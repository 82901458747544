import View from './view.js';
import { sharedDict } from './entities/dictionary.js';

export default class KPICarriersView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup handlers
        const { data } = handlers;

        const chart = $('#chart-kpi-carriers')
          .dxChart({
            dataSource: data,
            commonSeriesSettings: {
              argumentField: 'name',
              type: 'stackedBar',
            },
            series: [
              {
                valueField: 'punctuality',
                name: dict.getTextForKey(
                  'WEB_KPI_CARRIERPERFORMANCE_LEGEND_PUNCTUALITY'
                ),
              },
              {
                valueField: 'transitTime',
                name: dict.getTextForKey(
                  'WEB_KPI_CARRIERPERFORMANCE_LEGEND_TRANSITTIME'
                ),
              },
            ],
            legend: {
              verticalAlignment: 'bottom',
              horizontalAlignment: 'center',
              itemTextPosition: 'top',
            },
            valueAxis: {
              title: {
                text: '%',
              },
              position: 'right',
            },
            title: {
              text: dict.getTextForKey(
                'WEB_KPI_CARRIERPERFORMANCE_CHART_TITLE'
              ),
              // subtitle: {
              //   text: dict.getTextForKey('WEB_KPI_PUNCUTALITY_CHART_SUBTITLE'),
              // },
            },
            export: {
              enabled: true,
            },
            tooltip: {
              enabled: true,
            },
          })
          .dxChart('instance');
      });
    } catch (err) {
      console.error(err);
    }
  };
}

export const ENV = {
  PRODUCTION: true,
};

export const API_BASE_URL = ENV.PRODUCTION
  ? 'https://stesi.lenis.tech/api/'
  : 'http://192.168.1.15:8500/api/';
export const API_VERSION = '1.0';
export const APP_VERSION = '1.0(b1402)';
export const TIMEOUT_SEC = 10;
export const POLLING_SEC = 10;
export const POLLING_IS_ACTIVE = true; // Polling general switch
export const UNKNOWN_TEXT = 'LABEL NOT FOUND';

export const ROLES = [
  {
    code: 'SUPADMIN',
    name: 'SuperAdmin',
    nameKeyTranslation: 'ROLE_SUPADMIN',
    visible: false,
  },
  {
    code: 'ADMIN',
    name: 'Amministratore',
    nameKeyTranslation: 'ROLE_ADMIN',
    visible: true,
  },
  {
    code: 'CARRIER',
    name: 'Trasportatore',
    nameKeyTranslation: 'ROLE_CARRIER',
    visible: true,
  },
  {
    code: 'CUSTOMER',
    name: 'Cliente',
    nameKeyTranslation: 'ROLE_CUSTOMER',
    visible: true,
  },
  {
    code: 'INTERNAL',
    name: 'Operatore',
    nameKeyTranslation: 'ROLE_INTERNAL',
    visible: true,
  },
  {
    code: 'SUPPLIER',
    name: 'Fornitore',
    nameKeyTranslation: 'ROLE_SUPPLIER',
    visible: true,
  },
];

export const WEEKDAYS = [
  {
    code: 0,
    name: 'WEB_WEEKDAY_EVERYDAY',
  },
  {
    code: 1,
    name: 'WEB_WEEKDAY_MONDAY',
  },
  {
    code: 2,
    name: 'WEB_WEEKDAY_TUESDAY',
  },
  {
    code: 3,
    name: 'WEB_WEEKDAY_WEDNESDAY',
  },
  {
    code: 4,
    name: 'WEB_WEEKDAY_THURSDAY',
  },
  {
    code: 5,
    name: 'WEB_WEEKDAY_FRIDAY',
  },
  {
    code: 6,
    name: 'WEB_WEEKDAY_SATURDAY',
  },
  {
    code: 7,
    name: 'WEB_WEEKDAY_SUNDAY',
  },
];

export const DATEFORMAT = [
  {
    language: 'IT',
    format: 'dd MM yyyy',
  },
  {
    language: 'EN',
    format: 'MM dd yyyy',
  },
];

export const LOGTYPE = [
  { code: '-1', name: 'WEB_LOG_ALL' },
  { code: '0', name: 'WEB_LOG_INFO' },
  { code: '1', name: 'WEB_LOG_WARNING' },
  { code: '2', name: 'WEB_LOG_ERROR' },
];

export const OPERATIONTYPE = [
  {
    code: 0,
    name: 'LOADING',
    localizedName: 'WEB_SCHEDULER_LOADING',
  },
  {
    code: 1,
    name: 'UNLOADING',
    localizedName: 'WEB_SCHEDULER_UNLOADING',
  },
  {
    code: 2,
    name: 'LOADING/UNLOADING',
    localizedName: 'WEB_SCHEDULER_LOADING_UNLOADING',
  },
];

export const APPOINTMENTRESULT = [
  {
    code: 0,
    name: 'WEB_SCHEDULER_NONCOMPLIANT',
  },
  {
    code: 1,
    name: 'WEB_SCHEDULER_COMPLIANT',
  },
];

export const ATTACHMENTBASE64 = [
  {
    code: 'png',
    string: 'image/png',
  },
  {
    code: 'jpg',
    string: 'image/jpg',
  },
  {
    code: 'jpeg',
    string: 'image/jpeg',
  },
  {
    code: 'gif',
    string: 'image/gif',
  },
  {
    code: 'pdf',
    string: 'application/pdf',
  },
];

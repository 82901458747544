import View from './view.js';
import { sharedDict } from './entities/dictionary.js';

export default class KPISlotsOccupationView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup parameters and controller handlers
        const { data, fromDate, toDate, handlerGetData: getData } = handlers;
        const filteredRoles = [
          {
            name: `${dict.getTextForKey(
              'WEB_KPI_CUSTOMER'
            )}/${dict.getTextForKey('WEB_KPI_SUPPLIER')}`,
            type: 1,
          },
          // {
          //   name: dict.getTextForKey('WEB_KPI_SUPPLIER'),
          //   type: 2,
          // },
          {
            name: dict.getTextForKey('WEB_KPI_CARRIER'),
            type: 3,
          },
        ];
        // Setup filters
        let datePickerFromDate;
        let datePickerToDate;
        let selectBoxSubjectType;
        let query = {
          from: fromDate,
          to: toDate,
          type: 3,
        };
        let normalizedData = [];
        let normalizedSeries = [];

        // Call normalize data before load
        normalize(data);

        const toolbar = $('#toolbar-kpi-slots-occupation')
          .dxToolbar({
            onContentReady: function (e) {
              // TODO:
            },
            items: [
              {
                location: 'after',
                widget: 'dxDateBox',
                options: {
                  label: dict.getTextForKey('WEB_KPI_FROM'),
                  type: 'date',
                  value: fromDate,
                  onInitialized: function (e) {
                    datePickerFromDate = e.component;
                  },
                  onValueChanged(e) {
                    query.from = e.value;
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxDateBox',
                options: {
                  label: dict.getTextForKey('WEB_KPI_TO'),
                  type: 'date',
                  value: toDate,
                  onInitialized: function (e) {
                    datePickerToDate = e.component;
                  },
                  onValueChanged(e) {
                    query.to = e.value;
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  width: 'auto',
                  label: dict.getTextForKey('WEB_KPI_SUBJECT_TYPE'),
                  items: filteredRoles,
                  displayExpr: 'name',
                  valueExpr: 'type',
                  value: query.type,
                  onInitialized: function (e) {
                    selectBoxSubjectType = e.component;
                  },
                  onValueChanged(e) {
                    query.type = e.value;
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  type: 'default',
                  text: dict.getTextForKey('WEB_KPI_RESET'),
                  onClick(e) {
                    datePickerFromDate.option('value', fromDate);
                    datePickerToDate.option('value', toDate);
                    selectBoxSubjectType.option('value', 3);
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'search',
                  onClick(e) {
                    getData(query, function (data) {
                      reloadData(data);
                    });
                  },
                },
              },
            ],
          })
          .dxToolbar('instance');

        const chart = $('#chart-kpi-slots-occupation')
          .dxChart({
            dataSource: normalizedData,
            commonSeriesSettings: {
              argumentField: 'slot',
              type: 'stackedBar',
            },
            series: normalizedSeries,
            legend: {
              verticalAlignment: 'bottom',
              horizontalAlignment: 'center',
              itemTextPosition: 'top',
            },
            valueAxis: {
              title: {
                text: dict.getTextForKey('WEB_KPI_SLOTS_OCCUPATION_CHART_AXIS'),
              },
              position: 'right',
            },
            title: dict.getTextForKey('WEB_KPI_SLOTS_OCCUPATION_CHART_TITLE'),
            export: {
              enabled: true,
            },
            tooltip: {
              enabled: true,
              location: 'edge',
              customizeTooltip(arg) {
                return {
                  text: `${arg.seriesName} ${dict.getTextForKey(
                    'WEB_KPI_SLOTS_OCCUPATION_CHART_TOOLTIP'
                  )}: ${arg.valueText}`,
                };
              },
            },
          })
          .dxChart('instance');

        // Normalize server object according to chart's object
        function normalizeData(data) {
          let result = [];
          data.forEach(el => {
            const obj = result.find(m => m.slot === el.slot);
            if (obj) {
              obj[el.name] = el.total;
            } else {
              result.push({
                slot: el.slot,
                [el.name]: el.total,
              });
            }
          });
          return result;
        }

        // Normalize server object according to chart's object
        function normalizeSeries(data) {
          let result = [];
          data.forEach(el => {
            const keys = Object.keys(el);
            keys.slice(1).forEach(k => {
              if (!result.find(s => s.valueField === k)) {
                result.push({
                  valueField: k,
                  name: k,
                });
              }
            });
          });
          return result;
        }

        // Normalize objects
        function normalize(data) {
          normalizedData = normalizeData(data);
          normalizedSeries = normalizeSeries(normalizedData);
        }

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        function reloadData(data) {
          // Normalize data
          normalize(data);

          // Load data, series and refresh chart
          chart.option('dataSource', normalizedData);
          chart.option('series', normalizedSeries);
          chart.refresh();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

import View from './view.js';
import * as helpers from './utilities/helpers.js';
import { sharedDict } from './entities/dictionary.js';
import { WEEKDAYS, DATEFORMAT } from './utilities/config.js';

export default class SiteView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Setup parameters and handlers
        let {
          sites,
          // companies,
          user,
          nations,
          handlerCreate: create,
          handlerModify: modify,
          handlerDelete,
          handlerLock: lockSite,
        } = handlers;
        let treeView;
        // Handle popup mode: ADD or EDIT
        let popupMode;
        let popup;
        let form;
        let currentIndex;
        const role = user.account.role;
        // Setup permissions
        const editing =
          role === 'SUPADMIN' || role === 'ADMIN' || role === 'INTERNAL'
            ? true
            : false;
        const editingAllowed =
          role === 'SUPADMIN' || role === 'ADMIN' ? true : false;
        const dict = sharedDict();
        const weekdays = WEEKDAYS.map(({ code, name }) => {
          return {
            code: code,
            name: dict.getTextForKey(name),
          };
        });
        // Setup date localization
        const dateFormat = DATEFORMAT.find(
          el => el.language.toLowerCase() === user.language.toLowerCase()
        ).format;
        const dataGrid = $('#grid-sites')
          .dxDataGrid({
            dataSource: sites,
            keyExpr: 'id',
            rowAlternationEnabled: true,
            columnAutoWidth: true,
            headerFilter: {
              visible: true,
            },
            filterPanel: { visible: true },
            filterRow: { visible: true },
            selection: {
              mode: 'single',
            },
            paging: {
              pageSize: 10,
            },
            pager: {
              visible: true,
              allowedPageSizes: [10, 25, 'all'],
              showPageSizeSelector: true,
              showInfo: true,
              showNavigationButtons: true,
            },
            editing: {
              allowUpdating: editing,
              allowAdding: editing,
              allowDeleting: editing,
              useIcons: true,
              mode: 'popup',
            },
            columns: [
              {
                dataField: 'id',
                dataType: 'number',
                caption: dict.getTextForKey('WEB_SITES_ID'),
                visible: false,
                allowFiltering: false,
              },
              {
                dataField: 'name',
                dataType: 'string',
                caption: dict.getTextForKey('WEB_SITES_NAME'),
                alignment: 'left',
              },
              // {
              //   main: 'string',
              //   caption: 'Azienda',
              //   alignment: 'left',
              //   calculateDisplayValue: function () {
              //     return companies.find(cmp => cmp.id === user.idCompany).name;
              //   },
              // },
              {
                dataField: 'city',
                main: 'string',
                caption: dict.getTextForKey('WEB_SITES_CITY'),
                alignment: 'left',
              },
              {
                dataField: 'address',
                main: 'string',
                caption: dict.getTextForKey('WEB_SITES_ADDRESS'),
                alignment: 'left',
              },
              {
                dataField: 'country',
                main: 'string',
                width: '20%',
                caption: dict.getTextForKey('WEB_SITES_COUNTRY'),
                alignment: 'left',
              },
              {
                dataField: 'email',
                main: 'string',
                caption: dict.getTextForKey('WEB_SITES_EMAIL'),
                alignment: 'left',
              },
              {
                dataField: 'isEnabled',
                // width: 100,
                caption: dict.getTextForKey('WEB_SITES_ENABLED'),
                alignment: 'center',
                allowFiltering: false,
                calculateCellValue: function (e) {
                  return e.isEnabled ? '🟢' : '🔴';
                },
              },
              {
                dataField: 'zipCode',
                caption: dict.getTextForKey('WEB_SITES_ZIPCODE'),
                visible: false,
              },
              {
                dataField: 'phone',
                caption: dict.getTextForKey(''),
                visible: false,
              },
              {
                dataField: 'note',
                caption: dict.getTextForKey('WEB_SITES_NOTE'),
                visible: false,
              },
              {
                type: 'buttons',
                width: 200,
                alignment: 'left',
                buttons: [
                  {
                    name: 'edit',
                    onClick: function (e) {
                      showPopup(
                        'Edit',
                        sites.findIndex(el => el.id === e.row.data.id)
                      );
                    },
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    hint: 'Elimina',
                    icon: 'trash',
                    visible: editingAllowed,
                    onClick(e) {
                      const result = DevExpress.ui.dialog.confirm(
                        `<i>${dict.getTextForKey(
                          'WEB_SITES_DELETE_MESSAGE'
                        )}</i>`,
                        `${dict.getTextForKey('WEB_SITES_DELETE_TITLE')}`
                      );
                      result.done(function (dialogResult) {
                        if (dialogResult) {
                          handlerDelete(e.row.data, function (data) {
                            sites = data;
                            reloadData(data);
                          });
                        }
                      });
                      e.event.preventDefault();
                    },
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    name: 'user',
                    icon: 'user',
                    visible: editingAllowed,
                    onClick: function (e) {
                      const result = DevExpress.ui.dialog.confirm(
                        `<i>${dict.getTextForKey(
                          'WEB_SITES_LOCK_MESSAGE'
                        )}</i>`,
                        `${dict.getTextForKey('WEB_SITES_LOCK_TITLE')}`
                      );
                      result.done(function (dialogResult) {
                        if (dialogResult) {
                          lockSite(
                            e.row.data,
                            { isLocked: false },
                            function (data) {
                              reloadData(data);
                            }
                          );
                        }
                      });
                    },
                  },
                ],
              },
            ],
            onEditorPreparing: function (e) {
              if (e.dataField === 'isEnabled') {
                if (!e.row?.data?.id) {
                  e.editorOptions.value = true;
                } else {
                  e.editorOptions.value = e.value === '🟢' ? true : false;
                }
              }
            },
            onToolbarPreparing: function (e) {
              let toolbarItems = e.toolbarOptions.items;
              // customize addRow toolbar button
              for (let i = 0; i < toolbarItems.length; i++) {
                let item = toolbarItems[i];
                if (item.name === 'addRowButton') {
                  item.options.onClick = function (e) {
                    sites.push({
                      isEnabled: true,
                      festivities: [],
                      calendars: [],
                    });
                    showPopup('Add', sites.length - 1);
                  };
                  break;
                }
              }
            },
            onCellPrepared: function (e) {
              if (e.rowType == 'data') {
                if (!e.data.isLocked) {
                  e.cellElement.find('.dx-icon-user').remove();
                }
              }
            },
          })
          .dxDataGrid('instance');

        function showPopup(mode, index) {
          // Setup popup mode
          popupMode = mode;
          currentIndex = index;

          // Init popup
          initPopup();

          // Setup a template
          popup.option({
            contentTemplate: () => popupContentTemplate(),
          });
          popup.show();
          setTypeSlot();
        }

        function setTypeSlot() {
          let data = form.option('formData');
          // const multiType = form.getEditor(`SlotType`);
          // if (multiType && data.slotTypes)
          //   multiType.option(
          //     'value',
          //     data.slotTypes.map(x => x.idSlotType)
          //   );
        }

        // Setup custom popup
        function popupContentTemplate() {
          const content = $('<div>');
          form = content
            .dxForm({
              formData: sites[currentIndex],
              labelLocation: 'top',
              showColonAfterLabel: true,
              items: [
                {
                  itemType: 'group',
                  name: 'SiteInfo',
                  colCount: 2,
                  colSpan: 2,
                  items: [
                    {
                      dataField: 'name',
                      label: {
                        text: dict.getTextForKey('WEB_SITES_NAME'),
                      },
                      editorOptions: {
                        maxLength: '50',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SITES_NAME_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'city',
                      label: {
                        text: dict.getTextForKey('WEB_SITES_CITY'),
                      },
                      editorOptions: {
                        maxLength: '50',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SITES_CITY_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'address',
                      label: {
                        text: dict.getTextForKey('WEB_SITES_ADDRESS'),
                      },
                      editorOptions: {
                        maxLength: '100',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SITES_ADDRESS_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'country',
                      editorType: 'dxSelectBox',
                      editorOptions: {
                        searchEnabled: true,
                        dataSource: nations,
                        valueExpr: 'code',
                        displayExpr: 'name',
                      },
                      label: {
                        text: dict.getTextForKey('WEB_SITES_COUNTRY'),
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SITES_COUNTRY_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'zipCode',
                      label: {
                        text: dict.getTextForKey('WEB_SITES_ZIPCODE'),
                      },
                      editorOptions: {
                        maxLength: '20',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SITES_ZIPCODE_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'phone',
                      label: {
                        text: dict.getTextForKey('WEB_SITES_PHONE'),
                      },
                      editorOptions: {
                        maxLength: '50',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SITES_PHONE_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'email',
                      label: {
                        text: dict.getTextForKey('WEB_SITES_EMAIL'),
                      },
                      editorOptions: {
                        maxLength: '100',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SITES_EMAIL_REQUIRED'
                          ),
                        },
                        {
                          type: 'email',
                          message: dict.getTextForKey(
                            'WEB_SITES_EMAIL_INVALID'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'isEnabled',
                      editorType: 'dxCheckBox',
                      editorOptions: {
                        text: dict.getTextForKey('WEB_SITES_ISENABLED'),
                      },
                    },
                    {
                      dataField: 'note',
                      label: {
                        text: dict.getTextForKey('WEB_SITES_NOTE'),
                      },
                      editorType: 'dxTextArea',
                      colSpan: 2,
                      editorOptions: {
                        height: 100,
                      },
                    },
                  ],
                },
                {
                  itemType: 'group',
                  colCount: 2,
                  //colSpan: 2,
                  horizontalAlignment: 'right',
                  verticalAlignment: 'center',
                  name: 'calendar-group',
                  caption: dict.getTextForKey('WEB_SITES_CALENDAR'),
                  items: [
                    {
                      dataField: 'calendarUpdateAll',
                      editorType: 'dxCheckBox',
                      editorOptions: {
                        text: dict.getTextForKey(
                          'WEB_SITES_UPDATE_ALL_CALENDARS'
                        ),
                      },
                    },
                    {
                      itemType: 'button',
                      verticalAlignment: 'bottom',
                      buttonOptions: {
                        icon: 'add',
                        type: 'success',
                        stylingMode: 'contained',
                        text: dict.getTextForKey('WEB_SITES_ADD_CALENDAR'),
                        verticalAlignment: 'bottom',
                        onClick() {
                          form.itemOption(
                            'calendar-container',
                            'items',
                            createCalendarOptions()
                          );

                          // Focus on calendar last element (just created one)
                          form
                            .getEditor(
                              `calendars[${
                                sites[currentIndex].calendars.length - 1
                              }].day`
                            )
                            .focus();
                        },
                      },
                    },
                  ],
                },
                {
                  itemType: 'group',
                  colCount: 1,
                  colSpan: 2,
                  name: 'calendar-container',
                  items: getCalendarOptions(),
                },
                {
                  itemType: 'group',
                  colCount: 2,
                  //colSpan: 2,
                  horizontalAlignment: 'right',
                  verticalAlignment: 'center',
                  name: 'festivity-group',
                  caption: 'Festivity',
                  items: [
                    {
                      dataField: 'festivityUpdateAll',
                      editorType: 'dxCheckBox',
                      editorOptions: {
                        text: dict.getTextForKey(
                          'WEB_SITES_UPDATE_ALL_FESTIVITIES'
                        ),
                      },
                    },
                    {
                      itemType: 'button',
                      verticalAlignment: 'bottom',
                      buttonOptions: {
                        icon: 'add',
                        type: 'success',
                        stylingMode: 'contained',
                        text: dict.getTextForKey('WEB_SITES_ADD_FESTIVITY'),
                        verticalAlignment: 'bottom',
                        onClick() {
                          form.itemOption(
                            'festivity-container',
                            'items',
                            createFestivityOptions()
                          );

                          // Focus on festivities last element (just created one)
                          form
                            .getEditor(
                              `festivities[${
                                sites[currentIndex].festivities.length - 1
                              }].date`
                            )
                            .focus();
                        },
                      },
                    },
                  ],
                },
                {
                  itemType: 'group',
                  colCount: 1,
                  colSpan: 2,
                  name: 'festivity-container',
                  items: getFestivityOptions(),
                },
              ],
            })
            .dxForm('instance');

          $('.dx-popup-content').append(helpers.addScrollToContent(content));
        }

        function initPopup() {
          popup = $('#popup-site')
            .dxPopup({
              contentTemplate: popupContentTemplate,
              closeOnOutsideClick: true,
              title: dict.getTextForKey('WEB_SITES_SITE_INFO'),
              visible: true,
              width: '80%',
              onContentReady(e) {
                let contentElement = e.component.content();
                contentElement[0].parentElement.classList.add('p20');
              },
              toolbarItems: [
                {
                  widget: 'dxButton',
                  location: 'after',
                  toolbar: 'bottom',
                  options: {
                    type: 'success',
                    stylingMode: 'outlined',
                    text: editingAllowed
                      ? dict.getTextForKey('WEB_SITES_CANCEL')
                      : dict.getTextForKey('WEB_SITES_CLOSE'),
                    toolbar: 'bottom',
                    onClick: function () {
                      popup.hide();
                      // location.reload();
                    },
                  },
                },
                {
                  widget: 'dxButton',
                  location: 'after',
                  toolbar: 'bottom',
                  visible: editingAllowed,
                  options: {
                    text: dict.getTextForKey('WEB_SITES_CONFIRM'),
                    type: 'success',
                    stylingMode: 'contained',
                    onClick: function (e) {
                      // Check if form is valid
                      const result = form.validate();
                      if (!result.isValid) return;

                      popupMode === 'Add'
                        ? create(sites[currentIndex], function (data) {
                            sites = data;
                            reloadData(data);
                            popup.hide();
                          })
                        : modify(sites[currentIndex], function (data) {
                            sites = data;
                            reloadData(data);
                            popup.hide();
                          });
                    },
                  },
                },
              ],
            })
            .dxPopup('instance');
        }

        ///////////////////////////////////////////
        // Handle adding or removing dynamic rows
        ///////////////////////////////////////////

        //#region Calendar
        function createCalendarOptions() {
          const options = [];

          sites[currentIndex]?.calendars.forEach((_, i) => {
            options.push(generateNewCalendarOption(i));
          });

          // Push into array the new element
          sites[currentIndex]?.calendars.push({
            day: 0,
            fromTime: getFromTime(),
            toTime: getToTime(),
            deletable: true,
            isEnable: true,
          });

          options.push(
            generateNewCalendarOption(sites[currentIndex]?.calendars.length - 1)
          );

          return options;
        }

        function getCalendarOptions() {
          const options = [];
          if (sites[currentIndex]?.calendars.length === 0) {
            for (let i = 0; i < 8; i++) {
              let deletable = true;
              if (i === 0) deletable = false;
              // Push into array the new element
              sites[currentIndex]?.calendars.push({
                day: i,
                fromTime: getFromTime(),
                toTime: getToTime(),
                deletable: deletable,
                isEnable: true,
              });
            }
          } else {
            for (let i = 0; i < sites[currentIndex]?.calendars.length; i++) {
              let deletable = true;
              if (i === 0) deletable = false;
              sites[currentIndex].calendars[i].deletable = deletable;
            }
          }

          sites[currentIndex]?.calendars
            .sort((a, b) => a.day - b.day)
            .forEach((_, i) => {
              options.push(generateNewCalendarOption(i));
            });
          return options;
        }

        function checkCalendarOptions(firstDay = undefined) {
          form.itemOption('calendar-group', 'visible', firstDay != 0);
        }

        function generateNewCalendarOption(index) {
          return {
            itemType: 'group',
            //caption: 'new-Calendar',
            colCount: 6,
            //colSpan: 2,
            visible: true,
            items: [
              {
                dataField: `calendars[${index}].isEnable`,
                editorType: 'dxCheckBox',
                colSpan: 1,
                value: sites[currentIndex]?.calendars[index]?.isEnable,
                disabled: !sites[currentIndex]?.calendars[index]?.deletable,
                label: {
                  text: dict.getTextForKey('WEB_SITES_ISENABLED'),
                },
              },
              {
                dataField: `calendars[${index}].day`,
                editorType: 'dxSelectBox',
                colSpan: 2,
                label: {
                  text: dict.getTextForKey('WEB_SITES_DAY'),
                },
                validationRules: [
                  {
                    type: 'required',
                    message: dict.getTextForKey('WEB_SITES_CALENDAR_REQUIRED'),
                  },
                ],
                disabled: !sites[currentIndex]?.calendars[index]?.deletable,
                editorOptions: {
                  items: weekdays,
                  displayExpr: 'name',
                  valueExpr: 'code',
                  //onOpened: function (e) {
                  // Get current editor
                  // const currentEditor = form.getEditor(
                  //   `calendars[${index}].day`
                  // );
                  // Filter dataSource with "remaining" companies
                  // currentEditor.option(
                  //   'items',
                  //   weekdays.filter(wd => wd.code !== 0)
                  // );
                  // Reload dataSource
                  //currentEditor.getDataSource().reload();
                  //},
                  onValueChanged: function (e) {
                    sites[currentIndex].calendars[index].day = e.value;
                    //checkCalendarOptions(e.value);
                    // Setup a template
                    // popup.option({
                    //   contentTemplate: () => popupContentTemplate(),
                    // });
                  },
                },
              },
              {
                dataField: `calendars[${index}].fromTime`,
                editorType: 'dxDateBox',
                //colSpan: 1,
                label: {
                  text: dict.getTextForKey('WEB_SITES_FROMTIME'),
                },
                validationRules: [
                  {
                    type: 'required',
                    message: dict.getTextForKey('WEB_SITES_CALENDAR_REQUIRED'),
                  },
                ],
                editorOptions: {
                  type: 'time',
                  value: new Date(
                    `${new Date().toISOString().split('T')[0]} ${
                      sites[currentIndex].calendars[index].fromTime
                    }`
                  ),
                  onValueChanged(e) {
                    sites[currentIndex].calendars[index].fromTime = e.value
                      .toTimeString()
                      .split(' ')[0];
                    //handlerRangeSlider.option('end', data.value);
                  },
                },
              },
              {
                dataField: `calendars[${index}].toTime`,
                editorType: 'dxDateBox',
                // colSpan: 1,
                label: {
                  text: dict.getTextForKey('WEB_SITES_TOTIME'),
                },
                validationRules: [
                  {
                    type: 'required',
                    message: dict.getTextForKey('WEB_SITES_CALENDAR_REQUIRED'),
                  },
                ],
                editorOptions: {
                  type: 'time',
                  value: new Date(
                    `${new Date().toISOString().split('T')[0]} ${
                      sites[currentIndex].calendars[index].toTime
                    }`
                  ),
                  onValueChanged(e) {
                    sites[currentIndex].calendars[index].toTime = e.value
                      .toTimeString()
                      .split(' ')[0];
                    //handlerRangeSlider.option('end', data.value);

                    // Get second editor
                    //const edOre = form.getEditor(`calendars[${index}].Ore`);
                    // Clear old value
                    //edOre.option('value', e.value[1]);
                  },
                },
              },
              {
                itemType: 'button',
                verticalAlignment: 'center',
                horizontalAlignment: 'left',
                visible: sites[currentIndex]?.calendars[index]?.deletable,
                colSpan: 2,
                buttonOptions: {
                  icon: 'trash',
                  onClick() {
                    sites[currentIndex]?.calendars.splice(index, 1);
                    form.itemOption(
                      'calendar-container',
                      'items',
                      getCalendarOptions()
                    );
                  },
                },
              },
            ],
          };
        }
        //#endregion

        function getFromTime(date = new Date().toISOString().split('T')[0]) {
          return new Date(`${date} 00:00:00`).toTimeString().split(' ')[0];
        }

        function getToTime(date = new Date().toISOString().split('T')[0]) {
          return new Date(`${date} 23:59:59`).toTimeString().split(' ')[0];
        }

        //#region Festivity
        function createFestivityOptions() {
          const options = [];

          sites[currentIndex]?.festivities.forEach((_, i) => {
            options.push(generateNewFestivityOption(i));
          });

          const date = new Date().toISOString().split('T')[0];
          // Push into array the new element
          sites[currentIndex]?.festivities.push({
            date: date,
            fromTime: getFromTime(date),
            toTime: getToTime(date),
          });

          options.push(
            generateNewFestivityOption(
              sites[currentIndex]?.festivities.length - 1
            )
          );

          return options;
        }

        function getFestivityOptions() {
          const options = [];
          sites[currentIndex]?.festivities.forEach((_, i) => {
            options.push(generateNewFestivityOption(i));
          });
          return options;
        }

        function generateNewFestivityOption(index) {
          return {
            itemType: 'group',
            caption: 'new-Festivity',
            colCount: 6,
            visible: true,
            items: [
              {
                dataField: `festivities[${index}].isRecurrence`,
                editorType: 'dxCheckBox',
                colSpan: 1,
                value: false,
                onInitialized: function (e) {
                  e.component.option('value', false);
                },
                label: {
                  text: dict.getTextForKey('WEB_SCHEDULER_REPEAT'),
                },
              },
              {
                dataField: `festivities[${index}].date`,
                editorType: 'dxDateBox',
                colSpan: 2,
                label: {
                  text: dict.getTextForKey('WEB_SITES_DATE'),
                },
                validationRules: [
                  {
                    type: 'required',
                    message: dict.getTextForKey('WEB_SITES_DATE_REQUIRED'),
                  },
                ],
                editorOptions: {
                  displayFormat: dateFormat,
                  pickerType: 'rollers',
                  max: new Date(2022, 12, 0),
                  min: new Date(2022, 0, 1),
                  value: new Date(sites[currentIndex].festivities[index].date),
                  onValueChanged: function (e) {
                    sites[currentIndex].festivities[index].date = e.value
                      .toISOString()
                      .split('T')[0];
                  },
                },
              },
              {
                dataField: `festivities[${index}].fromTime`,
                editorType: 'dxDateBox',
                colSpan: 1,
                label: {
                  text: dict.getTextForKey('WEB_SITES_FROMTIME'),
                },
                validationRules: [
                  {
                    type: 'required',
                    message: dict.getTextForKey('WEB_SITES_FESTIVITY_REQUIRED'),
                  },
                ],
                editorOptions: {
                  type: 'time',
                  value: new Date(
                    `${sites[currentIndex].festivities[index].date} ${sites[currentIndex].festivities[index].fromTime}`
                  ),
                  onValueChanged(e) {
                    sites[currentIndex].festivities[index].fromTime = e.value
                      .toTimeString()
                      .split(' ')[0];
                  },
                },
              },
              {
                dataField: `festivities[${index}].toTime`,
                editorType: 'dxDateBox',
                colSpan: 1,
                label: {
                  text: dict.getTextForKey('WEB_SITES_TOTIME'),
                },
                validationRules: [
                  {
                    type: 'required',
                    message: dict.getTextForKey('WEB_SITES_CALENDAR_REQUIRED'),
                  },
                ],
                editorOptions: {
                  type: 'time',
                  value: new Date(
                    `${sites[currentIndex].festivities[index].date} ${sites[currentIndex].festivities[index].toTime}`
                  ),
                  onValueChanged(e) {
                    sites[currentIndex].festivities[index].toTime = e.value
                      .toTimeString()
                      .split(' ')[0];
                  },
                },
              },
              {
                itemType: 'button',
                verticalAlignment: 'center',
                horizontalAlignment: 'left',
                buttonOptions: {
                  icon: 'trash',
                  onClick() {
                    sites[currentIndex]?.festivities.splice(index, 1);
                    form.itemOption(
                      'festivity-container',
                      'items',
                      getFestivityOptions()
                    );
                  },
                },
              },
            ],
          };
        }
        //#endregion

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        function reloadData(data) {
          dataGrid.option('dataSource', data);
          dataGrid.refresh();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

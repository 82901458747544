import { API_BASE_URL, API_VERSION, TIMEOUT_SEC } from './utilities/config.js';
import { sharedUser } from './entities/user.js';
import * as DATE from './utilities/date.js';

export const timeout = function (sec) {
  return new Promise(function (_, reject) {
    setTimeout(function () {
      reject(new Error(`Request took too long! Timeout after ${sec} second`));
    }, sec * 1000);
  });
};

export const wait = async function (sec) {
  await new Promise(resolve => setTimeout(resolve, sec * 1000));
};

// NOTE: If body defined the request is POST
export const AJAX = async function (
  mthd,
  url,
  companyIsNeeded = true,
  body = undefined
) {
  try {
    const headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      'x-api-version': API_VERSION,
    };
    const user = sharedUser();
    headers.Authorization = user.token ? `Bearer ${user.token}` : '';
    // Superadmin always has a tenant associted by default in master DB
    if (user.idTenant) headers.TenantId = `${user.idTenant}`;
    if (user.idCompany && companyIsNeeded)
      headers.CompanyId = `${user.idCompany}`;
    let req = null;
    switch (mthd) {
      case 'POST':
        req = fetch(url, {
          method: mthd,
          headers: headers,
          body: JSON.stringify(body),
        });
        break;
      case 'GET':
        req = fetch(url, {
          method: mthd,
          headers: headers,
        });
        break;
      case 'PATCH':
        req = fetch(url, {
          method: mthd,
          headers: headers,
          body: JSON.stringify(body),
        });
        break;
      case 'DELETE':
        req = fetch(url, {
          method: mthd,
          headers: headers,
        });
        break;
      case 'GETAPP':
        req = fetch(url, {
          method: 'GET',
          headers: headers,
          json: JSON.stringify(body),
        });
        break;
    }

    // const req = body
    // ? fetch(url, {
    //     method: 'POST',
    //     headers: headers,
    //     body: JSON.stringify({
    //       data: body,
    //     }),
    //   })
    // : fetch(url, {
    //     method: 'GET',
    //     headers: headers,
    //   });

    const res = await Promise.race([req, timeout(TIMEOUT_SEC)]);
    const data = await res.json();
    return data;
  } catch (err) {
    throw err;
  }
};

//#region Authentication
export const login = async function (usr) {
  try {
    return await AJAX('POST', `${API_BASE_URL}auth/token`, false, {
      data: usr,
    });
  } catch (err) {
    throw err;
  }
};

export const resetPassword = async function (username) {
  try {
    return await AJAX('POST', `${API_BASE_URL}auth/forgot-password`, false, {
      data: username,
    });
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Configuration
export const getSettings = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}setting`);
  } catch (err) {
    throw err;
  }
};

export const getLanguages = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}languages`);
  } catch (err) {
    throw err;
  }
};

export const getTranslations = async function (lang) {
  try {
    const url = `${API_BASE_URL}translations?lang=${lang}`;
    return await AJAX('GET', url);
  } catch (err) {
    throw err;
  }
};

export const getTranslationsVersion = async function () {
  try {
    const url = `${API_BASE_URL}translations/version`;
    return await AJAX('GET', url);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Common configurations
export const getTenants = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}tenants`);
  } catch (err) {
    throw err;
  }
};

export const getCompanies = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}companies`);
  } catch (err) {
    throw err;
  }
};

export const getSubjects = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}subjects`);
  } catch (err) {
    throw err;
  }
};

export const getVersion = async function () {
  try {
    return await AJAX(`${API_BASE_URL}version`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Users
export const getUsers = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}users`, false);
  } catch (err) {
    throw err;
  }
};

export const createUser = async function (usr) {
  try {
    return await AJAX('POST', `${API_BASE_URL}users`, false, usr);
  } catch (err) {
    throw err;
  }
};

export const modifyUser = async function (usr) {
  try {
    return await AJAX('PATCH', `${API_BASE_URL}users/${usr.id}`, false, usr);
  } catch (err) {
    throw err;
  }
};

export const deleteUser = async function (usr) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}users/${usr.id}`, false);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Tenant
export const createTenant = async function (tnt) {
  try {
    return await AJAX('POST', `${API_BASE_URL}tenants`, false, tnt);
  } catch (err) {
    throw err;
  }
};

export const modifyTenant = async function (tnt) {
  try {
    return await AJAX('PATCH', `${API_BASE_URL}tenants/${tnt.id}`, false, tnt);
  } catch (err) {
    throw err;
  }
};

export const deleteTenant = async function (tnt) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}tenants/${tnt.id}`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Company
export const createCompany = async function (cmp) {
  try {
    return await AJAX('POST', `${API_BASE_URL}companies`, false, cmp);
  } catch (err) {
    throw err;
  }
};

export const modifyCompany = async function (cmp) {
  try {
    return await AJAX(
      'PATCH',
      `${API_BASE_URL}companies/${cmp.id}`,
      false,
      cmp
    );
  } catch (err) {
    throw err;
  }
};

export const deleteCompany = async function (cmp) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}companies/${cmp.id}`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Appointments
export const getAppointmentStatus = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}appointments_status`);
  } catch (err) {
    throw err;
  }
};

export const getAppointmentOperationType = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}appointments_operation_type`);
  } catch (err) {
    throw err;
  }
};

export const getAppointments = async function (app) {
  try {
    return await AJAX(
      'POST',
      `${API_BASE_URL}appointments/GetAppointment`,
      true,
      app
    );
  } catch (err) {
    throw err;
  }
};

export const getAppointmentAttachment = async function (fileUrl) {
  try {
    return await AJAX('GET', `${API_BASE_URL + fileUrl}`, true);
  } catch (err) {
    throw err;
  }
};

export const getAppointmentPDF = async function (app) {
  try {
    return await AJAX(
      'GET',
      `${API_BASE_URL}appointments/${app.id}/Print`,
      true
    );
  } catch (err) {
    throw err;
  }
};

export const createAppointment = async function (st) {
  try {
    return await AJAX('POST', `${API_BASE_URL}appointments`, true, st);
  } catch (err) {
    throw err;
  }
};

export const createMultiAppointment = async function (st) {
  try {
    return await AJAX(
      'POST',
      `${API_BASE_URL}appointments/MultiAppointments`,
      true,
      st
    );
  } catch (err) {
    throw err;
  }
};

export const modifyAppointment = async function (st) {
  try {
    return await AJAX(
      'PATCH',
      `${API_BASE_URL}appointments/${st.id}`,
      true,
      st
    );
  } catch (err) {
    throw err;
  }
};

export const deleteAppointment = async function (st) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}appointments/${st.id}`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Archive
export const getAppointmentsHistory = async function (data) {
  try {
    return await AJAX(
      'POST',
      `${API_BASE_URL}appointments_history`,
      true,
      data
    );
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Subject
export const getAllSubjects = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}subjects`, false);
  } catch (err) {
    throw err;
  }
};

export const getSubjectType = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}subject_types`);
  } catch (err) {
    throw err;
  }
};

export const createSubject = async function (sbj) {
  try {
    return await AJAX('POST', `${API_BASE_URL}subjects`, true, sbj);
  } catch (err) {
    throw err;
  }
};

export const modifySubject = async function (sbj) {
  try {
    return await AJAX('PATCH', `${API_BASE_URL}subjects/${sbj.id}`, true, sbj);
  } catch (err) {
    throw err;
  }
};
export const deleteSubject = async function (sbj) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}subjects/${sbj.id}`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Site
export const getSites = async function (isRecurrenceYears = false) {
  try {
    return await AJAX(
      'GET',
      `${API_BASE_URL}sites?isRecurrenceYears=${isRecurrenceYears}`
    );
  } catch (err) {
    throw err;
  }
};

export const createSite = async function (st) {
  try {
    return await AJAX('POST', `${API_BASE_URL}sites`, true, st);
  } catch (err) {
    throw err;
  }
};

export const modifySite = async function (st) {
  try {
    return await AJAX('PATCH', `${API_BASE_URL}sites/${st.id}`, true, st);
  } catch (err) {
    throw err;
  }
};

export const deleteSite = async function (st) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}sites/${st.id}`);
  } catch (err) {
    throw err;
  }
};

export const lockSite = async function (st, data) {
  try {
    return await AJAX(
      'PATCH',
      `${API_BASE_URL}sites/Lock/${st.id}`,
      true,
      data
    );
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Slot
export const getSlots = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}slots`);
  } catch (err) {
    throw err;
  }
};

export const createSlot = async function (st) {
  try {
    return await AJAX('POST', `${API_BASE_URL}slots`, true, st);
  } catch (err) {
    throw err;
  }
};

export const modifySlot = async function (st) {
  try {
    return await AJAX('PATCH', `${API_BASE_URL}slots/${st.id}`, true, st);
  } catch (err) {
    throw err;
  }
};

export const deleteSlot = async function (st) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}slots/${st.id}`);
  } catch (err) {
    throw err;
  }
};

export const getAppointmentsOperationType = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}appointments_operation_type`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Vehicle
export const getVehiclesTypes = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}vehicle_types`);
  } catch (err) {
    throw err;
  }
};

export const createVehicleType = async function (vht) {
  try {
    return await AJAX('POST', `${API_BASE_URL}vehicle_types`, true, vht);
  } catch (err) {
    throw err;
  }
};

export const modifyVehicleType = async function (vht) {
  try {
    return await AJAX(
      'PATCH',
      `${API_BASE_URL}vehicle_types/${vht.id}`,
      true,
      vht
    );
  } catch (err) {
    throw err;
  }
};

export const deleteVehicleType = async function (vht) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}vehicle_types/${vht.id}`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region ProductType
export const getProductTypes = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}product_types`);
  } catch (err) {
    throw err;
  }
};

export const createProductType = async function (prt) {
  try {
    return await AJAX('POST', `${API_BASE_URL}product_types`, true, prt);
  } catch (err) {
    throw err;
  }
};

export const modifyProductType = async function (prt) {
  try {
    return await AJAX(
      'PATCH',
      `${API_BASE_URL}product_types/${prt.id}`,
      true,
      prt
    );
  } catch (err) {
    throw err;
  }
};

export const deleteProductType = async function (prt) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}product_types/${prt.id}`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region SlotLine
export const getSlotLines = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}lines`);
  } catch (err) {
    throw err;
  }
};

export const createSlotLine = async function (sln) {
  try {
    return await AJAX('POST', `${API_BASE_URL}lines`, true, sln);
  } catch (err) {
    throw err;
  }
};

export const modifySlotLine = async function (sln) {
  try {
    return await AJAX('PATCH', `${API_BASE_URL}lines/${sln.id}`, true, sln);
  } catch (err) {
    throw err;
  }
};

export const deleteSlotLine = async function (sln) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}lines/${sln.id}`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region SlotType
export const getSlotTypes = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}slot_types`);
  } catch (err) {
    throw err;
  }
};

export const createSlotType = async function (sln) {
  try {
    return await AJAX('POST', `${API_BASE_URL}slot_types`, true, sln);
  } catch (err) {
    throw err;
  }
};

export const modifySlotType = async function (sln) {
  try {
    return await AJAX(
      'PATCH',
      `${API_BASE_URL}slot_types/${sln.id}`,
      true,
      sln
    );
  } catch (err) {
    throw err;
  }
};

export const deleteSlotType = async function (sln) {
  try {
    return await AJAX('DELETE', `${API_BASE_URL}slot_types/${sln.id}`);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Messages
export const getUnreadMessagesCount = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}messages`);
  } catch (err) {
    throw err;
  }
};

export const getMessages = async function (data) {
  try {
    return await AJAX(
      'POST',
      `${API_BASE_URL}messages/GetMessage`,
      false,
      data
    );
  } catch (err) {
    throw err;
  }
};

export const isRead = async function (msg, data) {
  try {
    return await AJAX(
      'PATCH',
      `${API_BASE_URL}messages/${msg.idMessage}`,
      false,
      data
    );
  } catch (err) {
    throw err;
  }
};

export const updateAllMessage = async function (data) {
  try {
    return await AJAX(
      'PATCH',
      `${API_BASE_URL}messages/UpdateAllMessage`,
      false,
      data
    );
  } catch (err) {
    throw err;
  }
};

export const deleteMessage = async function (msg) {
  try {
    return await AJAX(
      'DELETE',
      `${API_BASE_URL}messages/${msg.idMessage}`,
      false
    );
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Logs
export const getLogs = async function (data) {
  try {
    return await AJAX('POST', `${API_BASE_URL}logs`, false, data);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region Attachaments
export const downloadImages = async function (url) {
  try {
    return await AJAX('GET', `${API_BASE_URL}${url}`, true);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
//#endregion

//#region KPI
export const getYears = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}kpi/GetYears`, true);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getKpiPunctuality = async function (data) {
  try {
    return await AJAX('POST', `${API_BASE_URL}kpi/GetPerformances`, true, data);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getKpiTransitTimes = async function (data) {
  try {
    return await AJAX('POST', `${API_BASE_URL}kpi/GetTransitTimes`, true, data);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getCarrierPerformance = async function () {
  try {
    return await AJAX('GET', `${API_BASE_URL}kpi/GetCarrierPerformance`, true);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getKpiSlotOccupations = async function (data) {
  try {
    return await AJAX(
      'POST',
      `${API_BASE_URL}kpi/GetBaieSaturation`,
      true,
      data
    );
  } catch (err) {
    console.log(err);
    throw err;
  }
};
//#endregion

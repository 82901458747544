import View from './view.js';
import { sharedDict } from './entities/dictionary.js';

export default class VehiclesTypeView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup parameters and controller handlers
        const {
          vehiclesTypes,
          //companies,
          user,
          handlerCreate: create,
          handlerModify: modify,
          handlerDelete,
        } = handlers;
        let form;
        const role = user.account.role;
        const editing =
          role === 'SUPADMIN' || role === 'ADMIN' || role === 'INTERNAL'
            ? true
            : false;
        const editingAllowed =
          role === 'SUPADMIN' || role === 'ADMIN' ? true : false;
        const dataGrid = $('#grid-vehicles')
          .dxDataGrid({
            dataSource: vehiclesTypes,
            keyExpr: 'id',
            showBorders: true,
            columnAutoWidth: true,
            allowColumnReordering: true,
            rowAlternationEnabled: true,
            focusedRowEnabled: true,
            headerFilter: {
              visible: true,
            },
            filterPanel: { visible: true },
            filterRow: { visible: true },
            editing: {
              mode: 'popup',
              allowUpdating: editing,
              allowAdding: editing,
              allowDeleting: editing,
              useIcons: true,
              popup: {
                title: 'Veichle Info',
                showTitle: true,
                width: '80%',
                closeOnOutsideClick: true,
                onContentReady(e) {
                  let contentElement = e.component.content();
                  contentElement[0].parentElement.classList.add('p20');
                },
                toolbarItems: [
                  {
                    widget: 'dxButton',
                    location: 'after',
                    toolbar: 'bottom',
                    options: {
                      stylingMode: 'outlined',
                      type: 'success',
                      text: editingAllowed
                        ? dict.getTextForKey('WEB_VEHICLE_TYPE_CANCEL')
                        : dict.getTextForKey('WEB_VEHICLE_TYPE_CLOSE'),
                      toolbar: 'bottom',
                      onClick: function (e) {
                        dataGrid.cancelEditData();
                      },
                    },
                  },
                  {
                    widget: 'dxButton',
                    location: 'after',
                    toolbar: 'bottom',
                    visible: editingAllowed,
                    options: {
                      stylingMode: 'contained',
                      type: 'success',
                      text: dict.getTextForKey('WEB_VEHICLE_TYPE_CONFIRM'),
                      onClick: function (e) {
                        // Check if form is valid
                        const result = form.validate();
                        if (!result.isValid) return;

                        // Get 'id' of modified row
                        let id = dataGrid.option('editing.editRowKey');
                        // Detect if edited
                        const editing = dataGrid.option('editing');
                        if (editing.changes.length > 0) {
                          const data = editing.changes[0].data;
                          const changedKeys = Object.keys(data);
                          // Find current element
                          let object = dataGrid
                            .option('dataSource')
                            .find(el => el.id === id);
                          // If data not exists then create, otherwise update current element
                          if (object == null) {
                            object = data;
                            create(object, function (data) {
                              dataGrid.saveEditData();
                              reloadData(data);
                            });
                          } else {
                            changedKeys.forEach(el => {
                              object[`${el}`] = data[`${el}`];
                            });
                            modify(object, function (data) {
                              dataGrid.saveEditData();
                              reloadData(data);
                            });
                          }
                        } else {
                          // Save and close popup
                          dataGrid.cancelEditData();
                        }
                      },
                    },
                  },
                ],
              },
              form: {
                onInitialized(e) {
                  form = e.component;
                },
                items: [
                  {
                    itemType: 'group',
                    colCount: 2,
                    colSpan: 2,
                    items: [
                      {
                        dataField: 'name',
                        label: {
                          text: dict.getTextForKey('WEB_VEHICLE_TYPE_NAME'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_VEHICLE_TYPE_NAME_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'description',
                        label: {
                          text: dict.getTextForKey(
                            'WEB_VEHICLE_TYPE_DESCRIPTION'
                          ),
                        },
                        editorOptions: {
                          maxLength: '500',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_VEHICLE_TYPE_DESCRIPTION_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'isEnabled',
                        editorType: 'dxCheckBox',
                        editorOptions: {
                          text: dict.getTextForKey('WEB_VEHICLE_TYPE_ENABLED'),
                        },
                      },
                    ],
                  },
                ],
              },
            },
            paging: {
              pageSize: 10,
            },
            pager: {
              visible: true,
              allowedPageSizes: [10, 25, 'all'],
              showPageSizeSelector: true,
              showInfo: true,
              showNavigationButtons: true,
            },
            onEditorPreparing: function (e) {
              if (e.dataField === 'isEnabled') {
                if (!e.row?.data?.id) {
                  e.editorOptions.value = true;
                } else {
                  e.editorOptions.value = e.value === '🟢' ? true : false;
                }
              }
            },
            columns: [
              {
                dataField: 'id',
                main: 'int',
                visible: false,
                allowFiltering: false,
              },
              {
                dataField: 'name',
                caption: dict.getTextForKey('WEB_VEHICLE_TYPE_NAME'),
              },
              {
                dataField: 'description',
                caption: dict.getTextForKey('WEB_VEHICLE_TYPE_DESCRIPTION'),
              },
              {
                dataField: 'isEnabled',
                // width: 100,
                caption: dict.getTextForKey('WEB_VEHICLE_TYPE_ENABLED'),
                alignment: 'center',
                allowFiltering: false,
                calculateCellValue: function (e) {
                  return e.isEnabled ? '🟢' : '🔴';
                },
              },
              {
                type: 'buttons',
                width: 200,
                buttons: [
                  {
                    hint: 'Edit',
                    name: 'edit',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    hint: 'Delete',
                    icon: 'trash',
                    visible: editingAllowed,
                    onClick(e) {
                      const result = DevExpress.ui.dialog.confirm(
                        `<i>${dict.getTextForKey(
                          'WEB_VEHICLE_TYPE_DELETE_MESSAGE'
                        )}</i>`,
                        `${dict.getTextForKey('WEB_VEHICLE_TYPE_DELETE_TITLE')}`
                      );
                      result.done(function (dialogResult) {
                        if (dialogResult) {
                          handlerDelete(e.row.data, function (data) {
                            reloadData(data);
                          });
                        }
                        // else {
                        //   DevExpress.ui.dialog.alert(
                        //     '<i>NON ELIMINATO</i>',
                        //     'Attenzione'
                        //   );
                        //   e.component.refresh(true);
                        // }
                      });
                      e.event.preventDefault();
                    },
                  },
                ],
              },
            ],
          })
          .dxDataGrid('instance');

        function reloadData(data) {
          dataGrid.option('dataSource', data);
          dataGrid.refresh();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

import { sharedDict } from './entities/dictionary.js';
import View from './view.js';

export default class UserDetailsView extends View {
  render = function (handlers, callback) {
    try {
      // Import shared dictionary
      const dict = sharedDict();
      // Setup parameters and controller handlers
      const { user, handlerModify, handlerReloadDictionary } = handlers;
      $(() => {
        const form = $('#user-profile')
          .dxForm({
            formData: user,
            // scrollByContent: true, // the swipe gesture on all platforms
            // scrollByThumb: true, // the scrollbar on all platforms
            // useNativeScrolling: true,
            // showScrollbar: 'always', // or 'onScroll' | 'onHover' | 'never'
            onFieldDataChanged: function (e) {
              if (e.dataField === 'language') {
                handlerReloadDictionary(user);
              }
            },
            onContentReady: function (e) {
              // NOTE: not working for Firefox, seams to be global problem!
              // e.element[0].setAttribute('autocomplete', 'new-password');
            },
            items: [
              {
                itemType: 'group',
                cssClass: 'second-group',
                colCount: 2,
                caption: dict.getTextForKey('WEB_USER_DETAILS_PROFILE'),
                items: [
                  {
                    itemType: 'group',
                    items: [
                      {
                        dataField: 'name',
                        caption: dict.getTextForKey('WEB_USER_DETAILS_NAME'),
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_USER_DETAILS_FILED_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'surname',
                        caption: dict.getTextForKey('WEB_USER_DETAILS_SURNAME'),
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: 'Filed is required',
                          },
                        ],
                      },
                      {
                        dataField: 'account.username',
                        disabled: true,
                        label: {
                          text: dict.getTextForKey('WEB_USER_DETAILS_USERNAME'),
                        },
                      },
                      {
                        dataField: 'account.role',
                        caption: dict.getTextForKey('WEB_USER_DETAILS_ROLE'),
                        editorOptions: {
                          value: user.account.role,
                          disabled: true,
                        },
                        label: {
                          text: dict.getTextForKey('WEB_USER_DETAILS_ROLE'),
                        },
                      },
                    ],
                  },
                  {
                    itemType: 'group',
                    items: [
                      {
                        dataField: 'email',
                        caption: dict.getTextForKey('WEB_USER_DETAILS_EMAIL'),
                        disabled: true,
                        // editorOptions: {
                        //   maxLength: '100',
                        // },
                      },
                      {
                        dataField: 'language',
                        editorType: 'dxSelectBox',
                        editorOptions: {
                          items: dict.languages.map(lng => lng.code),
                          maxLength: '100',
                        },
                        label: {
                          text: dict.getTextForKey('WEB_USER_DETAILS_LANGUAGE'),
                        },
                      },
                      {
                        dataField: 'phoneNumber',
                        label: {
                          text: dict.getTextForKey('WEB_USER_DETAILS_PHONE'),
                        },
                        editorOptions: {
                          mask: '(000) 0000000',
                          maxLength: '50',
                        },
                        validationRules: [
                          // {
                          //   type: 'required',
                          //   message: 'Filed is required',
                          // },
                        ],
                      },
                    ],
                  },
                  {
                    colSpan: 2,
                    dataField: 'note',
                    editorType: 'dxTextArea',
                    editorOptions: {
                      height: 140,
                    },
                    label: {
                      text: dict.getTextForKey('WEB_USER_DETAILS_NOTE'),
                    },
                  },
                ],
              },
              {
                itemType: 'group',
                cssClass: 'second-group',
                colCount: 2,
                caption: dict.getTextForKey('WEB_USER_DETAILS_PASSWORDSETUP'),
                items: [
                  {
                    editorType: 'dxTextBox',
                    name: 'password1',
                    label: {
                      text: dict.getTextForKey('WEB_USER_DETAILS_NEWPASSWORD'),
                    },
                    editorOptions: {
                      mode: 'password',
                      maxLength: '20',
                      onContentReady: function (e) {
                        e.element[0].setAttribute(
                          'autocomplete',
                          'new-password'
                        );
                      },
                      onKeyDown: function (e) {
                        if (
                          getValueOfFormEditor('password1') !==
                          getValueOfFormEditor('password2')
                        ) {
                          setValueOfFromEditor('password1', null);
                          setValueOfFromEditor('password2', null);
                        }
                      },
                    },
                    validationRules: [
                      {
                        type: 'pattern',
                        pattern:
                          /^(?=.*[A-Za-z_])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z_\d@$!%*#?&]{8,}$/,
                        message: dict.getTextForKey(
                          'WEB_USERS_PASSWORD_VALIDATION'
                        ),
                      },
                    ],
                  },
                  {
                    editorType: 'dxTextBox',
                    label: {
                      text: dict.getTextForKey(
                        'WEB_USER_DETAILS_REPEATPASSWORD'
                      ),
                    },
                    name: 'password2',
                    editorOptions: {
                      mode: 'password',
                      maxLength: '20',
                      onContentReady: function (e) {
                        e.element[0].setAttribute(
                          'autocomplete',
                          'new-password'
                        );
                      },
                    },
                    validationRules: [
                      {
                        type: 'compare',
                        message: dict.getTextForKey(
                          'WEB_USER_DETAILS_PASSWORDMISSMATCH'
                        ),
                        comparisonTarget() {
                          return getValueOfFormEditor('password1');
                        },
                      },
                      {
                        type: 'pattern',
                        pattern:
                          /^(?=.*[A-Za-z_])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z_\d@$!%*#?&]{8,}$/,
                        message: dict.getTextForKey(
                          'WEB_USERS_PASSWORD_VALIDATION'
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                itemType: 'button',
                name: 'btn-save',
                horizontalAlignment: 'right',
                buttonOptions: {
                  text: dict.getTextForKey('WEB_USER_DETAILS_SAVE'),
                  type: 'success',
                  useSubmitBehavior: true,
                  onClick: function () {
                    const validatior = form.validate();
                    if (!validatior.isValid) {
                      DevExpress.ui.notify(
                        {
                          message: validatior.brokenRules[0].message,
                          position: {
                            my: 'center top',
                            at: 'center top',
                          },
                        },
                        'error',
                        1000
                      );
                      return;
                    }
                    handlerModify(user, function () {
                      location.reload();
                    });
                  },
                },
              },
            ],
          })
          .dxForm('instance');

        // $('.container').append(helpers.addScrollToContent(form));
        // helpers.addScrollToContent(form);

        function getValueOfFormEditor(name) {
          return form.getEditor(name).option('value');
        }

        function setValueOfFromEditor(name, value) {
          const editor = form.getEditor(name);
          editor.option('value', value);
        }

        // NOTE: Not used, sets click on Enter if needed (not requested)
        // Enter key event listener
        // document.addEventListener('keypress', btnClicked);
      });
    } catch (err) {
      console.error(err);
    }
  };

  // NOTE: Not used
  deInit() {
    // super.deInit();
    this.print();
    document.removeEventListener('keypress', btnClicked);
  }
}

// Not used
const btnClicked = function (e) {
  if (e.code === 'Enter') {
    $('#save').dxButton('instance').focus();
    $('#save').trigger('dxclick');
  }
};

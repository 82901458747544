import Navigation from './navigation.js';
import IndexView from './index.js';
import MessagesView from './messagesView.js';
import LogsView from './logsView.js';
import LoginView from './loginView.js';
import ResetPasswordView from './resetPasswordView.js';
import SchedulerView from './schedulerView.js';
import ArchiveView from './archiveView.js';
import UsersView from './usersView.js';
import UserDetailsView from './userDetailsView.js';
import TenantsView from './tenantsView.js';
import CompaniesView from './companiesView.js';
import SiteView from './siteView.js';
import SlotView from './slotView.js';
import VehiclesTypeView from './vehiclesTypeView.js';
import ProductTypeView from './productTypeView.js';
import SlotLineView from './slotLineView.js';
import SlotTypeView from './slotTypeView.js';
import SubjectsView from './subjectsView.js';
import KPIPunctualityView from './kpiPunctualityView.js';
import KPITransitsView from './kpiTransitsView.js';
import KPICarriersView from './kpiCarriers.js';
import KPISlotsOccupationView from './kpiSlotsOccupation.js';
import * as model from './models.js';
import { router } from './routers.js';
import * as helpers from './utilities/helpers.js';
import * as DATE from './utilities/date.js';
import { debugLog } from './utilities/logger.js';
import { nations } from './resources/nations.js';
import { sharedUser, resetUser } from './entities/user.js';
import { sharedDict, resetDictionary } from './entities/dictionary.js';
import {
  APP_VERSION,
  POLLING_SEC,
  POLLING_IS_ACTIVE,
  ROLES,
} from './/utilities/config.js';

const navigation = new Navigation();
const indexView = new IndexView();
const messagesView = new MessagesView();
const logsView = new LogsView();
const loginView = new LoginView();
const resetPasswordView = new ResetPasswordView();
const archiveView = new ArchiveView();
const schedulerView = new SchedulerView();
const usersView = new UsersView();
const userDetailsView = new UserDetailsView();
const tenantsView = new TenantsView();
const companiesView = new CompaniesView();
const siteView = new SiteView();
const slotView = new SlotView();
const vehiclesTypeView = new VehiclesTypeView();
const productTypeView = new ProductTypeView();
const slotLineView = new SlotLineView();
const slotTypeView = new SlotTypeView();
const subjectsView = new SubjectsView();
const kpiPunctualityView = new KPIPunctualityView();
const kpiTransitsView = new KPITransitsView();
const kpiCarriersView = new KPICarriersView();
const kpiSlotsOccupationView = new KPISlotsOccupationView();

let components;
let polling;
let pollingTime = new Date().getTime();

// ##################################
// Handle page reload
// ##################################
const loadPage = async function (curPage) {
  // debugLog(`CURRENT PAGE: ${curPage}`);

  // Set current navigation item in localStorage
  navigation.setItem(curPage.toLowerCase());

  // Change location path "hash"
  if (router.getRoute(curPage)) location.replace(router.getRoute(curPage).path);

  const user = sharedUser();
  const role = user.account.role;
  const { data: companies } = await model.getCompanies();
  switch (curPage) {
    case 'scheduler': {
      const { data: schedulerStatus } = await model.getAppointmentStatus();
      const { data: schedulerSites } = await model.getSites(true);
      const { data: schedulerSlots } = await model.getSlots();
      const { data: schedulerAppointments } = await model.getAppointments({
        idSite:
          schedulerSites && schedulerSites.length > 0
            ? schedulerSites[0]?.id
            : null,
        da: null,
        a: null,
      });
      const { data: schedulerSubjects } = await model.getSubjects();
      const { data: schedulerOperationTypes } =
        await getAppointmentsOperationType();
      const handlersScheduler = {
        schedulerAppointments,
        user,
        schedulerStatus,
        schedulerSites,
        schedulerSlots,
        schedulerSubjects,
        schedulerOperationTypes,
        roles: ROLES,
        handlerGetAppointment: getAppointments,
        handlerGetAttachment: getAppointmentAttachment,
        handlerPrintPDF: getAppointmentPDF,
        handlerCreate: createAppointment,
        handlerModify: modifyAppointment,
        handlerDelete: deleteAppointment,
        handlerLock: lockSite,
        handlerCreateMulti: createMultiAppointment,
      };
      schedulerView.render(handlersScheduler);
      break;
    }
    case 'archive': {
      const date = new Date();
      const { data: archiveAppointmentStatus } =
        await model.getAppointmentStatus();
      const { data: archives } = await model.getAppointmentsHistory({
        da: DATE.getDateTime(date, '00:00:00'),
        a: DATE.getDateTime(date, '23:59:59'),
      });
      const handlerArchives = {
        user,
        archives,
        archiveAppointmentStatus,
        handlerGetArchives: getArchives,
      };
      archiveView.render(handlerArchives);
      break;
    }
    case 'companies': {
      const handlersCompanies = {
        cmp: companies,
        role: role,
        nations: nations,
        handlerSetCompany: setCompany,
        handlerCreate: createCompany,
        handlerModify: modifyCompany,
        handlerDelete: deleteCompany,
      };
      companiesView.render(handlersCompanies);
      break;
    }
    case 'tenants': {
      const { data: tenants } = await model.getTenants();
      const handlerTenants = {
        tenants,
        role: role,
        handlerSetTenant: setTenant,
        handlerCreate: createTenant,
        handlerModify: modifyTenant,
        handlerDelete: deleteTenant,
      };
      tenantsView.render(handlerTenants);
      break;
    }
    case 'users': {
      const { data: users } = await model.getUsers();
      const { data: allsubjects } =
        role === 'SUPADMIN' || role === 'ADMIN' || role === 'INTERNAL'
          ? await model.getAllSubjects()
          : [];
      const handlers = {
        users,
        role: role,
        roles: ROLES,
        cmp: companies,
        sbj: allsubjects,
        handlerCreate: createUser,
        handlerModify: modifyUser,
        handlerDelete: deleteUser,
      };
      usersView.render(handlers);
      break;
    }
    case 'site': {
      const { data: sites } = await model.getSites();
      const handlersSite = {
        sites,
        //companies,
        user,
        nations,
        handlerCreate: createSite,
        handlerModify: modifySite,
        handlerDelete: deleteSite,
        handlerLock: lockSite,
      };
      siteView.render(handlersSite);
      break;
    }
    case 'slot':
      const { data: slots } = await model.getSlots();
      const { data: slotSites } = await model.getSites();
      const { data: slotTypesSlot } = await model.getSlotTypes();
      const { data: slotLinesSlot } = await model.getSlotLines();
      const { data: slotProductTypes } = await model.getProductTypes();
      const { data: slotVehicleTypes } = await model.getVehiclesTypes();
      const { data: slotOperationTypes } = await getAppointmentsOperationType();
      const handlersSlot = {
        slots,
        sites: slotSites,
        slotTypesSlot,
        slotLinesSlot,
        slotProductTypes,
        slotVehicleTypes,
        slotOperationTypes,
        user,
        handlerCreate: createSlot,
        handlerModify: modifySlot,
        handlerDelete: deleteSlot,
      };
      slotView.render(handlersSlot);
      break;
    case 'vehiclestype': {
      const { data: vehiclesTypes } = await model.getVehiclesTypes();
      const handlersVehiclesType = {
        vehiclesTypes,
        //companies,
        user,
        handlerCreate: createVehicleType,
        handlerModify: modifyVehicleType,
        handlerDelete: deleteVehicleType,
      };
      vehiclesTypeView.render(handlersVehiclesType);
      break;
    }
    case 'producttype': {
      const { data: productTypes } = await model.getProductTypes();
      const handlersProductType = {
        productTypes,
        user,
        handlerCreate: createProductType,
        handlerModify: modifyProductType,
        handlerDelete: deleteProductType,
      };
      productTypeView.render(handlersProductType);
      break;
    }
    case 'slotline': {
      const { data: slotLines } = await model.getSlotLines();
      const handlersSlotLine = {
        slotLines,
        user,
        handlerCreate: createSlotLine,
        handlerModify: modifySlotLine,
        handlerDelete: deleteSlotLine,
      };
      slotLineView.render(handlersSlotLine);
      break;
    }
    case 'slottype': {
      const { data: slotTypes } = await model.getSlotTypes();
      const handlersSlotType = {
        slotTypes,
        user,
        handlerCreate: createSlotType,
        handlerModify: modifySlotType,
        handlerDelete: deleteSlotType,
      };
      slotTypeView.render(handlersSlotType);
      break;
    }
    case 'subjects': {
      const { data: subjecttype } = await model.getSubjectType();
      const { data: subjects } =
        role === 'SUPADMIN' || role === 'ADMIN' || role === 'INTERNAL'
          ? await model.getSubjects()
          : [];
      const handlersSubjects = {
        sbj: subjects,
        role: role,
        subjecttype,
        nations,
        handlerCreate: createSubject,
        handlerModify: modifySubject,
        handlerDelete: deleteSubject,
      };
      subjectsView.render(handlersSubjects);
      break;
    }
    case 'messages': {
      // Default date
      const date = new Date();
      const { data: messages } = await model.getMessages({
        da: DATE.getDateTime(date, '00:00:00'),
        a: DATE.getDateTime(date, '23:59:59'),
      });
      const handlerMessages = {
        user,
        messages,
        handlerGetMessages: getMessages,
        handlerIsRead: isRead,
        handlerupdateAllMessage: updateAllMessage,
        handlerDelete: deleteMessage,
      };
      messagesView.render(handlerMessages);
      break;
    }
    case 'logs': {
      // Default date
      const date = new Date();
      const { data: logs } = await model.getLogs({
        dateFrom: DATE.getDateTime(date, '00:00:00'),
        dateTo: DATE.getDateTime(date, '23:59:59'),
      });
      const handlerLogs = {
        user,
        logs,
        handlerGetLogs: getLogs,
      };
      logsView.render(handlerLogs);
      break;
    }
    case 'kpiPunctuality': {
      const { data: years } = await model.getYears();
      const { data: subjects } = await model.getSubjects();
      const year = years.length > 0 ? years[0].year : new Date().getFullYear();
      const { data: punctualities } = await model.getKpiPunctuality({
        year: year,
        idSubject: null,
      });
      const handlers = {
        year,
        years,
        subjects,
        punctualities,
        handlerGetData: getKpiPunctuality,
      };
      kpiPunctualityView.render(handlers);
      break;
    }
    case 'kpiTransits': {
      const { data: subjects } = await model.getSubjects();
      const date = new Date();
      const fromDate = DATE.getDateTime(
        new Date(DATE.getCurrentMonthStartDate(date)),
        '00:00:00'
      );
      const toDate = DATE.getDateTime(
        new Date(DATE.getCurrentMonthEndDate(date)),
        '23:59:59'
      );
      const { data } = await model.getKpiTransitTimes({
        fromDate: fromDate,
        toDate: toDate,
        idCarrier: null,
        idSubject: null,
      });
      const handlers = {
        data,
        fromDate,
        toDate,
        subjects,
        handlerGetData: getKpiTransitTimes,
      };
      kpiTransitsView.render(handlers);
      break;
    }
    case 'kpiCarriers': {
      const { data } = await model.getCarrierPerformance();
      const hanlders = {
        data,
      };
      kpiCarriersView.render(hanlders);
      break;
    }
    case 'kpiSlotsOccupation': {
      const date = new Date();
      const fromDate = DATE.getDateTime(
        new Date(DATE.getCurrentMonthStartDate(date)),
        '00:00:00'
      );
      const toDate = DATE.getDateTime(
        new Date(DATE.getCurrentMonthEndDate(date)),
        '23:59:59'
      );
      const { data } = await model.getKpiSlotOccupations({
        fromDate: fromDate,
        toDate: toDate,
        type: 3,
      });
      const handlers = {
        data,
        fromDate: fromDate,
        toDate: toDate,
        handlerGetData: getKpiSlotOccupations,
      };
      kpiSlotsOccupationView.render(handlers);
      break;
    }
    default:
      // NOTE Remove later
      confirm('Page not found');
      goHome();
      break;
  }
};

// Load content based on saved navigation and router
const loadContent = function (options, callback = undefined) {
  const { list, content, tabs } = options.dxComponents;

  $('.tabsContainer').show();

  // Main navigation on left
  if (list) {
    list.dxList('instance').selectItem(options.index);
  }

  // Load once, if menu exists then skip, do not load twice.
  if (content && !tabs) {
    // debugLog(options.file);
    content.load(options.file, function () {
      if (callback) callback();
    });
  }

  // Select tab
  tabs && tabs.dxTabs('instance').option('selectedIndex', options.selectedTab);
};

// Unload content
const unloadContent = function (options, callback = undefined) {
  const { list, content, tabs } = options.dxComponents;

  // Hide tabs
  $('.tabsContainer').hide();

  // Uncheck left menu selected item
  if (list) {
    list.dxList('instance').unselectAll();
  }

  // Load HTML file in #content
  if (content) {
    // debugLog(options.file);
    content.load(options.file, function () {
      if (callback) callback();
    });
  }

  // Uncheck tabs if selected
  tabs && tabs.dxTabs('instance').option('selectedIndex', -1);
};

// Reset content, remove data from localStorage
const resetContent = function () {
  resetUser();
  resetDictionary();
  navigation.removeItem();
};

// ##################################
// Handle login/logout
// ##################################
const login = async function (usr) {
  const { data, result, message } = await model.login(usr);
  if (result.toLowerCase() !== 'ok') {
    helpers.showErrorDialog(message);
    return;
  }
  const user = sharedUser();
  const newUser = data.data;
  newUser.token = data.token;
  newUser.isLogged = true;
  user.setUser(newUser);

  // Set default company
  // setCompany();

  // DeInit login view
  loginView.deInit();

  // Show home and redirect to page selected by user's role
  // Example: if SUPADMIN => open tenants, ADMIN => open users
  showHome(user, true);
  // Start polling
  polling = user.account.role !== 'SUPADMIN';
  subscribe();
};

const logout = async function () {
  resetContent();
  showLogin();

  // Stop polling
  polling = false;
};

// ##################################
// POLLING
// ##################################
const updateMessages = async function () {
  const res = await model.getUnreadMessagesCount();
  const { result, data } = res;
  if (result?.toLowerCase() === 'ok') {
    navigation.updateMessagesBadge(data.numberMessages);
  }
};

const subscribe = async function (wait) {
  if (!polling || !POLLING_IS_ACTIVE) return;

  if (wait) await model.wait(POLLING_SEC);

  const res = await model.getUnreadMessagesCount();
  const { result, data } = res;
  if (result?.toLowerCase() !== 'ok') {
    // debugLog(`POLLING ERROR WITH CODE ${result}`);
    await subscribe(true);
  } else {
    // ##################################
    // Check polling timer
    // ##################################
    const curTime = new Date().getTime();
    const diff = curTime - pollingTime;
    pollingTime = curTime;
    // debugLog(`POLLING EXECUTED WITH INTERVAL: ${parseInt(diff / 1000)} SEC`);
    navigation.updateMessagesBadge(data.numberMessages);
    await subscribe(true);
  }
};

// Setup tentant and company (navigation)
const setTenant = function (id) {
  const user = sharedUser();
  user.setTenant(id);
};

const setCompany = async function (id = undefined) {
  const user = sharedUser();
  if (!id) {
    const companies = await getCompanies();
    if (companies && companies.length > 0) id = companies[0].id;
    else helpers.showErrorDialog('No companies found for user!');
  }
  user.setCompany(id);
};

// ##################################
// Handlers
// ##################################

//#region User
const createUser = async function (usr, callback) {
  const res = await model.createUser(usr);
  if (helpers.processResponse(res)) {
    if (callback) callback();
  }
};

const modifyUser = async function (usr, callback) {
  const { data, result, message } = await model.modifyUser(usr);
  if (result.toLowerCase() !== 'ok') {
    helpers.showErrorDialog(message);
    return;
  }
  const user = sharedUser();
  const newUser = data;
  if (user.id === newUser.id) {
    newUser.token = user.token;
    newUser.idCompany = user.idCompany;
    newUser.isLogged = true;
    user.setUser(newUser);
    helpers.showSuccessDialog('Modified');
  }
  if (callback) callback();
};

const deleteUser = async function (usr, callback) {
  const res = await model.deleteUser(usr);
  if (helpers.processResponse(res)) {
    if (callback) callback();
  }
};
//#endregion

//#region Tenants
const createTenant = async function (tnt, callback) {
  const res = await model.createTenant(tnt);
  if (helpers.processResponse(res)) {
    const { data: tenants } = await model.getTenants();
    if (callback) callback(tenants, res);
  }
};

const modifyTenant = async function (tnt, callback) {
  const res = await model.modifyTenant(tnt);
  if (helpers.processResponse(res)) {
    const { data: tenants } = await model.getTenants();
    if (callback) callback(tenants);
  }
};

const deleteTenant = async function (tnt, callback) {
  const res = await model.deleteTenant(tnt);
  if (helpers.processResponse(res)) {
    const { data: tenants } = await model.getTenants();
    if (callback) callback(tenants);
  }
};
//#endregion

//#region Companies
const createCompany = async function (comp, callback) {
  const res = await model.createCompany(comp);
  if (helpers.processResponse(res)) {
    const { data: companies } = await model.getCompanies();
    if (callback) callback(companies, res);
  }
};

const modifyCompany = async function (comp, callback) {
  const res = await model.modifyCompany(comp);
  if (helpers.processResponse(res)) {
    const { data: companies } = await model.getCompanies();
    if (callback) callback(companies);
  }
};

const deleteCompany = async function (comp, callback) {
  const res = await model.deleteCompany(comp);
  if (helpers.processResponse(res)) {
    const { data: companies } = await model.getCompanies();
    if (callback) callback(companies);
  }
};

const getCompanies = async function () {
  const res = await model.getCompanies();
  if (helpers.processResponse(res)) {
    return res.data;
  }
};
//#endregion

//#region Scheduler
const getAppointments = async function (app, callback) {
  const res = await model.getAppointments(app);
  if (helpers.processResponse(res)) {
    if (callback) callback(res);
  }
};
const getAppointmentPDF = async function (app, callback) {
  const res = await model.getAppointmentPDF(app);
  if (helpers.processResponse(res)) {
    if (callback) callback(res);
  }
};

const getAppointmentAttachment = async function (fileUrl, callback) {
  const res = await model.getAppointmentAttachment(fileUrl);
  if (helpers.processResponse(res)) {
    if (callback) callback(res);
  }
};

const createAppointment = async function (app, callback) {
  const res = await model.createAppointment(app);
  if (helpers.processResponse(res)) {
    if (callback) callback();
  }
};

const createMultiAppointment = async function (app, callback) {
  const res = await model.createMultiAppointment(app);
  if (helpers.processResponse(res)) {
    if (callback) callback();
  }
};

const modifyAppointment = async function (app, callback) {
  const res = await model.modifyAppointment(app);
  if (helpers.processResponse(res)) {
    if (callback) callback();
  }
};

const deleteAppointment = async function (app, callback) {
  const res = await model.deleteAppointment(app);
  if (helpers.processResponse(res)) {
    if (callback) callback();
  }
};
//#endregion

//#region Logs
const getArchives = async function (query, callback) {
  const data = {
    CodeAppointmentStatus: `${query.status === -1 ? '' : query.status}`,
    da: DATE.getDateTime(query.from, '00:00:00'),
    a: DATE.getDateTime(query.to, '23:59:59'),
  };
  // console.log(data);
  const res = await model.getAppointmentsHistory(data);
  if (helpers.processResponse(res)) {
    if (callback) callback(res.data);
  }
};
//#endregion

//#region Subject
const createSubject = async function (sbj, callback) {
  const res = await model.createSubject(sbj);
  if (helpers.processResponse(res)) {
    const { data: subjects } = await model.getSubjects();
    if (callback) callback(subjects);
  }
};

const modifySubject = async function (sbj, callback) {
  const res = await model.modifySubject(sbj);
  if (helpers.processResponse(res)) {
    const { data: subjects } = await model.getSubjects();
    if (callback) callback(subjects);
  }
};

const deleteSubject = async function (sbj, callback) {
  const res = await model.deleteSubject(sbj);
  if (helpers.processResponse(res)) {
    const { data: subjects } = await model.getSubjects();
    if (callback) callback(subjects);
  }
};
//#endregion

//#region Site
const createSite = async function (st, callback) {
  const res = await model.createSite(st);
  const { data: sites } = await model.getSites();
  if (helpers.processResponse(res)) {
    if (callback) callback(sites);
  }
};

const modifySite = async function (st, callback) {
  const res = await model.modifySite(st);
  const { data: sites } = await model.getSites();
  if (helpers.processResponse(res)) {
    if (callback) callback(sites);
  }
};

const deleteSite = async function (st, callback) {
  const res = await model.deleteSite(st);
  const { data: sites } = await model.getSites();
  if (helpers.processResponse(res)) {
    if (callback) callback(sites);
  }
};

const lockSite = async function (st, data, callback) {
  const res = await model.lockSite(st, data);
  if (helpers.processResponse(res)) {
    const { data: sites } = await model.getSites();
    if (callback) callback(sites);
  }
};
//#endregion

//#region Slot
const createSlot = async function (sl, callback) {
  const res = await model.createSlot(sl);
  if (helpers.processResponse(res)) {
    const { data: slots } = await model.getSlots();
    if (callback) callback(slots);
  }
};

const modifySlot = async function (sl, callback) {
  const res = await model.modifySlot(sl);
  if (helpers.processResponse(res)) {
    const { data: slots } = await model.getSlots();
    if (callback) callback(slots);
  }
};

const deleteSlot = async function (sl, callback) {
  const res = await model.deleteSlot(sl);
  if (helpers.processResponse(res)) {
    const { data: slots } = await model.getSlots();
    if (callback) callback(slots);
  }
};

const getAppointmentsOperationType = async function (callback) {
  const res = await model.getAppointmentsOperationType();
  res.data.forEach(element => {
    element.code = +element.code;
  });
  if (helpers.processResponse(res)) {
    return res;
  }
};
//#endregion

//#region Veichle
const createVehicleType = async function (sbj, callback) {
  const res = await model.createVehicleType(sbj);
  if (helpers.processResponse(res)) {
    const { data: vehiclesTypes } = await model.getVehiclesTypes();
    if (callback) callback(vehiclesTypes);
  }
};

const modifyVehicleType = async function (sbj, callback) {
  const res = await model.modifyVehicleType(sbj);
  if (helpers.processResponse(res)) {
    const { data: vehiclesTypes } = await model.getVehiclesTypes();
    if (callback) callback(vehiclesTypes);
  }
};

const deleteVehicleType = async function (sbj, callback) {
  const res = await model.deleteVehicleType(sbj);
  if (helpers.processResponse(res)) {
    const { data: vehiclesTypes } = await model.getVehiclesTypes();
    if (callback) callback(vehiclesTypes);
  }
};
//#endregion

//#region ProductType
const createProductType = async function (sbj, callback) {
  const res = await model.createProductType(sbj);
  if (helpers.processResponse(res)) {
    const { data: productTypes } = await model.getProductTypes();
    if (callback) callback(productTypes);
  }
};

const modifyProductType = async function (sbj, callback) {
  const res = await model.modifyProductType(sbj);
  if (helpers.processResponse(res)) {
    const { data: productTypes } = await model.getProductTypes();
    if (callback) callback(productTypes);
  }
};

const deleteProductType = async function (sbj, callback) {
  const res = await model.deleteProductType(sbj);
  if (helpers.processResponse(res)) {
    const { data: productTypes } = await model.getProductTypes();
    if (callback) callback(productTypes);
  }
};
//#endregion

//#region SlotLine
const createSlotLine = async function (sbj, callback) {
  const res = await model.createSlotLine(sbj);
  if (helpers.processResponse(res)) {
    const { data: slotLines } = await model.getSlotLines();
    if (callback) callback(slotLines);
  }
};

const modifySlotLine = async function (sbj, callback) {
  const res = await model.modifySlotLine(sbj);
  if (helpers.processResponse(res)) {
    const { data: slotLines } = await model.getSlotLines();
    if (callback) callback(slotLines);
  }
};

const deleteSlotLine = async function (sbj, callback) {
  const res = await model.deleteSlotLine(sbj);
  if (helpers.processResponse(res)) {
    const { data: slotLines } = await model.getSlotLines();
    if (callback) callback(slotLines);
  }
};
//#endregion

//#region SlotType
const createSlotType = async function (sbj, callback) {
  const res = await model.createSlotType(sbj);
  if (helpers.processResponse(res)) {
    const { data: slotTypes } = await model.getSlotTypes();
    if (callback) callback(slotTypes);
  }
};

const modifySlotType = async function (sbj, callback) {
  const res = await model.modifySlotType(sbj);
  if (helpers.processResponse(res)) {
    const { data: slotTypes } = await model.getSlotTypes();
    if (callback) callback(slotTypes);
  }
};

const deleteSlotType = async function (sbj, callback) {
  const res = await model.deleteSlotType(sbj);
  if (helpers.processResponse(res)) {
    const { data: slotTypes } = await model.getSlotTypes();
    if (callback) callback(slotTypes);
  }
};
//#endregion

//#region Messages
const getMessages = async function (query, callback) {
  const data = {
    da: DATE.getDateTime(query.from, '00:00:00'),
    a: DATE.getDateTime(query.to, '23:59:59'),
  };
  // console.log(data);
  const res = await model.getMessages(data);
  if (helpers.processResponse(res)) {
    if (callback) callback(res.data);
  }
};

const isRead = async function (msg, callback) {
  const res = await model.isRead(msg, {
    isRead: true,
  });
  if (helpers.processResponse(res)) {
    if (callback) callback();
    updateMessages();
  }
};

const updateAllMessage = async function (query, callback) {
  const data = {
    isRead: true,
    // da: DATE.getDateTime(query.from, '00:00:00'),
    // a: DATE.getDateTime(query.to, '23:59:59'),
  };
  // console.log(data);
  const res = await model.updateAllMessage(data);
  if (helpers.processResponse(res)) {
    if (callback) callback();
    updateMessages();
  }
};

const deleteMessage = async function (msg, callback) {
  const res = await model.deleteMessage(msg);
  if (helpers.processResponse(res)) {
    if (callback) callback();
    updateMessages();
  }
};
//#endregion

//#region Logs
const getLogs = async function (query, callback) {
  const data = {
    tipo: `${query.type === '-1' ? '' : query.type}`,
    dateFrom: DATE.getDateTime(query.from, '00:00:00'),
    dateTo: DATE.getDateTime(query.to, '23:59:59'),
  };
  // console.log(data);
  const res = await model.getLogs(data);
  if (helpers.processResponse(res)) {
    if (callback) callback(res.data);
  }
};
//#endregion

//#region KPI Puncutality
const getKpiPunctuality = async function (query, callback) {
  const data = {
    year: query.year,
    idCarrier: query.idCarrier,
    idSubject: query.idSubject,
  };
  const res = await model.getKpiPunctuality(data);
  if (helpers.processResponse(res)) {
    if (callback) callback(res.data);
  }
};

const getKpiTransitTimes = async function (query, callback) {
  const data = {
    fromDate: DATE.getDateTime(new Date(query.from), '00:00:00'),
    toDate: DATE.getDateTime(new Date(query.to), '23:59:59'),
    idCarrier: query.idCarrier,
    idSubject: query.idSubject,
  };
  const res = await model.getKpiTransitTimes(data);
  if (helpers.processResponse(res)) {
    if (callback) callback(res.data);
  }
};

// NOT USED!!!
const getKpiCarriers = async function (callback) {
  // const res = await model.getKpiCarriers(data);
  // if (helpers.processResponse(res)) {
  //   if (callback) callback(res.data);
  // }
};

const getKpiSlotOccupations = async function (query, callback) {
  const data = {
    fromDate: DATE.getDateTime(new Date(query.from), '00:00:00'),
    toDate: DATE.getDateTime(new Date(query.to), '23:59:59'),
    type: query.type,
  };
  const res = await model.getKpiSlotOccupations(data);
  if (helpers.processResponse(res)) {
    if (callback) callback(res.data);
  }
};
//#endregion

const showUserDetails = function () {
  // Load saved navigation item
  const route = router.getRoute('UserDetails');

  unloadContent(
    {
      file: route.file,
      dxComponents: components,
    },
    function () {
      const user = sharedUser();
      const handlers = {
        user,
        handlerModify: modifyUser,
        handlerReloadDictionary: reloadDictionary,
      };
      userDetailsView.render(handlers);
    }
  );
};

const showLogin = function () {
  // 1) Render loginView
  // loginView.renderHTML();
  const route = router.getRoute('Login');
  $('.dx-viewport').load(route.file, function () {
    const handlers = {
      handlerLogin: login,
      handlerReset: showResetPassword,
    };
    loginView.render(handlers);
    location.replace(route.path);
  });
};

const showResetPassword = async function (email) {
  const route = router.getRoute('resetPassword');
  $('.dx-viewport').load(route.file, function () {
    const handlers = {
      handlerReset: resetPassword,
      handlerLogin: showLogin,
    };
    resetPasswordView.render(handlers);
    location.replace(route.path);
  });
};

const resetPassword = async function (email) {
  const { data, result, message } = await model.resetPassword(email);
  if (result.toLowerCase() !== 'ok') {
    helpers.showErrorDialog(message);
    return;
  } else {
    //helpers.showSuccessDialog(message);
    //showLogin();
    helpers.showSuccessDialog('Your password has been changed successfully.');
    return;
  }
};

// Update dictionary if server's dictionary version is greater than local
// Update also languages if version changes
// Tip: to update languages (add/remove) the version should be incremented
const updateDictionaryIfNeeded = async function (lang) {
  const { data, result } = await model.getTranslationsVersion();
  if (result.toLowerCase() === 'ok') {
    const version = data.version;
    const dict = sharedDict();
    if (dict.checkDictVersion(version)) {
      const { data: languages } = await model.getLanguages();
      const { data: dictionary } = await model.getTranslations(lang);
      dict.setDictionary({
        languages: languages,
        dictionary: dictionary,
        version: version,
      });
    }
  }
};

const reloadDictionary = function (usr) {
  // Reset dictionary
  resetDictionary();
  // Update dictionary
  updateDictionaryIfNeeded(usr.language);
  // Update use
  const user = sharedUser();
  user.setUser(usr);
};

const showHome = async function (usr, redirectNeeded = false) {
  // Load dictionary, update if needed
  const lang = usr ? usr.language : 'ENG';
  await updateDictionaryIfNeeded(lang);
  await showMenu(usr);
  const route = router.getRoute('Home');
  // Define navigation for current user
  $('.dx-viewport').load(route.file, function () {
    const handlers = {
      handlerLoadPage: loadPage,
      handlerLogout: logout,
      handlerContentReady: onHomeContentReady,
      navigation: navigation.getNavigation(),
      toolbar: navigation.getToolbar(),
    };
    indexView.render(handlers, function (e) {
      // Save components
      components = e;
      // debugLog(components);

      // Load saved navigation item
      const navItem = navigation.getItem();

      // If item not found or router is not defined then load HOME
      if (!navItem || !router.getRoute(navItem.path)) {
        location.replace(route.path);
      } else {
        loadContent({
          file: router.getRoute(navItem.path).file,
          index: navItem.id,
          selectedTab: navItem.selectedTab,
          dxComponents: e,
        });
      }

      if (redirectNeeded) {
        let curPage;
        switch (usr.account.role) {
          case 'SUPADMIN':
            curPage = 'tenants';
            break;
          case 'ADMIN':
            curPage = 'users';
            break;
          default:
            curPage = 'scheduler';
            break;
        }
        navigation.setItem(curPage);
      }
    });
  });
};

const showMenu = async function (usr) {
  const role = usr.account.role;
  navigation.setNavigation(role);

  let tenants = [];
  let companies = [];

  // Get tenants if supadmin
  if (role === 'SUPADMIN') {
    let data = await model.getTenants();
    if (data.data.length > 0) {
      tenants = data.data;
    }
  }

  // Get comapnies if supadmin and tenants defined or the role is <> supadmin
  if ((role === 'SUPADMIN' && tenants.length > 0) || role !== 'SUPADMIN') {
    let data = await model.getCompanies();
    if (data.data.length > 0) companies = data.data;
  }

  const handlers = {
    handlerLogout: logout,
    handlerTenant: setTenant,
    handlerCompany: setCompany,
    handlerReload: showHome,
    handlerUserDetails: showUserDetails,
    user: usr,
    tn: tenants,
    cmp: companies,
    version: APP_VERSION,
  };
  navigation.setToolbar(handlers);
};

// Executed when Home content is ready
const onHomeContentReady = function () {
  // console.log('HOME CONTENT IS READY');
  updateMessages();
};

// NOTE Remove later
const goHome = function () {
  navigation.removeItem();
  const user = sharedUser();
  showHome(user);
};

// ##################################
// Document ready listener
// ##################################
// document.addEventListener('DOMContentLoaded', function () {
//   debugLog('DOCUMENT IS READY');
// });

// ##################################
// Window listener
// ##################################
window.addEventListener('load', async function () {
  debugLog('WINDOW LOAD');
  // Remove saved navigation if # not found
  // Reload Home

  const user = sharedUser();
  if (user.isLogged) {
    await updateDictionaryIfNeeded(user.language);
  }

  const curHash = this.location.hash;
  if (!curHash || !navigation.findItem(curHash.slice(1))) {
    navigation.removeItem();
  }

  // debugLog(router.getCurRoute().path);
  // location.replace(router.getCurRoute().path);
  // if (this.location.pathname === routeIndex.file) {
  //   this.location.replace('/');
  //   debugLog('WINDOW LOAD EVENT');
  // }
  // Check if user logged, then update dictionary if needed
  // const user = {
  //   language: 'en',
  // };
  // updateDictionary(user.language);
});

// ##################################
// Initialization
// ##################################
export const init = async function () {
  const user = sharedUser();
  if (!user?.isLogged) {
    debugLog('USER NOT LOGGED');
    showLogin();
  } else {
    debugLog('USER LOGGED');
    showHome(user);
    // Start polling
    polling = user.account.role !== 'SUPADMIN';
    subscribe(false);
  }
};

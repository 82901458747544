import View from './view.js';
import { sharedDict } from './entities/dictionary.js';

export default class ArchiveView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup parameters and controller handlers
        const {
          user,
          archives: data,
          archiveAppointmentStatus,
          handlerGetArchives: getArchives,
        } = handlers;
        // Add appointStatus All
        archiveAppointmentStatus.unshift({
          code: -1,
          nameKeyTranslation: 'WEB_ARCHIVE_ALL',
        });
        // Map dictionary object
        const appointmentStatus = archiveAppointmentStatus.map(
          ({ code, nameKeyTranslation }) => {
            return {
              code: +code,
              name: dict.getTextForKey(nameKeyTranslation),
            };
          }
        );
        // Map appointment status
        const appointmentStatusDatasource = appointmentStatus
          .filter(el => el.code !== -1)
          .map(({ code, name }) => {
            return {
              text: name,
              value: code,
            };
          });
        // Setup default date to now, used to compose filters results
        const date = new Date();
        let query = {
          status: -1,
          from: date,
          to: date,
        };
        // Setup dataGrid
        const dataGrid = $('#grid-archive')
          .dxDataGrid({
            dataSource: data,
            showBorders: true,
            columnAutoWidth: true,
            allowColumnReordering: true,
            rowAlternationEnabled: true,
            toolbar: {
              items: [
                {
                  location: 'after',
                  widget: 'dxDateBox',
                  options: {
                    label: dict.getTextForKey('WEB_ARCHIVE_FROM'),
                    type: 'date',
                    value: date,
                    onValueChanged(e) {
                      query.from = e.value;
                    },
                  },
                },
                {
                  location: 'after',
                  widget: 'dxDateBox',
                  options: {
                    label: dict.getTextForKey('WEB_ARCHIVE_TO'),
                    type: 'date',
                    value: date,
                    onValueChanged(e) {
                      query.to = e.value;
                    },
                  },
                },
                {
                  location: 'after',
                  widget: 'dxSelectBox',
                  options: {
                    width: 'auto',
                    label: dict.getTextForKey('WEB_ARCHIVE_STATUS'),
                    items: appointmentStatus,
                    value: appointmentStatus[0].code,
                    valueExpr: 'code',
                    displayExpr: 'name',
                    onValueChanged(e) {
                      query.status = e.value;
                    },
                  },
                },
                {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    icon: 'search',
                    onClick(e) {
                      getArchives(query, function (data) {
                        // console.log(data);
                        // Assign new data
                        dataGrid.option('dataSource', data);
                        // Reload data
                        reloadData();
                      });
                    },
                  },
                },
              ],
            },
            headerFilter: {
              visible: true,
            },
            filterPanel: { visible: true },
            filterRow: { visible: true },
            paging: {
              pageSize: 10,
            },
            pager: {
              visible: true,
              allowedPageSizes: [10, 25, 'all'],
              showPageSizeSelector: true,
              showInfo: true,
              showNavigationButtons: true,
            },
            columns: [
              {
                dataField: 'idAppointment',
                dataType: 'string',
                // sortIndex: 0,
                // sortOrder: 'desc',
                caption: dict.getTextForKey('WEB_ARCHIVE_ID'),
              },
              {
                dataField: 'date',
                dataType: 'string',
                // sortIndex: 1,
                sortOrder: 'desc',
                caption: dict.getTextForKey('WEB_ARCHIVE_DATE'),
                calculateDisplayValue: function (e) {
                  return new Date(e.date).toLocaleString(user.language);
                },
              },
              {
                dataField: 'subject',
                dataType: 'string',
                caption: dict.getTextForKey('WEB_ARCHIVE_SUBJECT'),
              },
              {
                dataField: 'codeAppointmentStatus',
                headerFilter: {
                  dataSource: appointmentStatusDatasource,
                },
                dataType: 'string',
                caption: dict.getTextForKey('WEB_ARCHIVE_STATUS'),
                calculateDisplayValue: function (e) {
                  return appointmentStatus.find(
                    el => el.code === e.codeAppointmentStatus
                  ).name;
                },
              },
            ],
          })
          .dxDataGrid('instance');

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        const getDataSource = function () {
          return $('#grid-archive').dxDataGrid('getDataSource');
        };

        const getData = function () {
          return getDataSource().items();
        };

        const reloadData = function () {
          getDataSource().load();
        };
      });
    } catch (err) {
      console.error(err);
    }
  };
}

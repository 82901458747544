import { debugLog } from '../utilities/logger.js';

// Private class
class User {
  id;
  idAccount;
  idTenant;
  idCompany;
  account;
  userCompany;
  email;
  name;
  surname;
  language;
  phoneNumber;
  note;
  roles;
  isLogged;
  isEnabled;
  token;

  setUser(data) {
    // const { name, surname, token, isLogged } = data;
    this.id = data.id;
    this.idAccount = data.idAccount;
    this.idTenant = data.idTenant;
    this.idCompany = data.idCompany;
    this.account = data.account;
    this.userCompany = data.userCompany;
    this.email = data.email;
    this.name = data.name;
    this.surname = data.surname;
    this.language = data.language;
    this.phoneNumber = data.phoneNumber;
    this.note = data.note;
    this.roles = data.roles;
    this.isEnabled = data.isEnabled;
    this.isLogged = data.isLogged;
    this.token = data.token;
    serialize(this);
  }

  isLogged() {
    return this.isLogged;
  }

  setTenant(id) {
    this.idTenant = id;
    serialize(this);
  }

  setCompany(id) {
    this.idCompany = id;
    serialize(this);
  }

  print() {
    console.log(
      `name: ${this.name}, surname: ${this.surname}, isLogged: ${this.isLogged}`
    );
  }
}

const serialize = function (usr) {
  localStorage.setItem('sharedUser', JSON.stringify(usr));
};

const deserialize = function () {
  return JSON.parse(localStorage.getItem('sharedUser'));
};

// Create shared instance
let user = null;

export const sharedUser = function () {
  if (!user) {
    user = new User();
    const savedUser = deserialize();
    if (savedUser) user.setUser(savedUser);
  }
  DevExpress.localization.locale(user.language?.toLowerCase());
  return user;
};

export const resetUser = function () {
  localStorage.removeItem('sharedUser');
  user = null;
};

// ##################################
// Helper function "runOnce"
// ##################################
// (function () {
//   user = new User();
//   console.log('New user created');
// })();
// ##################################
// Equivalent arrow function
// (() => {
//   sharedUser = new User();
//   console.log('New user created');
// })();
// Generate random number
// getRandomInt(max) {
//   return Math.floor(Math.random() * max);
// }
// ##################################
// Helper function "runOnce"
// ##################################

import View from './view.js';
import { sharedDict } from './entities/dictionary.js';

export default class KPITransitsView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup parameters and controller handlers
        const {
          data: dataSource,
          subjects,
          fromDate,
          toDate,
          handlerGetData: getData,
        } = handlers;

        // Setup filter roles
        const filteredRoles = [
          {
            name: dict.getTextForKey('WEB_KPI_CUSTOMER'),
            type: 1,
          },
          {
            name: dict.getTextForKey('WEB_KPI_SUPPLIER'),
            type: 2,
          },
        ];
        // Setup filters
        let datePickerFromDate;
        let datePickerToDate;
        let selectBoxCarrier;
        let selectBoxSubjectType;
        let selectBoxSubject;
        let query = {
          from: fromDate,
          to: toDate,
          idCarrier: null,
          idSubject: null,
        };
        const toolbar = $('#toolbar-kpi-transits')
          .dxToolbar({
            items: [
              {
                location: 'after',
                widget: 'dxDateBox',
                options: {
                  label: dict.getTextForKey('WEB_KPI_FROM'),
                  type: 'date',
                  value: fromDate,
                  onInitialized: function (e) {
                    datePickerFromDate = e.component;
                  },
                  onValueChanged(e) {
                    query.from = e.value;
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxDateBox',
                options: {
                  label: dict.getTextForKey('WEB_KPI_TO'),
                  type: 'date',
                  value: toDate,
                  onInitialized: function (e) {
                    datePickerToDate = e.component;
                  },
                  onValueChanged(e) {
                    query.to = e.value;
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  width: 'auto',
                  label: dict.getTextForKey('WEB_KPI_CARRIER'),
                  items: subjects.filter(
                    el => el.type === 3 && el.isEnabled === true
                  ),
                  displayExpr: 'name',
                  valueExpr: 'id',
                  onInitialized: function (e) {
                    selectBoxCarrier = e.component;
                  },
                  onValueChanged(e) {
                    query.idCarrier = e.value;
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  width: 'auto',
                  label: dict.getTextForKey('WEB_KPI_SUBJECT_TYPE'),
                  items: filteredRoles,
                  displayExpr: 'name',
                  valueExpr: 'type',
                  onInitialized: function (e) {
                    selectBoxSubjectType = e.component;
                  },
                  onValueChanged(e) {
                    // Clear old value
                    selectBoxSubject.option('value', null);
                    // Filter subjects for the current idCompany, isEnabled = true
                    selectBoxSubject
                      .getDataSource()
                      .filter(['type', '=', e.value], ['isEnabled', '=', true]);
                    selectBoxSubject.getDataSource().reload();
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  width: 'auto',
                  label: dict.getTextForKey('WEB_KPI_SUBJECTS'),
                  items: subjects,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  onInitialized: function (e) {
                    selectBoxSubject = e.component;
                  },
                  onValueChanged(e) {
                    query.idSubject = e.value;
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  type: 'default',
                  text: dict.getTextForKey('WEB_KPI_RESET'),
                  onClick(e) {
                    datePickerFromDate.option('value', fromDate);
                    datePickerToDate.option('value', toDate);
                    selectBoxCarrier.option('value', null);
                    selectBoxSubjectType.option('value', null);
                    selectBoxSubject.option('value', null);
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'search',
                  onClick(e) {
                    getData(query, function (data) {
                      reloadData(data);
                    });
                  },
                },
              },
            ],
          })
          .dxToolbar('instance');

        const chart = $('#chart-kpi-transits')
          .dxPieChart({
            palette: 'bright',
            dataSource,
            title: dict.getTextForKey('WEB_KPI_TRANSITS_CHART_TITLE'),
            legend: {
              orientation: 'horizontal',
              itemTextPosition: 'right',
              horizontalAlignment: 'center',
              verticalAlignment: 'bottom',
              columnCount: 4,
            },
            export: {
              enabled: true,
            },
            series: [
              {
                argumentField: 'name',
                valueField: 'numberOfTimes',
                label: {
                  visible: true,
                  font: {
                    size: 16,
                  },
                  connector: {
                    visible: true,
                    width: 0.5,
                  },
                  position: 'columns',
                  customizeText(arg) {
                    return `${arg.valueText} (${arg.percentText})`;
                  },
                },
              },
            ],
          })
          .dxPieChart('instance');

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        function reloadData(data) {
          chart.option('dataSource', data);
          chart.refresh();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

// ##################################
// Helper Date
// ##################################

// Private function
const getDaysInCurrentMonth = function (date = new Date()) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

// Public functions
// Get date
export const getDate = function (date = new Date()) {
  return `${('0' + (date.getMonth() + 1)).slice(-2)}/${(
    '0' + date.getDate()
  ).slice(-2)}/${date.getFullYear()}`;
};

// Get time
export const getTime = function (date = new Date(), time = undefined) {
  return `${!time ? date.toTimeString().split(' ')[0] : time}`;
};

// Get date time
export const getDateTime = function (date = new Date(), time = undefined) {
  return `${getDate(date)} ${getTime(date, time)}`;
};

// Get current month start date
export const getCurrentMonthStartDate = function (
  date = new Date(),
  time = undefined
) {
  return `${('0' + (date.getMonth() + 1)).slice(-2)}/${'01'.slice(
    -2
  )}/${date.getFullYear()}`;
};

// Get current month end date
export const getCurrentMonthEndDate = function (
  date = new Date(),
  time = undefined
) {
  return `${('0' + (date.getMonth() + 1)).slice(-2)}/${(
    '0' + getDaysInCurrentMonth(date)
  ).slice(-2)}/${date.getFullYear()}`;
};

// Get current month start date time
export const getCurrentMonthStartDateTime = function (
  date = new Date(),
  time = undefined
) {
  return `${getCurrentMonthStartDate(date)} ${getTime(date, time)}`;
};

// Get current month end date time
export const getCurrentMonthEndDateTime = function (
  date = new Date(),
  time = undefined
) {
  return `${getCurrentMonthEndDate(date)} ${getTime(date, time)}`;
};

import View from './view.js';
import { sharedDict } from './entities/dictionary.js';
import {
  OPERATIONTYPE,
  APPOINTMENTRESULT,
  ATTACHMENTBASE64,
} from './utilities/config.js';
import cloneDeep from '../node_modules/lodash-es/cloneDeep.js';

export default class SchedulerView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Map APPOINTMENTRESULT into result array
        const results = APPOINTMENTRESULT.map(({ code, name }) => {
          return {
            code: code,
            name: dict.getTextForKey(name),
          };
        });
        // Setup parameters and controller handlers
        let {
          schedulerAppointments,
          user,
          schedulerStatus,
          schedulerSites,
          schedulerSlots,
          schedulerSubjects,
          schedulerOperationTypes,
          roles,
          handlerGetAppointment: getAppointments,
          handlerGetAttachment: getAttachment,
          handlerPrintPDF: getAppointmentPDF,
          handlerCreate: create,
          handlerModify: modify,
          handlerDelete,
          handlerLock: lockSite,
          handlerCreateMulti: createMultiAppointment,
        } = handlers;
        let treeView;
        let currentSite;
        let currentStatus;
        // Workaround to fix the scheduler scroll bug
        let schedulerDidLoad = false;
        // Handle appointment status with colors to pass into resoures object
        const appStatus = [
          {
            id: 0,
            color: '#FAEE00',
          },
          {
            id: 10,
            color: '#04d416',
          },
          {
            id: 20,
            color: '#d61002',
          },
          {
            id: 30,
            color: '#FF8000',
          },
          {
            id: 40,
            color: '#0000ff',
          },
          {
            id: 50,
            color: '#0416d4',
          },
        ];

        // Assign to current site the first one
        if (schedulerSites !== null && schedulerSites.length > 0)
          currentSite = schedulerSites[0];

        // Map schedulerOperationTypes: convert code into INT, setup name parameter
        schedulerOperationTypes = schedulerOperationTypes.map(
          ({ code, nameKeyTranslation }) => {
            return {
              code: +code,
              name: dict.getTextForKey(nameKeyTranslation),
            };
          }
        );

        // Map roles by to trasform new role object
        roles = roles.map(({ code, nameKeyTranslation, visible }) => {
          return {
            code: code,
            name: dict.getTextForKey(nameKeyTranslation),
            visible: visible,
          };
        });

        // Check if sites are present, otherwise stop showing the empty scheduler
        if (!schedulerSites) return;

        // Filter isEnabeld
        schedulerSites = schedulerSites.filter(el => el.isEnabled === true);
        schedulerSlots = schedulerSlots.filter(
          el => el.isEnabled === true && el.isBusy === false
        );
        schedulerSubjects = schedulerSubjects.filter(
          el => el.isEnabled === true
        );

        if (schedulerSites.length === 0) return;
        let IdSite = schedulerSites[0].id;
        let appointments = schedulerAppointments.filter(
          x => x.isVisible === true
        );

        // Add new filter for appointment status ALL
        schedulerStatus.unshift({
          code: 99,
          nameKeyTranslation: 'WEB_SCHEDULER_ALL',
        });

        // Map appointment status: convert code into INT, assign name from dictionary
        const appointmentStatus = schedulerStatus.map(
          ({ code, nameKeyTranslation }) => {
            return {
              code: +code,
              name: dict.getTextForKey(nameKeyTranslation),
            };
          }
        );
        schedulerStatus = appointmentStatus;
        let mainStatus = schedulerStatus[0].code;
        // Read working time from site's calendar array
        let workingTimes = setWorkingTime(schedulerSites[0]);
        // Define holdays from site's fesitvities array
        let holidays = schedulerSites[0].festivities.map(el => {
          return {
            date: new Date(`${el.date}`).toLocaleDateString(),
            fromTime: el.fromTime,
            toTime: el.toTime,
          };
        });
        // Define hiddenAppointments signed by isVisible=false
        let hiddenAppointments = schedulerAppointments
          .filter(x => x.isVisible === false)
          .map(el => {
            return {
              date: new Date(`${el.arrivalTime}`).toLocaleDateString(),
              fromTime: new Date(`${el.arrivalTime}`)
                .toTimeString()
                .split(' ')[0],
              toTime: new Date(`${el.finishTime}`).toTimeString().split(' ')[0],
              idSlot: el.idSlot,
            };
          });
        let resourceSlot = getResourceSlot(schedulerSlots, IdSite);
        let schedulerVisible = true;
        // Handler simulate button
        let isAllowedSimulation = schedulerSites[0].isAllowedSimulation;
        // Set simulate button locked (disable)
        let locked = schedulerSites[0].isLocked;
        let simulate = false;
        if (resourceSlot.length === 0) {
          schedulerVisible = false;
        }
        // Init attachment array
        let arrayAttachments = [];
        // Setup appointments
        setAppointments();

        function getResourceSlot(slots, IdSite) {
          const slotsFiltered = slots.filter(el => el.idSite === IdSite);
          return slotsFiltered.map(el => {
            return {
              id: el.id,
              text: el.name,
            };
          });
        }

        // Customize tooltip when user clicks on appointment
        function getTooltipTemplate(app) {
          return $(
            `<div class="appointment-tooltip-container" style="background-color: ${
              appStatus.find(el => el.id === app.codeAppointmentStatus).color
            }">` +
              `<table style="background-color: ${
                appStatus.find(el => el.id === app.codeAppointmentStatus).color
              }">` +
              // Render subject
              `<tr>` +
              `<td class="align-right">${dict.getTextForKey(
                'WEB_SCHEDULER_SUBJECT'
              )}:</td>` +
              `<td class="align-left">${
                schedulerSubjects.find(el => el.id === app.idSubject).name
              }</td>` +
              `</tr>` +
              // Render carrier
              `<tr>` +
              `<td class="align-right">${dict.getTextForKey(
                'WEB_SCHEDULER_CARRIER'
              )}:</td>` +
              `<td class="align-left">${app.carriers[0].name}</td>` +
              `</tr>` +
              // Render arrival_time
              `<tr>` +
              `<td class="align-right">${dict.getTextForKey(
                'WEB_SCHEDULER_ARRIVAL_TIME'
              )}:</td>` +
              `<td class="align-left">${new Date(app.startDate).toLocaleString(
                user.language,
                { timeStyle: 'short', dateStyle: 'short' }
              )}</td>` +
              `</tr>` +
              // Render arrival_time
              `<tr>` +
              `<td class="align-right">${dict.getTextForKey(
                'WEB_SCHEDULER_FINISH_TIME'
              )}:</td>` +
              `<td class="align-left">${new Date(app.endDate).toLocaleString(
                user.language,
                { timeStyle: 'short', dateStyle: 'short' }
              )}</td>` +
              `</tr>` +
              // Render operationType
              `<tr>` +
              `<td class="align-right">${dict.getTextForKey(
                'WEB_SCHEDULER_OPERATIONTYPE'
              )}:</td>` +
              `<td class="align-left">${
                schedulerOperationTypes.find(s => s.code === app.operationType)
                  .name
              }</td>` +
              `</tr>` +
              // Render note
              `${
                app.note
                  ? `<tr>` +
                    `<td class="align-right">${dict.getTextForKey(
                      'WEB_SCHEDULER_NOTE'
                    )}:</td>` +
                    `<td class="align-left"><div class="truncate">${app.note}</div></td>` +
                    `</tr>`
                  : ''
              }` +
              `</table>` +
              `</div>`
          );
        }

        function setAppointments() {
          appointments.forEach(element => {
            element.startDate = new Date(`${element.arrivalTime}`);
            element.endDate = new Date(`${element.finishTime}`);
            const subject = schedulerSubjects.find(
              el => el.id === element.idSubject
            )?.name;
            const carrier = element?.carriers[0].name;
            const note = element.note;
            const operationType = dict.getTextForKey(
              OPERATIONTYPE.find(el => el.code === element.operationType)
                .localizedName
            );
            // console.log(element);
            // element.text = `${dict.getTextForKey(
            //   'WEB_SCHEDULER_SUBJECT'
            // )}: ${subject}, <br> ${dict.getTextForKey(
            //   'WEB_SCHEDULER_CARRIER'
            // )}: ${carrier}`;
            element.text = subject;
          });
        }

        // Handle popup close
        function closePopup(e) {
          e.component?._appointmentPopup?.popup.hide();
        }

        // Handle appointment delete
        function deleteAppointment(e) {
          const app = e.appointmentData;
          if (
            (user.account.role === 'SUPADMIN' ||
              user.account.role === 'ADMIN' ||
              user.account.role === 'INTERNAL' ||
              app.codeAppointmentStatus === 0) &&
            !simulate
          ) {
            if (!app.id) {
              closePopup(e);
              return;
            }
            handlerDelete(app, function () {
              reloadAppointments();
              closePopup(e);
            });
          } else {
            e.cancel = true;
            notifyDeleteNotValid();
          }
        }

        // Setup worktimes, reading from site.calendar array
        function setWorkingTime(site) {
          const workingTime = [];
          site.calendars
            .filter(c => c.isEnable === true)
            .forEach(element => {
              workingTime.push({
                day: element.day,
                fromTime: element.fromTime,
                toTime: element.toTime,
              });
            });
          return workingTime;
        }

        // Call to reload appointments
        function reloadAppointments() {
          const body = {
            idSite: IdSite,
            da: null,
            a: null,
          };
          getAppointments(body, function (res) {
            appointments = res.data.filter(x => x.isVisible === true);
            setAppointments();
            refreshScheduler();
          });
        }

        function manageToolbarButtons() {
          /////////////////////////////////////////////////////
          // NOTE: Get toolbar instance
          // $('#scheduler-toolbar')
          //   .dxToolbar('instance')
          //   .option(
          //     'items[2].options.disabled',
          //     !isAllowedSimulation
          //   );
          /////////////////////////////////////////////////////

          // Render 'Simulation' button visibility
          const simulationVisible =
            user.account.role === 'SUPADMIN' ||
            user.account.role === 'ADMIN' ||
            user.account.role === 'INTERNAL';

          // console.log(user.account.role);

          $('#scheduler-toolbar')
            .dxToolbar('instance')
            .option('items[2].options.visible', simulationVisible);

          // Render 'Simulation' disable if not allowed. Some other user took the LOCK
          $('#scheduler-toolbar')
            .dxToolbar('instance')
            .option('items[2].options.disabled', !isAllowedSimulation);

          // Render 'Simulation' button text
          if (isAllowedSimulation) {
            $('#scheduler-toolbar')
              .dxToolbar('instance')
              .option(
                'items[2].options.text',
                !locked
                  ? dict.getTextForKey('WEB_SCHEDULER_START_SIMULATION')
                  : dict.getTextForKey('WEB_SCHEDULER_END_SIMULATION')
              );
          } else {
            $('#scheduler-toolbar')
              .dxToolbar('instance')
              .option(
                'items[2].options.text',
                dict.getTextForKey('WEB_SCHEDULER_START_SIMULATION')
              );
          }
        }

        // Setup toolbar
        const toolbar = $('#scheduler-toolbar')
          .dxToolbar({
            onContentReady: function (e) {
              manageToolbarButtons();
            },
            items: [
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  width: 'auto',
                  label: dict.getTextForKey('WEB_SCHEDULER_SITE'),
                  items: schedulerSites,
                  valueExpr: 'id',
                  displayExpr: 'name',
                  value: schedulerSites[0].id,
                  onValueChanged(e) {
                    currentSite = schedulerSites.find(st => st.id === e.value);
                    holidays = currentSite.festivities.map(el => {
                      return {
                        date: new Date(`${el.date}`).toLocaleDateString(),
                        fromTime: el.fromTime,
                        toTime: el.toTime,
                      };
                    });
                    workingTimes = setWorkingTime(currentSite);
                    IdSite = e.value;
                    let resources = sch.option('resources');
                    resourceSlot = getResourceSlot(schedulerSlots, IdSite);
                    if (resourceSlot.length === 0) {
                      schedulerVisible = false;
                    } else {
                      schedulerVisible = true;
                      resources[1].dataSource = resourceSlot;
                      sch.option('resources', resources);
                      sch.repaint();
                      reloadAppointments();

                      // Setup variables if can simulate
                      isAllowedSimulation = currentSite.isAllowedSimulation;
                      locked = currentSite.isLocked;

                      manageToolbarButtons();
                    }
                    sch.option('visible', schedulerVisible);
                    sch.option('height', getSchedulerHeight());
                    sch.repaint();
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  width: 'auto',
                  label: dict.getTextForKey('WEB_SCHEDULER_STATUS'),
                  items: schedulerStatus,
                  valueExpr: 'code',
                  displayExpr: 'name',
                  value: mainStatus,
                  onValueChanged(e) {
                    mainStatus = e.value;
                    refreshScheduler();
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  // icon: 'search',
                  type: 'default',
                  text: !locked
                    ? dict.getTextForKey('WEB_SCHEDULER_START_SIMULATION')
                    : dict.getTextForKey('WEB_SCHEDULER_END_SIMULATION'),
                  disabled: !isAllowedSimulation,
                  onClick(e) {
                    if (locked) {
                      const result = DevExpress.ui.dialog.confirm(
                        dict.getTextForKey(
                          'WEB_SCHEDULER_END_SIMULTAION_MESSAGE'
                        ),
                        dict.getTextForKey('WEB_SCHEDULER_END_SIMULTAION_TITLE')
                      );
                      result.done(function (dialogResult) {
                        if (dialogResult) {
                          let newApp = [];
                          //app recursive
                          appointments
                            .filter(app => app.isModified === true)
                            .forEach((element, index) => {
                              if (element.recurrenceRule) {
                                let result = prepareRecursiveAppointment(
                                  e,
                                  element
                                );
                                if (result.found) {
                                  notifyDisableDate();
                                } else {
                                  result.newAppointments.forEach(el => {
                                    newApp.push(prepareAppointment(el));
                                  });
                                }
                              } else {
                                newApp.push(prepareAppointment(element));
                              }
                            });
                          createMultiAppointment(
                            {
                              appointments: newApp,
                            },
                            function () {
                              locked = false;
                              lockSite(
                                schedulerSites.find(x => x.id === IdSite),
                                { isLocked: locked },
                                function () {
                                  reloadAppointments();
                                  e.component.option(
                                    'text',
                                    dict.getTextForKey(
                                      'WEB_SCHEDULER_START_SIMULATION'
                                    )
                                  );
                                  simulate = false;
                                }
                              );
                            }
                          );
                        } else {
                          locked = false;
                          lockSite(
                            schedulerSites.find(x => x.id === IdSite),
                            { isLocked: locked },
                            function () {
                              reloadAppointments();
                              e.component.option(
                                'text',
                                dict.getTextForKey(
                                  'WEB_SCHEDULER_START_SIMULATION'
                                )
                              );
                              simulate = false;
                            }
                          );
                        }
                      });
                    } else {
                      locked = true;
                      lockSite(
                        schedulerSites.find(x => x.id === IdSite),
                        { isLocked: locked },
                        function () {
                          e.component.option(
                            'text',
                            dict.getTextForKey('WEB_SCHEDULER_END_SIMULATION')
                          );
                          simulate = true;
                        }
                      );
                    }
                  },
                },
              },
            ],
          })
          .dxToolbar('instance');

        // Setup scheduler
        const sch = $('#scheduler')
          .dxScheduler({
            height: getSchedulerHeight(),
            currentDate: new Date(),
            dataSource: appointments,
            firstDayOfWeek: 1,
            currentView: 'Timeline',
            cellDuration: 60,
            scrolling: {
              mode: 'virtual',
            },
            showAllDayPanel: false,
            horizontalScrollingEnabled: true,
            visible: schedulerVisible,
            closeOnOutsideClick: true,
            groups: ['idSlot'],
            views: [
              {
                type: 'timelineWeek',
                name: 'Timeline',
                groupOrientation: 'vertical',
              },
              {
                type: 'week',
                groupOrientation: 'vertical',
              },
              {
                type: 'month',
                groupOrientation: 'horizontal',
              },
            ],
            resources: [
              {
                fieldExpr: 'codeAppointmentStatus',
                valueExpr: 'id',
                dataSource: appStatus,
                useColorAsDefault: true,
              },
              {
                fieldExpr: 'idSlot',
                valueExpr: 'id',
                dataSource: resourceSlot,
              },
            ],
            // NOTE: Done in contentReady() method
            // startDayHour: 6,
            // endDayHour: 22,
            onContentReady(e) {
              // Hide non workign times on scheduler
              // NOTE: multiple configuration, different working times at same time, is not supported by scheduler component
              if (!currentSite && schedulerSites.length > 0) {
                currentSite = schedulerSites[0];
              }
              if (currentSite) {
                let tmp = [];
                currentSite.calendars.forEach(el => {
                  // NOTE: Check only DAY = 0 (Tutti giorni in site configuration)
                  if (el.day === 0) {
                    tmp.push(+el.fromTime.split(':')[0]);
                    tmp.push(+el.toTime.split(':')[0]);
                  }
                });
                let wt = tmp.sort((a, b) => a - b);
                e.component.option('startDayHour', wt[0]);
                e.component.option('endDayHour', wt[wt.length - 1]);
                // console.log(tmp);
              }
              // Workaround to fix DevExtreme scrolling bug
              // This code forces to reload scheduler again after it has loaded
              if (!schedulerDidLoad) {
                schedulerDidLoad = true;
                e.component.repaint();
              }
            },
            dataCellTemplate(itemData, itemIndex, itemElement) {
              if (!itemData.groups) return;

              const date = itemData.startDate;
              //TODO UNCOMMENT FOR WEEKEND
              //const isDisabled = isWeekend(date);
              const element = $('<div />');

              //TODO UNCOMMENT FOR WEEKEND
              // if (isDisabled) {
              //   element.addClass('disable-date');
              // } else
              if (
                !IsWorking(date) ||
                IsHiddenAppointment(date, itemData.groups?.idSlot) ||
                isHoliday(date)
              ) {
                element.addClass('dinner');
              }

              const isMonth = this.option('currentView') === 'month';
              if (isMonth) {
                element.text(date.getDate());
                element.addClass('dx-scheduler-date-table-cell-text');
              }

              return itemElement.append(element);
            },
            dateCellTemplate(itemData, itemIndex, itemElement) {
              const element = $(`<div>${itemData.text}</div>`);

              //TODO UNCOMMENT FOR WEEKEND
              // if (isWeekend(itemData.date)) {
              //   element.addClass('disable-date');
              // }

              return itemElement.append(element);
            },
            timeCellTemplate(itemData, itemIndex, itemElement) {
              const element = $(`<div>${itemData.date.getHours()}</div>`);
              const { date } = itemData;
              if (isHoliday(date)) element.addClass('disable-date');
              else if (!IsWorking(date) || isHoliday(date)) {
                //element.addClass('dinner');
              }
              return itemElement.append(element);
            },
            appointmentTooltipTemplate: function (e) {
              const app = e.appointmentData;
              return getTooltipTemplate(app);
            },
            onAppointmentFormOpening(e) {
              // Show custom tooltip
              if (e.component._appointmentTooltip._tooltip)
                e.component._appointmentTooltip._tooltip._zIndex = 0;

              e.component._appointmentPopup.popup.option('minWidth', '50%');
              e.component._appointmentPopup.popup.option('maxHeight', '90%');
              e.component._appointmentPopup.popup.option(
                'closeOnOutsideClick',
                true
              );

              // Tooltip popup customization
              e.component._appointmentPopup.popup.option(
                'toolbarItems[0].options',
                {
                  text: dict.getTextForKey('WEB_SCHEDULER_CONFIRM'),
                  type: 'success',
                  stylingMode: 'contained',
                }
              );

              e.component._appointmentPopup.popup.option('toolbarItems[1]', {
                location: 'before',
                widget: 'dxButton',
                options: {
                  stylingMode: 'contained',
                  text: dict.getTextForKey('WEB_SCHEDULER_DELETE'),
                  type: 'danger',
                  onClick(_) {
                    const result = DevExpress.ui.dialog.confirm(
                      dict.getTextForKey('WEB_SCHEDULER_DELETE_MESSAGE'),
                      dict.getTextForKey('WEB_SCHEDULER_DELETE_TITLE')
                    );
                    result.done(function (dialogResult) {
                      if (dialogResult) deleteAppointment(e);
                    });
                  },
                },
              });

              e.component._appointmentPopup.popup.option('toolbarItems[2]', {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'fas fa-close',
                  onClick(_) {
                    e.cancel = true;
                    closePopup(e);
                    reloadAppointments();
                  },
                },
              });

              const startDate = new Date(e.appointmentData.startDate);
              if (
                !isValidAppointmentDate(startDate, e.appointmentData.idSlot)
              ) {
                e.cancel = true;
                notifyDisableDate();
              }
              applyDisableDatesToDateEditors(e.form);
              let validationRuleCarrier = [];
              let carrierValue =
                e.appointmentData.idCarrier != null
                  ? e.appointmentData.idCarrier
                  : null;
              let carrierDisabled = false;
              let sbjDisabled = false;
              let statusDisabled = false;
              let subjects = schedulerSubjects;
              let subjectValue =
                e.appointmentData.idSubject != null
                  ? e.appointmentData.idSubject
                  : null;
              const form = e.form;

              // NOT USED!
              // NOTE: Localization insde REPEAT is not possibile, there is not public API to support this configuration
              // form.option('onFieldDataChanged', function (args) {
              //   if (args.dataField === 'repeat' && args.value) {
              //     let recurrenceRule = form.getEditor('recurrenceRule');
              //   }
              // });

              // NOT USED!
              // NOTE: Localization insde REPEAT is not possibile, there is not public API to support this configuration
              // form.option('onContentReady', function (args) {
              //   let recurrenceRule = form.getEditor('recurrenceRule');
              //   if (recurrenceRule) {
              //     let freqEd = recurrenceRule.getEditorByField('freq');
              //     // // let freqItems = freqEd.option('items');
              //     freqEd.option({
              //       value: 'hourly',
              //       // Not working
              //       // label: {
              //       //   text: dict.getTextForKey('WEB_USERS_PASSWORD'),
              //       // },
              //       // readOnly: true,
              //     });
              //   }
              // });

              // Create fields on main appointment popup
              const mainGroup = e.form.option('items')[0];
              if (mainGroup.items.find(z => z.dataField === 'text')) {
                mainGroup.items.splice(0, 1);
              }
              if (
                e.appointmentData.codeAppointmentStatus === 10 ||
                e.appointmentData.codeAppointmentStatus === 20 ||
                e.appointmentData.codeAppointmentStatus === 40 ||
                e.appointmentData.codeAppointmentStatus === 50
              ) {
                statusDisabled = true;
              }
              mainGroup.items[1].items?.forEach(element => {
                element.disabled = statusDisabled;
              });
              mainGroup.items[0].items?.forEach(element => {
                element.disabled = statusDisabled;
              });
              mainGroup.items.find(x => x.dataField === 'idSlot').disabled =
                statusDisabled;

              if (user.account.role === 'CARRIER') {
                //validationRuleSubject = [{ type: 'required' }];
                carrierValue = user.userCompany[0].idSubject;
                carrierDisabled = true;
              }
              //allday
              if (
                mainGroup.items[1].items.find(z => z.dataField === 'allDay')
              ) {
                mainGroup.items.find(
                  z => z.dataField === 'description'
                ).label.text = dict.getTextForKey('WEB_SCHEDULER_NOTE');
                mainGroup.items.find(
                  z => z.dataField === 'description'
                ).dataField = 'note';
                mainGroup.items[1].items.shift();
                mainGroup.items[1].items[0].label.text = dict.getTextForKey(
                  'WEB_SCHEDULER_REPEAT'
                );
                mainGroup.items[0].items.find(
                  z => z.dataField === 'startDate'
                ).label.text = dict.getTextForKey('WEB_SCHEDULER_STARTDATE');
                mainGroup.items[0].items.find(
                  z => z.dataField === 'endDate'
                ).label.text = dict.getTextForKey('WEB_SCHEDULER_ENDDATE');
              }

              // Hanlde Status
              currentStatus = 0;
              let visibleStatus = true;
              if (
                user.account.role === 'SUPADMIN' ||
                user.account.role === 'ADMIN' ||
                user.account.role === 'INTERNAL'
              ) {
                currentStatus =
                  e.appointmentData.codeAppointmentStatus != null
                    ? e.appointmentData.codeAppointmentStatus
                    : schedulerStatus.find(x => x.code === 10).code;
                visibleStatus = true;
              } else {
                currentStatus =
                  e.appointmentData.codeAppointmentStatus != null
                    ? e.appointmentData.codeAppointmentStatus
                    : schedulerStatus.find(x => x.code === 0).code;
                visibleStatus = false;
              }

              mainGroup.items.find(
                x => x.dataField === 'codeAppointmentStatus'
              ).editorOptions = {
                items: schedulerStatus.filter(x => x.code != 99),
                displayExpr: 'name',
                valueExpr: 'code',
                value: currentStatus,
                onValueChanged: function (e) {
                  let visible = false;
                  if (
                    +e.value === 50 &&
                    (user.account.role === 'SUPADMIN' ||
                      user.account.role === 'ADMIN' ||
                      user.account.role === 'INTERNAL')
                  )
                    visible = true;

                  form.option(
                    `items[0].items[${findIndex(form, 'resultGroup')}].visible`,
                    visible
                  );
                  currentStatus = +e.value;
                },
              };

              mainGroup.items.find(
                x => x.dataField === 'codeAppointmentStatus'
              ).label = {
                text: dict.getTextForKey('WEB_SCHEDULER_STATUS'),
              };

              mainGroup.items.find(
                x => x.dataField === 'codeAppointmentStatus'
              ).visible = visibleStatus;

              mainGroup.items.splice(7);

              // TODO: Remove later, NOT USED!!!
              // #########################################################################
              // Limitations: https://supportcenter.devexpress.com/ticket/details/t966884/scheduler-edit-customize-the-recurrencerule-editor
              // #########################################################################
              // let recurrenceRule = form.getEditor('recurrenceRule');

              // let freqEd = recurrenceRule.getEditorByField('freq');
              // let freqItems = freqEd.option('items');
              // freqEd.option({
              //   value: 'weekly',
              //   readOnly: true,
              // });

              // let repeatEndEd = recurrenceRule.getEditorByField('repeatEnd');
              // let repeatEndItems = repeatEndEd.option('items');
              // repeatEndItems = repeatEndItems.filter(i => i.type !== 'never');
              // repeatEndEd.option('items', repeatEndItems);

              // let untilDateBox = $(
              //   '.dx-recurrence-datebox-until-date'
              // ).dxDateBox('instance');
              // untilDateBox.option('max', new Date());
              // #########################################################################
              // Limitations: https://supportcenter.devexpress.com/ticket/details/t966884/scheduler-edit-customize-the-recurrencerule-editor
              // #########################################################################

              if (
                user.account.role === 'CUSTOMER' ||
                user.account.role === 'SUPPLIER'
              ) {
                subjects = schedulerSubjects.filter(
                  a => a.subjectTypesRole === user.account.role
                );
                subjectValue = user.userCompany[0].idSubject;
                sbjDisabled = true;
              }

              if (
                user.account.role === 'SUPADMIN' ||
                user.account.role === 'ADMIN' ||
                user.account.role === 'INTERNAL' ||
                user.account.role === 'CARRIER'
              ) {
                const roleSelected =
                  e.appointmentData.idSubject != null
                    ? schedulerSubjects.find(
                        sc => sc.id === e.appointmentData.idSubject
                      )?.subjectTypesRole
                    : null;
                // if (!e.form.getEditor(`role`)) {
                mainGroup.items.push({
                  dataField: 'role',
                  label: {
                    text: dict.getTextForKey('WEB_SCHEDULER_ROLE'),
                  },
                  editorType: 'dxSelectBox',
                  colSpan: 2,
                  editorOptions: {
                    items: roles.filter(
                      x => x.code === 'CUSTOMER' || x.code === 'SUPPLIER'
                    ),
                    displayExpr: 'name',
                    valueExpr: 'code',
                    value: roleSelected,
                    disabled: statusDisabled,
                    onValueChanged: function (e) {
                      // Get second editor
                      const secondEditor = form.getEditor(`idSubject`);
                      // Clear old value
                      secondEditor.option('value', null);
                      // Filter subjects for the current idCompany, isEnabled = true
                      secondEditor
                        .getDataSource()
                        .filter(
                          ['subjectTypesRole', '=', e.value],
                          ['isEnabled', '=', true]
                        );
                      secondEditor.getDataSource().reload();
                    },
                  },
                });
              }

              // Handle Subject
              // if (!e.form.getEditor(`idSubject`)) {
              mainGroup.items.push({
                dataField: 'idSubject',
                label: {
                  text: dict.getTextForKey('WEB_SCHEDULER_SUBJECT'),
                },
                editorType: 'dxSelectBox',
                colSpan: 2,
                editorOptions: {
                  items: subjects,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  value: subjectValue,
                  disabled:
                    statusDisabled == false ? sbjDisabled : statusDisabled,
                },
                validationRules: [{ type: 'required' }],
              });

              // Handle Operation type
              let opDisabled = true;
              let opTypes = schedulerOperationTypes;
              let opValue =
                e.appointmentData.operationType != null
                  ? e.appointmentData.operationType
                  : schedulerSlots.find(
                      el => el.id === e.appointmentData.idSlot
                    ).type;

              // Check ig value equals to Carico/Scarico
              if (
                opValue ===
                  OPERATIONTYPE.find(op => op.name === 'LOADING/UNLOADING')
                    .code ||
                e.appointmentData.codeAppointmentStatus === 0
              ) {
                opDisabled = false;
                opTypes = schedulerOperationTypes.filter(s => s.code !== 2);
                //opValue = null;
              }
              // if (!e.form.getEditor(`operationType`)) {
              mainGroup.items.push({
                dataField: 'operationType',
                editorType: 'dxSelectBox',
                label: {
                  text: dict.getTextForKey('WEB_SCHEDULER_OPERATIONTYPE'),
                },
                editorOptions: {
                  items: opTypes,
                  displayExpr: 'name',
                  valueExpr: 'code',
                  disabled:
                    statusDisabled == false ? opDisabled : statusDisabled,
                  value: opValue,
                },
              });

              // Handle Vehicle type
              mainGroup.items.push({
                dataField: 'idVehicleType',
                label: {
                  text: dict.getTextForKey('WEB_SCHEDULER_VEHICLETYPE'),
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                  items: schedulerSlots
                    .find(s => s.id === e.appointmentData.idSlot)
                    .slotVehicleTypes.filter(el => el.isEnabled === true),
                  displayExpr: 'name',
                  valueExpr: 'idSlotVehicleType',
                  disabled: statusDisabled,
                },
              });

              // Handle Product type
              mainGroup.items.push({
                label: {
                  text: dict.getTextForKey('WEB_SCHEDULER_PRODUCTTYPE'),
                },
                editorType: 'dxDropDownBox',
                editorOptions: {
                  dataSource: schedulerSlots.find(
                    s => s.id === e.appointmentData.idSlot
                  ).slotProductTypes,
                  disabled: statusDisabled,
                  valueExpr: 'idSlotProductType',
                  displayExpr: 'name',
                  value: e.appointmentData.productsType
                    ? e.appointmentData.productsType.map(function (i) {
                        return i.idProductType;
                      })
                    : null,
                  contentTemplate: function (e) {
                    const $treeView = $('<div>').dxTreeView({
                      dataSource: e.component.getDataSource(),
                      keyExpr: 'idSlotProductType',
                      displayExpr: 'name',
                      selectionMode: 'multiple',
                      selectByClick: true,
                      onItemSelectionChanged(args) {
                        const selectedKeys =
                          args.component.getSelectedNodeKeys();
                        e.component.option('value', selectedKeys);
                        let data = form.option('formData');
                        data.productType = [];
                        selectedKeys.forEach(function (element) {
                          data.productType.push({
                            IdProductType: element,
                            Quantity: 0,
                          });
                        });
                      },
                    });
                    treeView = $treeView.dxTreeView('instance');
                    return $treeView;
                  },
                },
              });

              // // Slot type
              // mainGroup.items.push({
              //   dataField: 'idSlotType',
              //   label: {
              //     text: dict.getTextForKey('WEB_SCHEDULER_SLOTTYPE'),
              //   },
              //   editorType: 'dxSelectBox',
              //   editorOptions: {
              //     items: schedulerSlots.find(
              //       s => s.id === e.appointmentData.idSlot
              //     ).slotTypes,
              //     displayExpr: 'name',
              //     valueExpr: 'idSlotType',
              //     disabled: statusDisabled,
              //   },
              // });

              // Handle Slot line
              mainGroup.items.push({
                dataField: 'idLine',
                label: {
                  text: dict.getTextForKey('WEB_SCHEDULER_LINE'),
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                  items: schedulerSlots
                    .find(s => s.id === e.appointmentData.idSlot)
                    .slotLines.filter(el => el.isEnabled === true),
                  displayExpr: 'name',
                  valueExpr: 'idSlotLine',
                  disabled: statusDisabled,
                },
              });

              // Handle Carrier
              mainGroup.items.push({
                dataField: 'idCarrier',
                editorType: 'dxSelectBox',
                label: {
                  text: dict.getTextForKey('WEB_SCHEDULER_CARRIER'),
                },
                editorOptions: {
                  items: schedulerSubjects.filter(
                    el =>
                      el.subjectTypesRole === 'CARRIER' && el.isEnabled === true
                  ),
                  displayExpr: 'name',
                  valueExpr: 'id',
                  value: carrierValue,
                  disabled:
                    statusDisabled == false ? carrierDisabled : statusDisabled,
                },
                validationRules: validationRuleCarrier,
              });

              // Handle name carrier
              mainGroup.items.push({
                dataField: 'driverName',
                label: {
                  text: dict.getTextForKey('WEB_SCHEDULER_DRIVERNAME'),
                },
                editorOptions: {
                  maxLength: '50',
                },
                validationRules: [
                  {
                    type: 'required',
                  },
                ],
              });

              mainGroup.items.push({
                itemType: 'group',
                colCount: 3,
                colSpan: 2,
                horizontalAlignment: 'left',
                verticalAlignment: 'center',
                items: [
                  {
                    dataField: 'plateNumber',
                    label: {
                      text: dict.getTextForKey('WEB_SCHEDULER_PLATENUMBER'),
                    },
                    editorOptions: {
                      maxLength: '20',
                    },
                    validationRules: [
                      {
                        type: 'required',
                      },
                    ],
                  },
                  {
                    dataField: 'towingPlateNumber',
                    label: {
                      text: dict.getTextForKey(
                        'WEB_SCHEDULER_TOWINGPLATENUMBER'
                      ),
                    },
                    editorOptions: {
                      maxLength: '20',
                    },
                    validationRules: [
                      {
                        type: 'required',
                      },
                    ],
                  },
                  {
                    dataField: 'quantity',
                    editorType: 'dxNumberBox',
                    label: {
                      text: dict.getTextForKey('WEB_SCHEDULER_QUANTITY'),
                    },
                    editorOptions: {
                      min: 0,
                    },
                  },
                ],
              });
              //   dataField: 'plateNumber',
              //   label: {
              //     text: dict.getTextForKey('WEB_SCHEDULER_PLATENUMBER'),
              //   },
              //   colSpan: 0,
              //   validationRules: [
              //     {
              //       type: 'required',
              //     },
              //   ],
              // });

              // // Towing plate number
              // mainGroup.items.push({
              //   dataField: 'towingPlateNumber',
              //   label: {
              //     text: dict.getTextForKey('WEB_SCHEDULER_TOWINGPLATENUMBER'),
              //   },
              //   colSpan: 0,
              //   validationRules: [
              //     {
              //       type: 'required',
              //     },
              //   ],
              // });

              // // Quantity
              // mainGroup.items.push({
              //   dataField: 'quantity',
              //   editorType: 'dxNumberBox',
              //   colSpan: 0,
              //   label: {
              //     text: dict.getTextForKey('WEB_SCHEDULER_QUANTITY'),
              //   },
              //   editorOptions: {
              //     min: 0,
              //   }
              // });

              // GropuResult: result, resultNote
              mainGroup.items.push({
                itemType: 'group',
                name: 'resultGroup',
                visible:
                  e.appointmentData.codeAppointmentStatus === 50 &&
                  (user.account.role === 'SUPADMIN' ||
                    user.account.role === 'ADMIN' ||
                    user.account.role === 'INTERNAL'),
                colSpan: 2,
                items: [
                  {
                    dataField: 'result',
                    editorType: 'dxSelectBox',
                    colSpan: 2,
                    editorOptions: {
                      label: {
                        text: dict.getTextForKey('WEB_SCHEDULER_RESULT'),
                      },
                      items: results,
                      displayExpr: 'name',
                      valueExpr: 'code',
                    },
                  },
                  {
                    dataField: 'resultNote',
                    editorType: 'dxTextArea',
                    colSpan: 2,
                    editorOptions: {
                      height: 100,
                    },
                    label: {
                      text: dict.getTextForKey('WEB_SCHEDULER_RESULTNOTE'),
                    },
                  },
                ],
              });

              // Handle Attachments
              mainGroup.items.push(
                {
                  itemType: 'group',
                  colCount: 1,
                  colSpan: 2,
                  horizontalAlignment: 'left',
                  verticalAlignment: 'center',
                  name: 'attachments-group',
                  caption: dict.getTextForKey('WEB_SCHEDULER_ATTACHMENT'),
                  items: [
                    {
                      template: function (data, itemElement) {
                        itemElement.append(
                          $('<div>')
                            .attr('id', `addAttachment`)
                            .dxFileUploader({
                              selectButtonText: dict.getTextForKey(
                                'WEB_SCHEDULER_ADDATTACHMENT'
                              ),
                              labelText: '',
                              allowedFileExtensions: [
                                '.jpg',
                                '.jpeg',
                                '.gif',
                                '.png',
                                '.pdf',
                              ],
                              uploadFile(file, progress) {
                                let reader = new FileReader();
                                const fileName = file.name.replaceAll(' ', '_');
                                const fileType = file.type;
                                reader.onloadend = function () {
                                  arrayAttachments.push({
                                    name: fileName,
                                    blob: reader.result.replace(
                                      'data:' + fileType + ';base64,',
                                      ''
                                    ),
                                  });
                                  e.form
                                    .option('items')[0]
                                    .items.find(
                                      x => x.name === 'attachments-container'
                                    ).items = getAttachmentOptions(
                                    e.appointmentData,
                                    e.form
                                  );
                                  e.form.repaint();
                                };
                                reader.readAsDataURL(file);
                              },
                            })
                        );
                      },
                      name: `addAttachment`,
                      horizontalAlignment: 'right',
                      label: {
                        text: ' ',
                      },
                      colSpan: 1,
                    },
                  ],
                },
                {
                  itemType: 'group',
                  colCount: 1,
                  colSpan: 2,
                  name: 'attachments-container',
                  items: getAttachmentOptions(e.appointmentData, e.form),
                }
              );

              // Handle Report PDF
              mainGroup.items.push({
                itemType: 'group',
                colCount: 1,
                colSpan: 2,
                visible: e.appointmentData.id > 0,
                horizontalAlignment: 'left',
                verticalAlignment: 'center',
                name: 'attachments-group',
                caption: dict.getTextForKey('WEB_SCHEDULER_REPORT'),
                items: [
                  {
                    itemType: 'button',
                    verticalAlignment: 'bottom',
                    colSpan: 1,
                    buttonOptions: {
                      //width: '100%',
                      text: dict.getTextForKey('WEB_SCHEDULER_SHOWREPORT'),
                      onClick() {
                        getAppointmentPDF(e.appointmentData, function (result) {
                          let file = {
                            blob: result.data.blob,
                            name: result.data.fileUrl,
                            lastModifiedDate: new Date(),
                          };
                          const blob = b64toBlob(
                            file.blob,
                            ATTACHMENTBASE64.find(
                              x => x.code === file.name.split('.')[1]
                            ).string
                          );
                          const blobUrl = URL.createObjectURL(blob);

                          window.open(blobUrl, '_blank');
                        });
                      },
                    },
                  },
                ],
              });
              e.form.repaint();
            },
            onEditorPreparing(e) {},
            onAppointmentAdding(e) {
              if (!isValidAppointment(e.component, e.appointmentData)) {
                e.cancel = true;
                notifyDisableDate();
              } else {
                // Workaround for devextreme bug on status caching
                e.appointmentData.codeAppointmentStatus = currentStatus;
                if (simulate) {
                  e.appointmentData.isModified = true;
                } else {
                  // Check if appointment containts recurring rule
                  if (
                    e.appointmentData.recurrenceRule &&
                    !e.appointmentData.recurrenceRule.includes('UNTIL') &&
                    !e.appointmentData.recurrenceRule.includes('COUNT')
                  ) {
                    e.cancel = true;
                    notifyDisableDate(
                      dict.getTextForKey('WEB_SCHEDULER_ENDDATE_MISSING_NOTIFY')
                    );
                  } else if (e.appointmentData.recurrenceRule) {
                    let result = prepareRecursiveAppointment(
                      e,
                      e.appointmentData
                    );
                    e = result.e;
                    if (result.found) {
                      notifyDisableDate();
                    } else {
                      createMultiAppointment(
                        {
                          appointments: result.newAppointments,
                        },
                        function () {
                          reloadAppointments();
                        }
                      );
                    }
                  } else {
                    create(prepareAppointment(e.appointmentData), function () {
                      reloadAppointments();
                    });
                  }
                }
              }
            },
            onAppointmentAdded(e) {
              // const itemIndex = appointments.findIndex(
              //   app =>
              //     app.startDate === e.appointmentData.startDate &&
              //     app.endDate === e.appointmentData.endDate &&
              //     e.appointmentData.idSlot === app.idSlot &&
              //     e.appointmentData.text === app.text
              // );
              // appointments[itemIndex].arrivalTime = e.appointmentData.startDate;
              // appointments[itemIndex].finishTime = e.appointmentData.endDate;
            },
            onAppointmentUpdating(e) {
              if (
                !isValidAppointment(e.component, e.newData) ||
                (e.newData.codeAppointmentStatus ===
                  e.oldData.codeAppointmentStatus &&
                  e.oldData.codeAppointmentStatus === 10 &&
                  (e.newData.endDate.toTimeString() !==
                    e.oldData.endDate.toTimeString() ||
                    e.newData.startDate.toTimeString() !==
                      e.oldData.startDate.toTimeString()))
              ) {
                e.cancel = true;
                notifyDisableDate(
                  !isValidAppointment(e.component, e.newData)
                    ? dict.getTextForKey('WEB_SCHEDULER_DISABLEDATE')
                    : dict.getTextForKey('WEB_SCHEDULER_IMPOSSIBILE_TO_MOVE')
                );
              } else {
                if (simulate) {
                  e.newData.isModified = true;
                } else {
                  if (
                    e.newData.codeAppointmentStatus ===
                      e.oldData.codeAppointmentStatus &&
                    e.oldData.codeAppointmentStatus === 30
                  )
                    e.newData.codeAppointmentStatus = 0;
                  // Check if appointment containts recurring rule
                  if (
                    e.newData.recurrenceRule &&
                    !e.newData.recurrenceRule.includes('UNTIL') &&
                    !e.newData.recurrenceRule.includes('COUNT')
                  ) {
                    e.cancel = true;
                    notifyDisableDate(
                      dict.getTextForKey('WEB_SCHEDULER_ENDDATE_MISSING_NOTIFY')
                    );
                  } else if (e.newData.recurrenceRule) {
                    let result = prepareRecursiveAppointment(e, e.newData);
                    e = result.e;

                    if (result.found) {
                      notifyDisableDate();
                    } else {
                      createMultiAppointment(
                        {
                          appointments: result.newAppointments,
                        },
                        function () {
                          reloadAppointments();
                        }
                      );
                    }
                  } else {
                    modify(prepareAppointment(e.newData), function () {
                      reloadAppointments();
                    });
                  }
                }
              }
            },
            onAppointmentUpdated(e) {
              // const itemIndex = appointments.findIndex(
              //   app =>
              //     app.startDate === e.appointmentData.startDate &&
              //     app.endDate === e.appointmentData.endDate &&
              //     e.appointmentData.idSlot === app.idSlot &&
              //     e.appointmentData.text === app.text
              // );
              // appointments[itemIndex].arrivalTime = e.appointmentData.startDate;
              // appointments[itemIndex].finishTime = e.appointmentData.endDate;
            },
            onAppointmentDeleting(e) {
              const app = e.appointmentData;
              if (
                (user.account.role === 'SUPADMIN' ||
                  user.account.role === 'ADMIN' ||
                  user.account.role === 'INTERNAL' ||
                  app.codeAppointmentStatus === 0) &&
                !simulate
              ) {
                handlerDelete(app);
              } else {
                e.cancel = true;
                notifyDeleteNotValid();
              }
            },
            onAppointmentDeleted(e) {
              // const itemIndex = appointments.findIndex(app => app.startDate === e.appointmentData.startDate && app.endDate === e.appointmentData.endDate && e.appointmentData.idSlot === app.idSlot && e.appointmentData.text === app.text);
              // appointments[itemIndex].arrivalTime = e.appointmentData.startDate;
              // appointments[itemIndex].finishTime = e.appointmentData.endDate;
            },
          })
          .dxScheduler('instance');

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        // Calculate scheduler height on window resize
        window.addEventListener('resize', function () {
          sch.option('height', getSchedulerHeight());
          sch.repaint();
        });

        function getContainerTop() {
          return $('.container').offset().top;
        }

        function getToolbarHeight() {
          return $('#scheduler-toolbar')[0].clientHeight;
        }

        function getToolbarItemsContainerHeight() {
          return $('.dx-toolbar-items-container')[0].clientHeight;
        }

        function getSchedulerHeight() {
          const cellHeight = 34;
          const slotsCount = getSlots(currentSite).length;
          // console.log(`SIZE COUNT ${slotsCount}`);
          const height =
            cellHeight * slotsCount + getToolbarHeight() + getContainerTop();
          const maxHeight =
            $(window).height() -
            getContainerTop() -
            getToolbarHeight() -
            getToolbarItemsContainerHeight();

          const schedulerHeight = height > maxHeight ? maxHeight : height;

          // console.log($(window).height());
          // console.log(getContainerTop());
          // console.log(getToolbarHeight());

          return schedulerHeight.toString();
        }

        function getSlots(site) {
          return schedulerSlots.filter(el => el.idSite === site.id);
        }

        function refreshScheduler() {
          if (mainStatus === 99) {
            sch.option('dataSource', appointments);
          } else {
            sch.option(
              'dataSource',
              appointments.filter(a => a.codeAppointmentStatus === mainStatus)
            );
          }
          sch.getDataSource().reload();
        }

        function findIndex(form, id) {
          return form.option('items')[0].items.findIndex(x => x.name === id);
        }

        // Prepare objects as server structure
        function prepareAppointment(app) {
          // Check if recurrence exists and not defined UNTIL then return error
          // if (app.recurrenceRule && !app.recurrenceRule.includes('UNTIL'))
          //   return null;

          const sD = app.startDate;
          const eD = app.endDate;
          app.IdSite = IdSite;
          app.arrivalTime = `${('0' + (sD.getMonth() + 1)).slice(-2)}/${(
            '0' + sD.getDate()
          ).slice(-2)}/${sD.getFullYear()} ${sD.toTimeString().split(' ')[0]}`;
          app.finishTime = `${('0' + (eD.getMonth() + 1)).slice(-2)}/${(
            '0' + eD.getDate()
          ).slice(-2)}/${eD.getFullYear()} ${eD.toTimeString().split(' ')[0]}`;
          app.year = sD.getFullYear();
          // if status != concluso remove object result and resultNote
          if (app.codeAppointmentStatus !== 50) {
            app.result = null;
            app.resultNote = '';
          }
          app.attachments = arrayAttachments;
          if (app.productType === undefined) {
            app.productType = [];
            app.productsType?.map(function (i) {
              app.productType.push({
                IdProductType: i.idProductType,
                Quantity: 0,
              });
            });
          }
          // Remove recurrenceRule from appointment
          app.recurrenceRule = null;
          return app;
        }

        function prepareRecursiveAppointment(e, appointmentData) {
          // Make iCalendar ISO Date String to assign to DTSTART
          const rruleStartDate =
            appointmentData.startDate
              .toISOString()
              .replaceAll('-', '')
              .replaceAll(':', '')
              .replaceAll('.', '')
              .slice(0, 15) + 'Z';

          // Compose correct rrule string according do iCalendar standard
          appointmentData.recurrenceRule = `DTSTART:${rruleStartDate}\nRRULE:${appointmentData.recurrenceRule}`;
          const ruleObj = rrule.rrulestr(appointmentData.recurrenceRule);
          // Remove recurrenceRule from appointment
          appointmentData.recurrenceRule = null;
          // Get array of dates
          const dates = ruleObj.all();
          // Add timeStamp to app for recurrences in milliseconds
          let timeStamp = new Date().getTime();
          // From reccurence rule objects creates new array of appointments
          let newAppointments = [];
          let found = false;
          for (let i = 0; i < dates.length; i++) {
            let newApp = cloneDeep(appointmentData);
            newApp.startDate = dates[i];
            const diff =
              appointmentData.endDate.getTime() -
              appointmentData.startDate.getTime();
            newApp.endDate = new Date(dates[i].getTime() + diff);
            newApp.recurrenceRule = null;
            // Add timeStamp to app for recurrences
            newApp.RecTimestamp = timeStamp.toString();

            if (i != 0) newApp.id = null;

            if (!newApp.id && !isValidAppointment(e.component, newApp)) {
              e.cancel = true;
              found = true;
              break;
            } else {
              newAppointments.push(prepareAppointment(newApp));
            }
          }
          let res = {
            e: e,
            found: found,
            newAppointments: newAppointments,
          };
          return res;
        }

        function notifyDisableDate(
          msg = dict.getTextForKey('WEB_SCHEDULER_NOTVALID')
        ) {
          DevExpress.ui.notify(
            {
              message: msg,
              position: {
                my: 'center top',
                at: 'center top',
              },
            },
            'warning',
            3000
          );
        }

        function notifyDeleteNotValid() {
          const msg = dict.getTextForKey('WEB_SCHEDULER_DELETEDNOTVALID');
          DevExpress.ui.notify(
            {
              message: msg,
              position: {
                my: 'center top',
                at: 'center top',
              },
            },
            'warning',
            3000
          );
        }

        function isValidAppointment(component, appointmentData) {
          const startDate = new Date(appointmentData.startDate);
          const endDate = new Date(appointmentData.endDate);
          const cellDuration = component.option('cellDuration');
          const appointmentOfDay = appointments.filter(
            app =>
              new Date(app.arrivalTime).getDate() === startDate.getDate() &&
              new Date(app.arrivalTime).getMonth() === startDate.getMonth() &&
              appointmentData.idSlot === app.idSlot &&
              appointmentData.id != app.id
          );
          for (let i = 0; i < appointmentOfDay.length; i++) {
            appointmentOfDay[i].arrivalTime = new Date(
              appointmentOfDay[i].arrivalTime
            );
            appointmentOfDay[i].finishTime = new Date(
              appointmentOfDay[i].finishTime
            );
            if (
              startDate < appointmentOfDay[i].finishTime &&
              appointmentOfDay[i].arrivalTime < endDate
            ) {
              return false;
            }
          }
          return isValidAppointmentInterval(
            startDate,
            endDate,
            cellDuration,
            appointmentData.idSlot
          );
        }

        function isValidAppointmentInterval(
          startDate,
          endDate,
          cellDuration,
          slot
        ) {
          const edgeEndDate = new Date(endDate?.getTime() - 1);

          if (!isValidAppointmentDate(edgeEndDate, slot)) {
            return false;
          }

          const durationInMs = cellDuration * 60 * 1000;
          const date = startDate;
          while (date <= endDate) {
            if (!isValidAppointmentDate(date, slot)) {
              return false;
            }
            const newDateTime = date?.getTime() + durationInMs - 1;
            date.setTime(newDateTime);
          }

          return true;
        }

        function isValidAppointmentDate(date, slot) {
          return (
            !isHoliday(date) &&
            IsWorking(date) &&
            //TODO UNCOMMENT FOR WEEKEND
            // !isWeekend(date) &&
            !IsHiddenAppointment(date, slot)
          );
        }

        function isHoliday(dt) {
          const date = dt.toLocaleDateString();
          const filterDay = holidays.find(hap => hap.date === date);
          if (!filterDay) return false;
          return (
            dt.toTimeString().split(' ')[0] >= filterDay.fromTime &&
            dt.toTimeString().split(' ')[0] < filterDay.toTime
          );
        }
        //TODO UNCOMMENT FOR WEEKEND
        // function isWeekend(date) {
        //   const day = date.getDay();
        //   return day === 0 || day === 6;
        // }

        function IsWorking(dt) {
          const hours = dt.getHours();
          const day = dt.getDay() === 0 ? 7 : dt.getDay();
          const filterDay = workingTimes.find(wt => wt.day === day)
            ? workingTimes.filter(wt => wt.day === day)
            : workingTimes.filter(wt => wt.day === 0);
          if (!filterDay) return true;
          let match = false;
          filterDay.forEach(element => {
            if (
              dt.toTimeString().split(' ')[0] >= element.fromTime &&
              dt.toTimeString().split(' ')[0] < element.toTime
            )
              match = true;
          });
          return match;
        }

        function IsHiddenAppointment(dt, slot) {
          const date = dt.toLocaleDateString();
          const filterDay = hiddenAppointments.filter(
            hap => hap.date === date && hap.idSlot === slot
          );
          if (!filterDay) return false;
          let match = false;
          filterDay.forEach(element => {
            if (
              dt.toTimeString().split(' ')[0] >= element.fromTime &&
              dt.toTimeString().split(' ')[0] < element.toTime
            )
              match = true;
          });
          return match;
        }

        function applyDisableDatesToDateEditors(form) {
          const startDateEditor = form.getEditor('startDate');
          startDateEditor.option('disabledDates', holidays);
          const endDateEditor = form.getEditor('endDate');
          endDateEditor.option('disabledDates', holidays);
        }

        function getAttachmentOptions(app, form) {
          const options = [];
          if (app.attachments?.length === 0 || !app.attachments) {
            app.attachments = [];
          }
          arrayAttachments = app.attachments;
          app.attachments.forEach((_, i) => {
            options.push(generateNewAttachmentOption(app, i, form));
          });
          return options;
        }

        function generateNewAttachmentOption(app, index, form) {
          let option = {};

          //   option = {
          //     itemType: 'group',
          //     colCount: 6,
          //     items: [
          //       {
          //         template: function (data, itemElement) {
          //           itemElement.append(
          //             $('<div>')
          //               .attr('id', `attachments[${index}].id`)
          //               .dxFileUploader({
          //                 selectButtonText: dict.getTextForKey(
          //                   'WEB_SCHEDULER_ADDATTACHMENT'
          //                 ),
          //                 labelText: '',
          //                 width: '200px',
          //                 allowedFileExtensions: [
          //                   '.jpg',
          //                   '.jpeg',
          //                   '.gif',
          //                   '.png',
          //                   '.pdf',
          //                 ],
          //                 uploadFile(file, progress) {
          //                   let reader = new FileReader();
          //                   const fileName = file.name.replaceAll(' ', '_');
          //                   const fileType = file.type;
          //                   reader.onloadend = function () {
          //                     arrayAttachments[index] = {
          //                       name: fileName,
          //                       blob: reader.result.replace(
          //                         'data:' + fileType + ';base64,',
          //                         ''
          //                       ),
          //                     };
          //                   };
          //                   reader.readAsDataURL(file);
          //                 },
          //                 onInitialized: function (e) {
          //                   e.component.focus();
          //                 },
          //               })
          //           );
          //         },
          //         name: `attachments[${index}].id`,
          //         label: {
          //           text: ' ',
          //         },
          //         verticalAlignment: 'bottom',
          //         colSpan: 1,
          //         //dataField: `attachments[${index}].id`,
          //         //editorType: 'dxFileUploader',
          //         //editorOptions:
          //       },
          //       {
          //         itemType: 'button',
          //         verticalAlignment: 'center',
          //         horizontalAlignment: 'center',
          //         colSpan: 1,
          //         buttonOptions: {
          //           icon: 'trash',
          //           onClick() {
          //             arrayAttachments.splice(index, 1);
          //             form
          //               .option('items')[0]
          //               .items.find(
          //                 x => x.name === 'attachments-container'
          //               ).items = getAttachmentOptions(app, form);
          //             form.repaint();
          //             form
          //               .getEditor(
          //                 `attachments[${app.attachments?.length - 1}].id`
          //               )
          //               ?.focus();
          //           },
          //         },
          //       },
          //     ],
          //   };
          option = {
            itemType: 'group',
            colCount: 4,
            items: [
              {
                itemType: 'button',
                verticalAlignment: 'bottom',
                colSpan: 1,
                buttonOptions: {
                  width: '100%',
                  text: app.attachments[index].name,
                  onClick() {
                    //dict.getTextForKey(
                    //   'WEB_SCHEDULER_SHOWATTACHMENT'
                    // ) + ' ' + app.attachments[index].name,
                    if (!app.attachments[index].fileUrl) {
                      let file = {
                        blob: app.attachments[index].blob,
                        name: app.attachments[index].name,
                        lastModifiedDate: new Date(),
                      };
                      const blob = b64toBlob(
                        file.blob,
                        ATTACHMENTBASE64.find(
                          x => x.code === file.name.split('.')[1]
                        ).string
                      );
                      const blobUrl = URL.createObjectURL(blob);

                      window.open(blobUrl, '_blank');
                    } else {
                      getAttachment(
                        app.attachments[index].fileUrl.replace('/', ''),
                        function (result) {
                          let file = {
                            blob: result.data.blob,
                            name: result.data.name,
                            lastModifiedDate: new Date(),
                          };
                          const blob = b64toBlob(
                            file.blob,
                            ATTACHMENTBASE64.find(
                              x => x.code === file.name.split('.')[1]
                            ).string
                          );
                          const blobUrl = URL.createObjectURL(blob);

                          window.open(blobUrl, '_blank');
                        }
                      );
                    }
                  },
                  onInitialized: function (e) {
                    e.component.focus();
                  },
                },
              },
              {
                itemType: 'button',
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                colSpan: 1,
                buttonOptions: {
                  icon: 'trash',
                  onClick() {
                    arrayAttachments.splice(index, 1);
                    form
                      .option('items')[0]
                      .items.find(
                        x => x.name === 'attachments-container'
                      ).items = getAttachmentOptions(app, form);
                    form.repaint();
                    form
                      .getEditor(
                        `attachments[${app.attachments?.length - 1}].id`
                      )
                      ?.focus();
                  },
                },
              },
            ],
          };
          return option;
          // return {
          //   itemType: 'group',
          //   colCount: 6,
          //   items: [
          //     {
          //       dataField: `attachments[${index}].id`,
          //       editorType: 'dxFileUploader',
          //       verticalAlignment: 'bottom',
          //       colSpan: 1,
          //       visible: app.attachments[index].id === null,
          //       label: {
          //         text: ' ',
          //       },
          //       editorOptions: {
          //         selectButtonText: dict.getTextForKey(
          //           'WEB_SCHEDULER_ADDATTACHMENT'
          //         ),
          //         //uploadMode: 'useForm',
          //         labelText: '',
          //         width: '200px',
          //         allowedFileExtensions: [
          //           '.jpg',
          //           '.jpeg',
          //           '.gif',
          //           '.png',
          //           '.pdf',
          //         ],
          //         uploadFile(file, progress) {
          //           let reader = new FileReader();
          //           const fileName = file.name.replaceAll(' ', '_');
          //           const fileType = file.type;
          //           reader.onloadend = function () {
          //             app.attachments[index] = {
          //               name: fileName,
          //               blob: reader.result.replace(
          //                 'data:' + fileType + ';base64,',
          //                 ''
          //               ),
          //             };
          //           };
          //           reader.readAsDataURL(file);
          //         },
          //       },
          //     },
          //     {
          //       itemType: 'button',
          //       verticalAlignment: 'bottom',
          //       colSpan: 1,
          //       visible: app.attachments[index].id !== null,
          //       buttonOptions: {
          //         text: app.attachments[index].name,
          //         //dict.getTextForKey(
          //         //   'WEB_SCHEDULER_SHOWATTACHMENT'
          //         // ) + ' ' + app.attachments[index].name,
          //         onClick() {
          //           getAttachment(
          //             app.attachments[index].fileUrl.replace('/', ''),
          //             function (result) {
          //               let file = {
          //                 blob: result.data.blob,
          //                 name: result.data.name,
          //                 lastModifiedDate: new Date(),
          //               };
          //               const blob = b64toBlob(
          //                 file.blob,
          //                 ATTACHMENTBASE64.find(
          //                   x => x.code === file.name.split('.')[1]
          //                 ).string
          //               );
          //               const blobUrl = URL.createObjectURL(blob);

          //               window.open(blobUrl, '_blank');
          //             }
          //           );
          //         },
          //       },
          //     },
          //     {
          //       itemType: 'button',
          //       verticalAlignment: 'center',
          //       horizontalAlignment: 'center',
          //       colSpan: 1,
          //       buttonOptions: {
          //         icon: 'trash',
          //         onClick() {
          //           app.attachments.splice(index, 1);
          //           form
          //             .option('items')[0]
          //             .items.find(
          //               x => x.name === 'attachments-container'
          //             ).items = getAttachmentOptions(app, form);
          //           form.repaint();
          //           form
          //             .getEditor(`attachments[${app.attachments?.length - 1}].id`)
          //             ?.focus();
          //         },
          //       },
          //     },
          //   ],
          // };
        }

        // function createAttachmentOptions(app, form) {
        //   const options = [];
        //   // Push into array the new element
        //   app.attachments.push({
        //     id: null,
        //     name: '',
        //     blob: '',
        //   });

        //   app.attachments.forEach((_, i) => {
        //     options.push(generateNewAttachmentOption(app, i, form));
        //   });

        //   arrayAttachments = app.attachments;
        //   // options.push(
        //   //   generateNewAttachmentOption(app, app.attachments.length - 1, form)
        //   // );

        //   return options;
        // }

        const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
          const byteCharacters = atob(b64Data);
          const byteArrays = [];

          for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
          ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          return blob;
        };
      });
    } catch (err) {
      console.error(err);
    }
  };
}

import View from './view.js';
import { sharedDict } from './entities/dictionary.js';

export default class KPIPunctualityView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup parameters and controller handlers
        let {
          year,
          years,
          subjects,
          punctualities,
          handlerGetData: getData,
        } = handlers;
        if (year) year = new Date().getFullYear();
        const filteredRoles = [
          {
            name: dict.getTextForKey('WEB_KPI_CUSTOMER'),
            type: 1,
          },
          {
            name: dict.getTextForKey('WEB_KPI_SUPPLIER'),
            type: 2,
          },
        ];
        // Setup filters
        let selectBoxYear;
        let selectBoxCarrier;
        let selectBoxSubjectType;
        let selectBoxSubject;
        let query = {
          year: year,
          idCarrier: null,
          idSubject: null,
        };
        const toolbar = $('#toolbar-kpi-punctuality')
          .dxToolbar({
            onContentReady: function (e) {
              // TODO:
            },
            items: [
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  label: dict.getTextForKey('WEB_KPI_YEAR'),
                  items: years,
                  displayExpr: 'year',
                  valueExpr: 'year',
                  value: year,
                  onInitialized(e) {
                    selectBoxYear = e.component;
                  },
                  onValueChanged(e) {
                    query.year = e.value;
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  width: 'auto',
                  label: dict.getTextForKey('WEB_KPI_CARRIER'),
                  items: subjects.filter(
                    el => el.type === 3 && el.isEnabled === true
                  ),
                  displayExpr: 'name',
                  valueExpr: 'id',
                  onInitialized(e) {
                    selectBoxCarrier = e.component;
                  },
                  onValueChanged(e) {
                    query.idCarrier = e.value;
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  width: 'auto',
                  label: dict.getTextForKey('WEB_KPI_SUBJECT_TYPE'),
                  items: filteredRoles,
                  displayExpr: 'name',
                  valueExpr: 'type',
                  onInitialized(e) {
                    selectBoxSubjectType = e.component;
                  },
                  onValueChanged(e) {
                    // Clear old value
                    selectBoxSubject.option('value', null);
                    // Filter subjects for the current idCompany, isEnabled = true
                    selectBoxSubject
                      .getDataSource()
                      .filter(['type', '=', e.value], ['isEnabled', '=', true]);
                    selectBoxSubject.getDataSource().reload();
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxSelectBox',
                options: {
                  width: 'auto',
                  label: dict.getTextForKey('WEB_KPI_SUBJECTS'),
                  items: subjects,
                  displayExpr: 'name',
                  valueExpr: 'id',
                  onInitialized: function (e) {
                    selectBoxSubject = e.component;
                  },
                  onValueChanged(e) {
                    query.idSubject = e.value;
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  type: 'default',
                  text: dict.getTextForKey('WEB_KPI_RESET'),
                  onClick(e) {
                    selectBoxYear.option('value', year);
                    selectBoxCarrier.option('value', null);
                    selectBoxSubjectType.option('value', null);
                    selectBoxSubject.option('value', null);
                  },
                },
              },
              {
                location: 'after',
                widget: 'dxButton',
                options: {
                  icon: 'search',
                  onClick(e) {
                    getData(query, function (data) {
                      reloadData(data);
                    });
                  },
                },
              },
            ],
          })
          .dxToolbar('instance');

        const chart = $('#chart-kip-punctuality')
          .dxChart({
            dataSource: punctualities,
            commonSeriesSettings: {
              argumentField: 'month',
              type: 'fullStackedBar',
            },
            series: [
              {
                valueField: 'puncutal',
                name: dict.getTextForKey(
                  'WEB_KPI_PUNCUTALITY_LEGEND_PUNCTUALITY'
                ),
              },
              {
                valueField: 'late',
                name: dict.getTextForKey('WEB_KPI_PUNCUTALITY_LEGEND_LATE'),
              },
              {
                valueField: 'early',
                name: dict.getTextForKey('WEB_KPI_PUNCUTALITY_LEGEND_EARLY'),
              },
              {
                valueField: 'failed',
                name: dict.getTextForKey('WEB_KPI_PUNCUTALITY_LEGEND_FAILED'),
              },
            ],
            legend: {
              verticalAlignment: 'top',
              horizontalAlignment: 'center',
              itemTextPosition: 'right',
            },
            title: {
              text: dict.getTextForKey('WEB_KPI_PUNCUTALITY_CHART_TITLE'),
              subtitle: {
                text: dict.getTextForKey('WEB_KPI_PUNCUTALITY_CHART_SUBTITLE'),
              },
            },
            export: {
              enabled: true,
            },
            tooltip: {
              enabled: true,
              customizeTooltip(arg) {
                return {
                  text: `${arg.percentText} - ${arg.valueText}`,
                };
              },
            },
          })
          .dxChart('instance');

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        function reloadData(data) {
          chart.option('dataSource', data);
          chart.refresh();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

import View from './view.js';
// import { sharedDict } from './entities/dictionary.js';

export default class ResetPasswordView extends View {
  render = function (handlers, callback) {
    try {
      // Destructuring handlers
      // Setup handler to invoke controller
      const { handlerReset, handlerLogin } = handlers;
      // const dict = sharedDict();

      $(() => {
        $('#username')
          .dxTextBox({
            name: 'Username',
            label: 'Username',
          })
          .dxValidator({
            validationRules: [{ type: 'required' }],
          });

        $('#btn-forgot-password').dxButton({
          text: 'Resend password',
          type: 'success',
          useSubmitBehavior: false,
          onClick(e) {
            const user = {
              username: $('#username').dxTextBox('instance').option('value'),
            };
            if (user.username.length === 0) {
              DevExpress.ui.notify(
                {
                  message: 'Some fields are missing!',
                  position: {
                    my: 'center top',
                    at: 'center top',
                  },
                },
                'error',
                1000
              );
              return;
            }
            handlerReset(user);
          },
        });

        $('#btn-login').dxButton({
          text: 'Go to Login',
          type: 'success',
          onClick(e) {
            handlerLogin();
          },
        });
      });
    } catch (err) {
      console.error(err);
    }
  };
}

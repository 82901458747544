﻿// import { navigation } from './navigation.js';
import View from './view.js';

export default class IndexView extends View {
  // #navigation = new NavigationView();

  // constructor() {
  //   super();
  //   this.#navigation = new NavigationView();
  // }

  // NOT USED
  renderHTML() {
    const markup = `
      <div class="app-container">
        <div id="toolbar"></div>
        <div id="notificationPopover">
          <div class="notification-list-container">
            <div id="listnotification"></div>
          </div>
        </div>
        <div id="drawer">
          <div id="menu" class="dx-theme-accent-as-background-color" style="height: 40px"></div>
          <div id="content" class="dx-theme-background-color"></div>
        </div>
      </div>`;
    this.clear();
    this.parentElement.insertAdjacentHTML('afterbegin', markup);
  }

  render(handlers, callback) {
    //DELETEME
    // console.log(dictionary.getDictionary());

    // Destructuring handlers
    const {
      handlerLoadPage: loadPage,
      handlerLogout: logout,
      handlerContentReady: contentReady,
      navigation,
      toolbar,
    } = handlers;

    let tenantId = 1;
    let IdCompany = 1;
    let currentPage = '';
    let components = {};

    // const navigation = this.#navigation.getNavigation();
    // console.log(navigation);

    // Handel navigation
    $(() => {
      /*$('#content').html(text);*/
      const drawer = $('#drawer')
        .dxDrawer({
          opened: true,
          height: 'inherit',
          selectByClick: true,
          // scrollByContent: true,
          // scrollingEnabled: true,
          closeOnOutsideClick: false,
          onContentReady() {
            components.content = $('#content');
            callback(components);

            // Triggered when Home content is ready
            contentReady();
          },
          // elementAttr: {
          //   class: 'dx-theme-accent-as-background-color',
          //   style: 'z-index: -1000; width: auto; padding-left: 100px',
          // },
          template() {
            const $list = $('<div>').addClass('panel-list');
            return $list.dxList({
              dataSource: navigation,
              hoverStateEnabled: true,
              focusStateEnabled: false,
              activeStateEnabled: false,
              // hint: 'TEST',
              height: '100vh', // Important!!!
              onContentReady() {
                components.list = $list;
                callback(components);
              },
              /////////////////////////////////////////////////////////////////
              // itemTemplate() version with tooltip
              /////////////////////////////////////////////////////////////////
              // itemTemplate: function (data, _, element) {
              //   const iconClass = data.icon;
              //   element.append(
              //     $(
              //       `<div class="tooltip"><div class="tooltiptext">${
              //         data.path.charAt(0).toUpperCase() + data.path.slice(1)
              //       }</div>
              //         <div class="icon-badge-container" style="${
              //           data.path === 'scheduler'
              //             ? 'padding-left: 5px'
              //             : 'padding-left: 0px'
              //         }">
              //           <i class="${iconClass}"></i>${
              //         data.path === 'messages'
              //           ? '<div id="badge" class="icon-badge hidden"></div>'
              //           : ''
              //       }</div></div>`
              //     )
              //   );
              // },
              // elementAttr: {
              //   class: 'dx-theme-accent-as-background-color',
              //   style: 'width: 65px; margin-left: 0;',
              // },
              /////////////////////////////////////////////////////////////////
              // itemTemplate() version with tooltip
              /////////////////////////////////////////////////////////////////
              itemTemplate: function (data, _, element) {
                const iconClass = data.icon;
                element.append(
                  $(
                    `<div>
                      <div class="icon-badge-container" style="${
                        data.path === 'scheduler'
                          ? 'padding-left: 5px'
                          : 'padding-left: 0px'
                      }">
                        <i class="${iconClass}"></i>${
                      data.path === 'messages'
                        ? '<div id="badge" class="icon-badge hidden"></div>'
                        : ''
                    }</div></div>`
                  )
                );
              },
              elementAttr: {
                class: 'dx-theme-accent-as-background-color',
                style: 'width: 65px; margin-left: 0;',
              },
              selectionMode: 'single',
              // Load current page from navigation
              onSelectionChanged: function (e) {
                if (e.addedItems[0]) {
                  currentPage = e.addedItems[0].path;

                  // ##################################
                  // Load page
                  // ##################################
                  loadPage(currentPage);
                  $('#content').load('/views/' + currentPage + '.html');

                  // NOTE Maybe side-effects later?
                  $(() => {
                    $('#tabs').dxTabs({
                      // selectedIndex: 0,
                      //width: '100px',
                      // width: function () {
                      //   return window.innerWidth / 2;
                      // },
                      scrollByContent: true,
                      // scrollingEnabled: true,
                      // height: function () {
                      //   return (window.innerHeight * 1) / 100;
                      // },
                      dataSource: e.addedItems[0].items,
                      onContentReady() {
                        if (e.addedItems[0] && e.addedItems[0].items != null) {
                          components.tabs = $('#tabs');
                          callback(components);
                        }
                      },
                      onItemClick(data) {
                        const item = data.itemData;
                        currentPage = item.path;

                        // ##################################
                        // Load page
                        // ##################################
                        loadPage(currentPage);
                        $('#content').load('/views/' + currentPage + '.html');
                      },
                    });
                  });
                }
              },
              onItemClick: onDrawerMenuItemClick,
            });
          },
        })
        .dxDrawer('instance');

      $('#toolbar').dxToolbar({
        items: toolbar,
        onContentReady() {
          // Update components
          callback(components);
        },
        // NOTE: Remove later
        // onItemClick(data) {
        //   if (data.itemData.options.icon === 'user') {
        //     const item = data.itemData;
        //     currentPage = item.path;
        //     // ##################################
        //     // Load page
        //     // ##################################
        //     loadPage(currentPage);
        //     $('#content').load('/views/' + currentPage + '.html');
        //   }
        // },
      });

      const popupContentTemplate = function () {
        //return $('<div>').append(
        //    getNotifications()
        //);
        return $('');
      };

      function getNotifications() {
        return $(
          '<div><div>20/04/2022 13:15</div><div>Arrivo merce</div></div><div><div>20/04/2022 12:45</div><div>Arrivo merce</div></div>'
        );
      }

      // NOT USED
      // $('#listnotification')
      //   .dxList({
      //     dataSource: messages,
      //     height: '100%',
      //     itemTemplate(data) {
      //       const result = $('<div>').addClass('notification-message');
      //       $('<table>')
      //         .append('<tr>')
      //         .append($('<td>').text(data.creation_date))
      //         .append(
      //           $('<td>').append(
      //             data.isread
      //               ? '<i class="fa-solid fa-check" style="color: green;"></i>'
      //               : ''
      //           )
      //         )
      //         .append('<tr>')
      //         .append($("<td colspan='2'>").text(data.text))
      //         .append('<tr>')
      //         .append(
      //           $("<td colspan='2' style='text-align:right;'>").html(
      //             '<a>Vedi</a>'
      //           )
      //         )

      //         .appendTo(result);

      //       //$('<tr>').appendTo(result);
      //       ////$('<img>').attr('src', data.ImageSrc).appendTo(result);
      //       //$('<td>').text(data.creation_date).appendTo(result);
      //       //$('<td>').append(data.isread ? '<i class="fa-solid fa-check"></i>' : '').appendTo(result);
      //       //$('<tr>').appendTo(result);
      //       //$('<td colspan="2">').text(data.text ).appendTo(result);

      //       //$(result).append("<p>Test</p>");
      //       //$('<div>').text(data.isread).appendTo(result);
      //       //$('<div>').addClass('price')
      //       //    .html(formatCurrency(data.Price)).appendTo(result);

      //       return result;
      //     },
      //   })
      //   .dxList('instance');

      $('#notificationPopover').dxPopover({
        //contentTemplate: popupContentTemplate,
        target: '#notificationButton', //'#notificationLink',
        showEvent: 'mouseenter',
        position: 'bottom',
        width: 250, //'auto',
        showTitle: false,
        dragEnabled: false,
        closeOnOutsideClick: true,
        showCloseButton: false,
        //title: 'Notifiche',
      });

      const dxMenu = $('#menu')
        .dxMenu({
          // dataSource: menuData,
          hideSubmenuOnMouseLeave: false,
          displayExpr: 'name',
          onItemClick(data) {
            const item = data.itemData;
          },
        })
        .dxMenu('instance');

      function onDrawerMenuItemClick(e) {}
    });
  }
}

import View from './view.js';
import * as helpers from './utilities/helpers.js';
import { sharedDict } from './entities/dictionary.js';

export default class SlotView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup parameters and controller handlers
        let {
          slots,
          sites,
          slotTypesSlot: slotTypes,
          slotLinesSlot: slotLines,
          slotProductTypes: ProductTypes,
          slotVehicleTypes: VehicleTypes,
          slotOperationTypes,
          user,
          handlerCreate: create,
          handlerModify: modify,
          handlerDelete,
        } = handlers;
        let treeView;
        let popupMode;
        let popup;
        let form;
        let currentIndex;
        const role = user.account.role;
        // Handler view permissions
        const editing =
          role === 'SUPADMIN' || role === 'ADMIN' || role === 'INTERNAL'
            ? true
            : false;
        const editingAllowed =
          role === 'SUPADMIN' || role === 'ADMIN' ? true : false;

        slotOperationTypes = slotOperationTypes.map(
          ({ code, nameKeyTranslation }) => {
            return {
              code: +code,
              name: dict.getTextForKey(nameKeyTranslation),
            };
          }
        );
        const dataGrid = $('#grid-slots')
          .dxDataGrid({
            dataSource: slots,
            keyExpr: 'id',
            showBorders: true,
            columnAutoWidth: true,
            allowColumnReordering: true,
            rowAlternationEnabled: true,
            focusedRowEnabled: true,
            headerFilter: {
              visible: true,
            },
            filterPanel: { visible: true },
            filterRow: { visible: true },
            selection: {
              mode: 'single',
            },
            paging: {
              pageSize: 10,
            },
            pager: {
              visible: true,
              allowedPageSizes: [10, 25, 'all'],
              showPageSizeSelector: true,
              showInfo: true,
              showNavigationButtons: true,
            },
            editing: {
              allowUpdating: editing,
              allowAdding: editing,
              allowDeleting: editing,
              useIcons: true,
              mode: 'popup',
            },
            filterRow: { visible: true },
            columns: [
              {
                dataField: 'id',
                caption: dict.getTextForKey('WEB_SLOTS_ID'),
                main: 'int',
                visible: false,
                allowFiltering: false,
              },
              {
                dataField: 'name',
                main: 'string',
                caption: dict.getTextForKey('WEB_SLOTS_NAME'),
                alignment: 'left',
              },
              {
                dataField: 'description',
                main: 'string',
                caption: dict.getTextForKey('WEB_SLOTS_DESCRIPTION'),
                alignment: 'left',
              },
              {
                caption: dict.getTextForKey('WEB_SLOTS_SLOTTYPE'),
                alignment: 'left',
                calculateCellValue: function (rowData) {
                  return dict.getTextForKey(
                    slotOperationTypes.find(el => el.code === rowData.type).name
                  );
                },
              },
              {
                dataField: 'idSite',
                caption: dict.getTextForKey('WEB_SLOTS_SITE'),
                alignment: 'left',
                calculateCellValue: function (rowData) {
                  return sites.find(el => el.id === rowData.idSite)?.name;
                },
                // NOTE: For header filter use calculateCellValue instead
                // calculateDisplayValue: function (rowData) {
                //   return sites.find(el => el.id === rowData.idSite)?.name;
                // },
              },
              {
                dataField: 'isBusy',
                width: 150,
                caption: dict.getTextForKey('WEB_SLOTS_BUSY'),
                alignment: 'center',
                allowFiltering: false,
                calculateCellValue: function (e) {
                  return e.isBusy ? '🔴' : '🟢';
                },
              },
              {
                dataField: 'isEnabled',
                // width: 100,
                caption: dict.getTextForKey('WEB_SLOTS_ENABLED'),
                alignment: 'center',
                allowFiltering: false,
                calculateCellValue: function (e) {
                  return e.isEnabled ? '🟢' : '🔴';
                },
              },
              {
                type: 'buttons',
                width: 200,
                buttons: [
                  {
                    name: 'edit',
                    onClick: function (e) {
                      showPopup(
                        'Edit',
                        slots.findIndex(el => el.id === e.row.data.id)
                      );
                    },
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    hint: 'Elimina',
                    icon: 'trash',
                    visible: editingAllowed,
                    onClick(e) {
                      const result = DevExpress.ui.dialog.confirm(
                        `<i>${dict.getTextForKey(
                          'WEB_SLOTS_DELETE_MESSAGE'
                        )}</i>`,
                        `${dict.getTextForKey('WEB_SLOTS_DELETE_TITLE')}`
                      );
                      result.done(function (dialogResult) {
                        if (dialogResult) {
                          handlerDelete(e.row.data, function (data) {
                            reloadData(data);
                          });
                        }
                      });
                      e.event.preventDefault();
                    },
                  },
                ],
              },
            ],
            onToolbarPreparing: function (e) {
              let toolbarItems = e.toolbarOptions.items;

              // customize addRow toolbar button
              for (let i = 0; i < toolbarItems.length; i++) {
                let item = toolbarItems[i];
                if (item.name === 'addRowButton') {
                  item.options.onClick = function (e) {
                    slots.push({
                      isEnabled: true,
                      isBusy: false,
                    });
                    showPopup('Add', slots.length - 1);
                  };
                  break;
                }
              }
            },
          })
          .dxDataGrid('instance');

        function showPopup(mode, index) {
          // Setup popup mode
          popupMode = mode;
          currentIndex = index;

          // Init popup
          initPopup();

          // Setup a template
          popup.option({
            contentTemplate: () => popupContentTemplate(),
          });
          popup.show();
          setTypeSlot();
        }

        function setTypeSlot() {
          let data = form.option('formData');
          const multiType = form.getEditor(`SlotType`);
          if (multiType && data.slotTypes)
            multiType.option(
              'value',
              data.slotTypes.map(x => x.idSlotType)
            );
          const multiLines = form.getEditor(`slotLine`);
          if (multiLines && data.slotLines)
            multiLines.option(
              'value',
              data.slotLines.map(x => x.idSlotLine)
            );
          const multiProduct = form.getEditor(`slotProductType`);
          if (multiProduct && data.slotProductTypes)
            multiProduct.option(
              'value',
              data.slotProductTypes.map(x => x.idSlotProductType)
            );
          const multiVehicle = form.getEditor(`slotVehicleType`);
          if (multiVehicle && data.slotVehicleTypes)
            multiVehicle.option(
              'value',
              data.slotVehicleTypes.map(x => x.idSlotVehicleType)
            );
        }

        function popupContentTemplate() {
          const content = $('<div>');
          form = content
            .dxForm({
              formData: slots[currentIndex],
              labelLocation: 'top',
              showColonAfterLabel: true,
              items: [
                {
                  itemType: 'group',
                  name: dict.getTextForKey('WEB_SLOTS_POPUP_TITLE'),
                  colCount: 3,
                  colSpan: 2,
                  items: [
                    {
                      dataField: 'name',
                      label: {
                        text: dict.getTextForKey('WEB_SLOTS_NAME'),
                      },
                      editorOptions: {
                        maxLength: '50',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SLOTS_SLOTTYPE_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'description',
                      label: {
                        text: dict.getTextForKey('WEB_SLOTS_DESCRIPTION'),
                      },
                      editorOptions: {
                        maxLength: '500',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SLOTS_SLOTTYPE_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'idSite',
                      label: {
                        text: dict.getTextForKey('WEB_SLOTS_SITE'),
                      },
                      editorType: 'dxSelectBox',
                      editorOptions: {
                        searchEnabled: true,
                        dataSource: sites,
                        valueExpr: 'id',
                        displayExpr: 'name',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SLOTS_SLOT_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'isBusy',
                      itemType: 'simple',
                      editorType: 'dxCheckBox',
                      editorOptions: {
                        text: dict.getTextForKey('WEB_SLOTS_BUSY'),
                      },
                    },
                    {
                      dataField: 'isEnabled',
                      editorType: 'dxCheckBox',
                      editorOptions: {
                        text: dict.getTextForKey('WEB_SLOTS_ENABLED'),
                        //value: slots[currentIndex].isEnabled,
                      },
                    },
                  ],
                },
                {
                  itemType: 'group',
                  name: 'slotType',
                  caption: dict.getTextForKey('WEB_SLOTS_SLOTTYPE'),
                  colCount: 2,
                  colSpan: 2,
                  items: [
                    {
                      dataField: 'type',
                      label: {
                        text: dict.getTextForKey('WEB_SLOTS_LOADING_UNLOADING'),
                      },
                      editorType: 'dxSelectBox',
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SLOTS_LOADING_UNLOADING_REQUIRED'
                          ),
                        },
                      ],
                      editorOptions: {
                        items: slotOperationTypes,
                        displayExpr: 'name',
                        valueExpr: 'code',
                      },
                    },
                    {
                      dataField: 'SlotType',
                      label: {
                        text: dict.getTextForKey('WEB_SLOTS_GATETYPE'),
                      },
                      editorType: 'dxDropDownBox',
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_SLOTS_GATETYPE_REQUIRED'
                          ),
                        },
                      ],
                      editorOptions: {
                        dataSource: slotTypes,
                        valueExpr: 'id',
                        displayExpr: 'name',
                        contentTemplate: function (e) {
                          const $treeView = $('<div>').dxTreeView({
                            dataSource: e.component.getDataSource(),
                            keyExpr: 'id',
                            displayExpr: 'name',
                            selectionMode: 'multiple',
                            selectByClick: true,
                            onContentReady: function (args) {
                              let data = form.option('formData');
                              if (!data.userCompany) {
                                const selectedKeys =
                                  args.component.getSelectedNodeKeys();
                                e.component.option('value', selectedKeys);
                                data.slotTypes = [];
                                selectedKeys.forEach(function (element) {
                                  data.slotTypes.push({
                                    IdSlotType: `${element}`,
                                  });
                                });
                              }
                            },
                            onItemSelectionChanged(args) {
                              const selectedKeys =
                                args.component.getSelectedNodeKeys();
                              e.component.option('value', selectedKeys);
                              let data = form.option('formData');
                              data.slotTypes = [];
                              selectedKeys.forEach(function (element) {
                                data.slotTypes.push({
                                  IdSlotType: `${element}`,
                                });
                              });
                            },
                          });
                          treeView = $treeView.dxTreeView('instance');
                          return $treeView;
                        },
                      },
                    },
                  ],
                },
                {
                  itemType: 'group',
                  colCount: 3,
                  colSpan: 2,
                  items: [
                    {
                      itemType: 'group',
                      name: 'slotLine',
                      caption: dict.getTextForKey('WEB_SLOTS_SLOTLINE'),
                      items: [
                        {
                          dataField: 'slotLine',
                          editorType: 'dxDropDownBox',
                          validationRules: [
                            {
                              type: 'required',
                              message: dict.getTextForKey(
                                'WEB_SLOTS_SLOTLINE_REQUIRED'
                              ),
                            },
                          ],
                          editorOptions: {
                            dataSource: slotLines,
                            valueExpr: 'id',
                            displayExpr: 'name',
                            contentTemplate: function (e) {
                              const $treeView = $('<div>').dxTreeView({
                                dataSource: e.component.getDataSource(),
                                keyExpr: 'id',
                                displayExpr: 'name',
                                selectionMode: 'multiple',
                                selectByClick: true,
                                onContentReady: function (args) {
                                  let data = form.option('formData');
                                  if (!data.userCompany) {
                                    const selectedKeys =
                                      args.component.getSelectedNodeKeys();
                                    e.component.option('value', selectedKeys);
                                    data.slotLines = [];
                                    selectedKeys.forEach(function (element) {
                                      data.slotLines.push({
                                        IdSlotLine: `${element}`,
                                      });
                                    });
                                  }
                                },
                                onItemSelectionChanged(args) {
                                  const selectedKeys =
                                    args.component.getSelectedNodeKeys();
                                  e.component.option('value', selectedKeys);
                                  let data = form.option('formData');
                                  data.slotLines = [];
                                  selectedKeys.forEach(function (element) {
                                    data.slotLines.push({
                                      IdSlotLine: `${element}`,
                                    });
                                  });
                                },
                              });
                              treeView = $treeView.dxTreeView('instance');
                              return $treeView;
                            },
                          },
                        },
                      ],
                    },
                    {
                      itemType: 'group',
                      name: 'slotProductType',
                      caption: dict.getTextForKey('WEB_SLOTS_PRODUCT_TYPES'),
                      items: [
                        {
                          dataField: 'slotProductType',
                          editorType: 'dxDropDownBox',
                          validationRules: [
                            {
                              type: 'required',
                              message: dict.getTextForKey(
                                'WEB_SLOTS_PRODUCTTYPES_REQUIRED'
                              ),
                            },
                          ],
                          editorOptions: {
                            dataSource: ProductTypes,
                            valueExpr: 'id',
                            displayExpr: 'name',
                            contentTemplate: function (e) {
                              const $treeView = $('<div>').dxTreeView({
                                dataSource: e.component.getDataSource(),
                                keyExpr: 'id',
                                displayExpr: 'name',
                                selectionMode: 'multiple',
                                selectByClick: true,
                                onItemSelectionChanged(args) {
                                  const selectedKeys =
                                    args.component.getSelectedNodeKeys();
                                  e.component.option('value', selectedKeys);
                                  let data = form.option('formData');
                                  data.slotProductTypes = [];
                                  selectedKeys.forEach(function (element) {
                                    data.slotProductTypes.push({
                                      IdSlotProductType: `${element}`,
                                    });
                                  });
                                },
                              });
                              treeView = $treeView.dxTreeView('instance');
                              return $treeView;
                            },
                          },
                        },
                      ],
                    },
                    {
                      itemType: 'group',
                      name: 'slotVehicleType',
                      caption: dict.getTextForKey('WEB_SLOTS_VEHICLE_TYPES'),
                      items: [
                        {
                          dataField: 'slotVehicleType',
                          editorType: 'dxDropDownBox',
                          validationRules: [
                            {
                              type: 'required',
                              message: dict.getTextForKey(
                                'WEB_SLOTS_VEHICLETYPES_REQUIRED'
                              ),
                            },
                          ],
                          editorOptions: {
                            dataSource: VehicleTypes,
                            valueExpr: 'id',
                            displayExpr: 'name',
                            contentTemplate: function (e) {
                              const $treeView = $('<div>').dxTreeView({
                                dataSource: e.component.getDataSource(),
                                keyExpr: 'id',
                                displayExpr: 'name',
                                selectionMode: 'multiple',
                                selectByClick: true,
                                onItemSelectionChanged(args) {
                                  const selectedKeys =
                                    args.component.getSelectedNodeKeys();
                                  e.component.option('value', selectedKeys);
                                  let data = form.option('formData');
                                  data.slotVehicleTypes = [];
                                  selectedKeys.forEach(function (element) {
                                    data.slotVehicleTypes.push({
                                      IdSlotVehicleType: `${element}`,
                                    });
                                  });
                                },
                              });
                              treeView = $treeView.dxTreeView('instance');
                              return $treeView;
                            },
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            })
            .dxForm('instance');

          $('.dx-popup-content').append(helpers.addScrollToContent(content));
        }

        function initPopup() {
          popup = $('#popup-slot')
            .dxPopup({
              contentTemplate: popupContentTemplate,
              closeOnOutsideClick: true,
              title: dict.getTextForKey('WEB_SLOTS_SLOTINFO'),
              visible: true,
              width: '80%',
              onContentReady(e) {
                let contentElement = e.component.content();
                contentElement[0].parentElement.classList.add('p20');
              },
              toolbarItems: [
                {
                  widget: 'dxButton',
                  location: 'after',
                  toolbar: 'bottom',
                  options: {
                    type: 'success',
                    stylingMode: 'outlined',
                    text: editingAllowed
                      ? dict.getTextForKey('WEB_SLOTS_CANCEL')
                      : dict.getTextForKey('WEB_SLOTS_CLOSE'),
                    toolbar: 'bottom',
                    onClick: function () {
                      popup.hide();
                      location.reload();
                    },
                  },
                },
                {
                  widget: 'dxButton',
                  location: 'after',
                  toolbar: 'bottom',
                  visible: editingAllowed,
                  options: {
                    type: 'success',
                    stylingMode: 'contained',
                    text: dict.getTextForKey('WEB_SLOTS_CONFIRM'),
                    onClick: function (e) {
                      // Check if form is valid
                      const result = form.validate();
                      if (!result.isValid) return;

                      popupMode === 'Add'
                        ? create(slots[currentIndex], function (data) {
                            slots = data;
                            reloadData(data);
                            popup.hide();
                          })
                        : modify(slots[currentIndex], function (data) {
                            slots = data;
                            reloadData(data);
                            popup.hide();
                          });
                    },
                  },
                },
              ],
            })
            .dxPopup('instance');
        }

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        function reloadData(data) {
          dataGrid.option('dataSource', data);
          dataGrid.refresh();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

import View from './view.js';
import { sharedDict } from './entities/dictionary.js';

export default class SubjectsView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup parameters and controller handlers
        let {
          sbj: subjects,
          role,
          subjecttype,
          nations,
          handlerCreate: create,
          handlerModify: modify,
          handlerDelete,
        } = handlers;
        let form;
        const editing =
          role === 'SUPADMIN' || role === 'ADMIN' || role === 'INTERNAL'
            ? true
            : false;
        const editingAllowed =
          role === 'SUPADMIN' || role === 'ADMIN' ? true : false;
        subjecttype = subjecttype.map(
          ({ id, codeRole, name_Key_Translation }) => {
            return {
              id: +id,
              codeRole: codeRole,
              descrizione: dict.getTextForKey(name_Key_Translation),
            };
          }
        );
        const dataGrid = $('#grid-subjects')
          .dxDataGrid({
            dataSource: subjects,
            // dataSource: companies.filter(
            //   company => company.id_tenant == tenantId
            // ),
            keyExpr: 'id',
            showBorders: true,
            columnAutoWidth: true,
            allowColumnReordering: true,
            rowAlternationEnabled: true,
            focusedRowEnabled: true,
            headerFilter: {
              visible: true,
            },
            filterPanel: { visible: true },
            filterRow: { visible: true },
            paging: {
              pageSize: 10,
            },
            pager: {
              visible: true,
              allowedPageSizes: [10, 25, 'all'],
              showPageSizeSelector: true,
              showInfo: true,
              showNavigationButtons: true,
            },
            editing: {
              mode: 'popup',
              allowUpdating: editing,
              allowAdding: editing,
              allowDeleting: editing,
              useIcons: true,
              popup: {
                title: dict.getTextForKey('WEB_SUBJECTS_POPUP_TITLE'),
                showTitle: true,
                width: '80%',
                closeOnOutsideClick: true,
                onContentReady(e) {
                  let contentElement = e.component.content();
                  contentElement[0].parentElement.classList.add('p20');
                },
                toolbarItems: [
                  {
                    widget: 'dxButton',
                    location: 'after',
                    toolbar: 'bottom',
                    options: {
                      stylingMode: 'outlined',
                      type: 'success',
                      text: editingAllowed
                        ? dict.getTextForKey('WEB_SUBJECTS_CANCEL')
                        : dict.getTextForKey('WEB_SUBJECTS_CLOSE'),
                      toolbar: 'bottom',
                      onClick: function (e) {
                        // Cancel and close popup
                        dataGrid.cancelEditData();
                      },
                    },
                  },
                  {
                    widget: 'dxButton',
                    location: 'after',
                    toolbar: 'bottom',
                    visible: editingAllowed,
                    options: {
                      stylingMode: 'contained',
                      type: 'success',
                      text: dict.getTextForKey('WEB_SUBJECTS_CONFIRM'),
                      onClick: function (e) {
                        // Check if form is valid
                        const result = form.validate();
                        if (!result.isValid) return;
                        // Get 'id' of modified row
                        let id = dataGrid.option('editing.editRowKey');
                        // Detect if edited
                        const editing = dataGrid.option('editing');
                        if (editing.changes.length > 0) {
                          const data = editing.changes[0].data;
                          const changedKeys = Object.keys(data);
                          // Find current element
                          let object = dataGrid
                            .option('dataSource')
                            .find(el => el.id === id);
                          // If data not exists then create, otherwise update current element
                          if (object == null) {
                            object = data;
                            create(object, function (data) {
                              dataGrid.saveEditData();
                              reloadData(data);
                            });
                          } else {
                            changedKeys.forEach(el => {
                              object[`${el}`] = data[`${el}`];
                            });
                            modify(object, function (data) {
                              dataGrid.saveEditData();
                              reloadData(data);
                            });
                          }
                        } else {
                          // Save and close popup
                          dataGrid.cancelEditData();
                        }
                      },
                    },
                  },
                ],
              },
              form: {
                onInitialized(e) {
                  form = e.component;
                },
                items: [
                  {
                    itemType: 'group',
                    colCount: 2,
                    colSpan: 2,
                    items: [
                      {
                        dataField: 'name',
                        label: {
                          text: dict.getTextForKey('WEB_SUBJECTS_NAME'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_SUBJECTS_NAME_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'type',
                        editorType: 'dxSelectBox',
                        label: {
                          text: dict.getTextForKey('WEB_SUBJECTS_SUBJECTTYPE'),
                        },
                        editorOptions: {
                          items: subjecttype,
                          displayExpr: 'descrizione',
                          valueExpr: 'id',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_SUBJECTS_SUBJECTTYPE_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'city',
                        label: {
                          text: dict.getTextForKey('WEB_SUBJECTS_CITY'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_SUBJECTS_CITY_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'address',
                        label: {
                          text: dict.getTextForKey('WEB_SUBJECTS_ADDRESS'),
                        },
                        editorOptions: {
                          maxLength: '100',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_SUBJECTS_ADDRESS_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'country',
                        label: {
                          text: dict.getTextForKey('WEB_SUBJECTS_COUNTRY'),
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                          searchEnabled: true,
                          dataSource: nations,
                          valueExpr: 'code',
                          displayExpr: 'name',
                        },
                      },
                      {
                        dataField: 'zipCode',
                        label: {
                          text: dict.getTextForKey('WEB_SUBJECTS_ZIPCODE'),
                        },
                        editorOptions: {
                          maxLength: '20',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_SUBJECTS_ZIPCODE_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'phone',
                        label: {
                          text: dict.getTextForKey('WEB_SUBJECTS_PHONE'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_SUBJECTS_PHONE_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'email',
                        label: {
                          text: dict.getTextForKey('WEB_SUBJECTS_EMAIL'),
                        },
                        editorOptions: {
                          maxLength: '100',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_SUBJECTS_EMAIL_REQUIRED'
                            ),
                          },
                          {
                            type: 'email',
                            message: dict.getTextForKey(
                              'WEB_SUBJECTS_EMAIL_INVALID'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'vatCode',
                        label: {
                          text: dict.getTextForKey('WEB_SUBJECTS_VATCODE'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_SUBJECTS_VATCODE_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'wmsCode',
                        label: {
                          text: dict.getTextForKey('WEB_SUBJECTS_WMSCODE'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_SUBJECTS_WMSCODE_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'isEnabled',
                        editorType: 'dxCheckBox',
                        editorOptions: {
                          text: dict.getTextForKey('WEB_SUBJECTS_ENABLED'),
                        },
                      },
                    ],
                  },
                ],
              },
            },
            columns: [
              {
                dataField: 'id',
                main: 'int',
                visible: false,
                allowFiltering: false,
              },
              {
                dataField: 'wmsCode',
                main: 'string',
                caption: dict.getTextForKey('WEB_SUBJECTS_WMSCODE'),
                alignment: 'left',
              },
              {
                dataField: 'type',
                caption: dict.getTextForKey('WEB_SUBJECTS_TYPE'),
                alignment: 'left',
                calculateDisplayValue: function (rowData) {
                  return subjecttype.find(sbj => sbj.id === rowData.type)
                    ?.descrizione;
                },
                headerFilter: {
                  dataSource(data) {
                    data.dataSource.postProcess = function (results) {
                      results = [];
                      subjecttype.forEach(element => {
                        results.push({
                          text: element.descrizione,
                          value: element.id,
                        });
                      });
                      return results;
                    };
                  },
                },
              },
              {
                dataField: 'name',
                main: 'string',
                caption: dict.getTextForKey('WEB_SUBJECTS_NAME'),
                alignment: 'left',
              },
              {
                dataField: 'city',
                main: 'string',
                caption: dict.getTextForKey('WEB_SUBJECTS_CITY'),
                alignment: 'left',
              },
              {
                dataField: 'address',
                main: 'string',
                caption: dict.getTextForKey('WEB_SUBJECTS_ADDRESS'),
                alignment: 'left',
              },
              {
                dataField: 'country',
                main: 'string',
                width: '20%',
                caption: dict.getTextForKey('WEB_SUBJECTS_COUNTRY'),
                alignment: 'left',
              },
              {
                dataField: 'email',
                main: 'string',
                caption: dict.getTextForKey('WEB_SUBJECTS_EMAIL'),
                alignment: 'left',
              },
              {
                dataField: 'isEnabled',
                // width: 100,
                caption: dict.getTextForKey('WEB_SUBJECTS_ENABLED'),
                alignment: 'center',
                allowFiltering: false,
                calculateCellValue: function (e) {
                  return e.isEnabled ? '🟢' : '🔴';
                },
              },
              {
                dataField: 'zipCode',
                caption: dict.getTextForKey('WEB_SUBJECTS_ZIPCODE'),
                visible: false,
              },
              {
                dataField: 'phone',
                caption: dict.getTextForKey('WEB_SUBJECTS_PHONE'),
                visible: false,
              },
              {
                dataField: 'idCompany',
                caption: dict.getTextForKey('WEB_SUBJECTS_COMPANY'),
                visible: false,
              },
              {
                dataField: 'vatCode',
                caption: dict.getTextForKey('WEB_SUBJECTS_VATCODE'),
                visible: false,
              },
              {
                type: 'buttons',
                width: 200,
                buttons: [
                  {
                    hint: 'Edit',
                    name: 'edit',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    hint: 'Delete',
                    icon: 'trash',
                    visible: editingAllowed,
                    onClick(e) {
                      const result = DevExpress.ui.dialog.confirm(
                        `<i>${dict.getTextForKey(
                          'WEB_SUBJECTS_DELETE_MESSAGE'
                        )}`,
                        `${dict.getTextForKey('WEB_SUBJECTS_DELETE_TITLE')}`
                      );
                      result.done(function (dialogResult) {
                        if (dialogResult) {
                          handlerDelete(e.row.data, function (data) {
                            reloadData(data);
                          });
                        }
                        // else {
                        //   DevExpress.ui.dialog.alert(
                        //     '<i>NON ELIMINATO</i>',
                        //     'Attenzione'
                        //   );
                        //   e.component.refresh(true);
                        // }
                      });
                      e.event.preventDefault();
                    },
                  },
                ],
              },
            ],
            onEditorPreparing: function (e) {
              if (e.dataField === 'isEnabled') {
                if (!e.row?.data?.id) {
                  e.editorOptions.value = true;
                } else {
                  e.editorOptions.value = e.value === '🟢' ? true : false;
                }
              }
            },
          })
          .dxDataGrid('instance');

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        function reloadData(data) {
          dataGrid.option('dataSource', data);
          dataGrid.refresh();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

import View from './view.js';
import { sharedDict } from './entities/dictionary.js';

export default class TenantsView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup parameters and controller handlers
        const {
          tenants,
          role,
          handlerSetTenant: setTenant,
          handlerCreate: create,
          handlerModify: modify,
          handlerDelete,
        } = handlers;
        const editing = role === 'SUPADMIN' || role === 'ADMIN' ? true : false;
        let form;
        const dataGrid = $('#grid-tenants')
          .dxDataGrid({
            dataSource: tenants,
            keyExpr: 'id',
            showBorders: true,
            columnAutoWidth: true,
            allowColumnReordering: true,
            rowAlternationEnabled: true,
            focusedRowEnabled: true,
            headerFilter: {
              visible: true,
            },
            filterPanel: { visible: true },
            filterRow: { visible: true },
            paging: {
              pageSize: 10,
            },
            pager: {
              visible: true,
              allowedPageSizes: [10, 25, 'all'],
              showPageSizeSelector: true,
              showInfo: true,
              showNavigationButtons: true,
            },
            editing: {
              mode: 'popup',
              allowUpdating: editing,
              allowAdding: editing,
              allowDeleting: editing,
              useIcons: true,
              popup: {
                title: dict.getTextForKey('WEB_TENANTS_POPUP_TITLE'),
                showTitle: true,
                width: '80%',
                closeOnOutsideClick: true,
                onContentReady(e) {
                  let contentElement = e.component.content();
                  contentElement[0].parentElement.classList.add('p20');
                },
                toolbarItems: [
                  {
                    widget: 'dxButton',
                    location: 'after',
                    toolbar: 'bottom',
                    options: {
                      stylingMode: 'outlined',
                      type: 'success',
                      text: dict.getTextForKey('WEB_TENANTS_CANCEL'),
                      toolbar: 'bottom',
                      onClick: function (e) {
                        // Cancel and close popup
                        dataGrid.cancelEditData();
                      },
                    },
                  },
                  {
                    widget: 'dxButton',
                    location: 'after',
                    toolbar: 'bottom',
                    options: {
                      stylingMode: 'contained',
                      type: 'success',
                      text: dict.getTextForKey('WEB_TENANTS_CONFIRM'),
                      onClick: function (e) {
                        // Check if form is valid
                        const result = form.validate();
                        if (!result.isValid) return;

                        // Get 'id' of modified row
                        let id = dataGrid.option('editing.editRowKey');
                        // Detect if edited
                        const editing = dataGrid.option('editing');
                        if (editing.changes.length > 0) {
                          const data = editing.changes[0].data;
                          const changedKeys = Object.keys(data);
                          // Find current element
                          let object = dataGrid
                            .option('dataSource')
                            .find(el => el.id === id);
                          // If data not exists then create, otherwise update current element
                          if (object == null) {
                            object = data;
                            object.createDb = true;
                            // object.connectionString = '';
                            create(object, function (data, res) {
                              dataGrid.saveEditData();
                              // Set new Tenant
                              if (res.data) setTenant(res.data.id);
                              // Reload data after tenant set
                              reloadData(data, true);
                            });
                          } else {
                            changedKeys.forEach(el => {
                              object[`${el}`] = data[`${el}`];
                            });
                            modify(object, function (data) {
                              dataGrid.saveEditData();
                              reloadData(data, false);
                            });
                          }
                        } else {
                          // Save and close popup
                          dataGrid.cancelEditData();
                        }
                      },
                    },
                  },
                ],
              },
              form: {
                onInitialized(e) {
                  form = e.component;
                },
                items: [
                  {
                    itemType: 'group',
                    colCount: 2,
                    colSpan: 2,
                    items: [
                      {
                        dataField: 'name',
                        label: {
                          text: dict.getTextForKey('WEB_TENANTS_NAME'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_TENANTS_NAME_REQUIRED'
                            ),
                          },
                          {
                            type: 'pattern',
                            pattern: /^\S*$/,
                            message: dict.getTextForKey(
                              'WEB_TENANTS_WHITESPACE_NOT_PERMITTED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'code',
                        label: {
                          text: dict.getTextForKey('WEB_TENANTS_CODE'),
                        },
                        editorOptions: {
                          maxLength: '10',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_TENANTS_CODE_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'isEnabled',
                        editorType: 'dxCheckBox',
                        editorOptions: {
                          text: dict.getTextForKey('WEB_TENANTS_ENABLED'),
                        },
                      },
                    ],
                  },
                ],
              },
            },
            columns: [
              {
                dataField: 'id',
                main: 'int',
                // caption: dict.getTextForKey('WEB_TENANTS_ID'),
                width: '10%',
                allowFiltering: false,
              },
              {
                dataField: 'name',
                main: 'string',
                caption: dict.getTextForKey('WEB_TENANTS_NAME'),
                alignment: 'left',
              },
              {
                dataField: 'code',
                main: 'string',
                caption: dict.getTextForKey('WEB_TENANTS_CODE'),
                width: '20%',
                alignment: 'left',
              },
              {
                dataField: 'isMain',
                main: 'bool',
                caption: dict.getTextForKey('WEB_TENANTS_MAIN_TENANT'),
                alignment: 'center',
                allowFiltering: false,
              },
              {
                dataField: 'isEnabled',
                main: 'bool',
                caption: dict.getTextForKey('WEB_TENANTS_ENABLED'),
                alignment: 'center',
                allowFiltering: false,
                calculateCellValue: function (e) {
                  return e.isEnabled ? '🟢' : '🔴';
                },
              },
              {
                dataField: 'connectionString',
                caption: dict.getTextForKey('WEB_TENANTS_CONNECTION_STRING'),
                main: 'string',
                visible: false,
                allowFiltering: false,
              },
              {
                type: 'buttons',
                width: 200,
                alignment: 'left',
                buttons: [
                  {
                    hint: 'Modifica',
                    name: 'edit',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    name: 'delete',
                    hint: 'trash',
                    onClick(e) {
                      const result = DevExpress.ui.dialog.confirm(
                        `<i>${dict.getTextForKey(
                          'WEB_TENANTS_DELETE_MESSAGE'
                        )}</i>`,
                        `${dict.getTextForKey('WEB_TENANTS_DELETE_TITLE')}`
                      );
                      result.done(function (dialogResult) {
                        if (dialogResult) {
                          handlerDelete(e.row.data, function (data) {
                            reloadData(data, true);
                          });
                        }
                      });
                      e.event.preventDefault();
                    },
                  },
                ],
              },
            ],
            onEditorPreparing: function (e) {
              if (e.dataField === 'name') {
                // If in "Edit" mode, checks if "id" exists
                if (e.row?.data?.id) {
                  e.editorOptions.disabled = true;
                }
              }
              if (e.dataField === 'isEnabled') {
                if (!e.row?.data?.id) {
                  e.editorOptions.value = true;
                } else {
                  e.editorOptions.value = e.value === '🟢' ? true : false;
                }
              }
            },
            onCellPrepared: function (e) {
              if (e.rowType == 'data') {
                if (e.data.isMain)
                  e.cellElement.find('.dx-link-delete').remove();
              }
            },
          })
          .dxDataGrid('instance');

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        // Passing "reload" parameter to force reload, used for insert and delete
        function reloadData(data, reload) {
          dataGrid.option('dataSource', data);
          dataGrid.refresh();

          if (reload) {
            location.reload();
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

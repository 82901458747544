﻿import View from './view.js';
// import { sharedDict } from './entities/dictionary.js';

export default class LoginView extends View {
  // NOT USED
  renderHTML() {
    const markup = `    
      <div id="login-block">
        <span id="login-title"> Silwa SLOT </span>
        <div class="demo-container">
          <form>
            <div class="dx-field">
              <div class="dx-field-label">Tenant</div>
              <div class="dx-field-value">
                <div id="tenantCode"></div>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label">Email</div>
              <div class="dx-field-value">
                <div id="login"></div>
              </div>
            </div>

            <div class="dx-field">
              <div class="dx-field-label">Password</div>
              <div class="dx-field-value">
                <div id="password"></div>
              </div>
            </div>
            <div id="validateAndSubmit"></div>
          </form>
        </div>
      </div>`;
    this.clear();
    this.parentElement.insertAdjacentHTML('afterbegin', markup);
  }

  render(handlers) {
    try {
      // Setup parameters and controller handlers
      const { handlerLogin: login, handlerReset } = handlers;
      // const dict = sharedDict();

      $(() => {
        $(function () {
          // $('#tenantCode')
          //   .dxTextBox({
          //     name: 'Tenand',
          //     readOnly: true,
          //     value: 'STESI',
          //   })
          //   .dxValidator({
          //     validationRules: [{ type: 'required' }],
          //   });

          $('#username')
            .dxTextBox({
              name: 'Login',
              label: 'Username',
            })
            .dxValidator({
              validationRules: [{ type: 'required' }],
            });

          $('#password')
            .dxTextBox({
              name: 'Password',
              mode: 'password',
              label: 'Password',
            })
            .dxValidator({
              validationRules: [{ type: 'required' }],
            });

          $('#btn-login').dxButton({
            text: 'Login',
            type: 'success',
            useSubmitBehavior: false,
            onClick(e) {
              const user = {
                username: $('#username').dxTextBox('instance').option('value'),
                password: $('#password').dxTextBox('instance').option('value'),
              };
              if (user.username.length === 0 || user.password.length === 0) {
                DevExpress.ui.notify(
                  {
                    message: 'Some fields are missing!',
                    position: {
                      my: 'center top',
                      at: 'center top',
                    },
                  },
                  'error',
                  1000
                );
                return;
              }

              login(user);
            },
          });

          $('#btn-forgot-password').dxButton({
            text: 'Forgot password?',
            alignment: 'right',
            type: 'success',
            onClick() {
              handlerReset();
            },
          });

          // Enter key event listener
          document.addEventListener('keypress', btnClicked);
        });
      });
    } catch (err) {
      console.error(err);
    }
  }

  // Remove listeners
  deInit() {
    // super.deInit();
    this.print();
    document.removeEventListener('keypress', btnClicked);
  }
}

// Private functions
const btnClicked = function (e) {
  if (e.code === 'Enter') {
    $('#btn-login').dxButton('instance').focus();
    $('#btn-login').trigger('dxclick');
  }
};

import View from './view.js';
import { sharedDict } from './entities/dictionary.js';

export default class CompaniesView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup parameters and controller handlers
        const {
          cmp: companies,
          role,
          nations,
          handlerSetCompany: setCompany,
          handlerCreate: create,
          handlerModify: modify,
          handlerDelete,
        } = handlers;
        let form;
        // Setup editingMode
        const editing = role === 'SUPADMIN' || role === 'ADMIN' ? true : false;
        // Setup dataGrid
        const dataGrid = $('#grid-companies')
          .dxDataGrid({
            dataSource: companies,
            keyExpr: 'id',
            showBorders: true,
            columnAutoWidth: true,
            allowColumnReordering: true,
            rowAlternationEnabled: true,
            focusedRowEnabled: true,
            headerFilter: {
              visible: true,
            },
            filterPanel: { visible: true },
            filterRow: { visible: true },
            selection: {
              mode: 'single',
            },
            paging: {
              pageSize: 10,
            },
            pager: {
              visible: true,
              allowedPageSizes: [10, 25, 'all'],
              showPageSizeSelector: true,
              showInfo: true,
              showNavigationButtons: true,
            },
            editing: {
              mode: 'popup',
              allowUpdating: editing,
              allowAdding: editing,
              allowDeleting: editing,
              useIcons: true,
              popup: {
                title: dict.getTextForKey('WEB_COMPANIES_COMPANY_INFO'),
                showTitle: true,
                width: '80%',
                closeOnOutsideClick: true,
                onContentReady(e) {
                  let contentElement = e.component.content();
                  contentElement[0].parentElement.classList.add('p20');
                },
                toolbarItems: [
                  {
                    widget: 'dxButton',
                    location: 'after',
                    toolbar: 'bottom',
                    options: {
                      stylingMode: 'outlined',
                      type: 'success',
                      text: dict.getTextForKey('WEB_COMPANIES_CANCEL'),
                      toolbar: 'bottom',
                      onClick: function (e) {
                        // Cancel and close popup
                        dataGrid.cancelEditData();
                      },
                    },
                  },
                  {
                    widget: 'dxButton',
                    location: 'after',
                    toolbar: 'bottom',
                    options: {
                      stylingMode: 'contained',
                      type: 'success',
                      text: dict.getTextForKey('WEB_COMPANIES_CONFIRM'),
                      onClick: function (e) {
                        // Check if form is valid
                        const result = form.validate();
                        if (!result.isValid) return;

                        // Get 'id' of modified row
                        let id = dataGrid.option('editing.editRowKey');
                        // Detect if edited
                        const editing = dataGrid.option('editing');
                        if (editing.changes.length > 0) {
                          const data = editing.changes[0].data;
                          const changedKeys = Object.keys(data);
                          // Find current element
                          let object = dataGrid
                            .option('dataSource')
                            .find(el => el.id === id);
                          // If data not exists then create, otherwise update current element
                          if (object == null) {
                            object = data;
                            create(object, function (data, res) {
                              // Set new Tenant
                              if (res.data) setCompany(res.data.id);
                              // Reload data after tenant set
                              reloadData(data, true);
                            });
                          } else {
                            changedKeys.forEach(el => {
                              object[`${el}`] = data[`${el}`];
                            });
                            modify(object, function (data) {
                              dataGrid.saveEditData();
                              reloadData(data, false);
                            });
                          }
                        } else {
                          // Save and close popup
                          dataGrid.cancelEditData();
                        }
                      },
                    },
                  },
                ],
              },
              form: {
                onInitialized(e) {
                  form = e.component;
                },
                items: [
                  {
                    itemType: 'group',
                    colCount: 2,
                    colSpan: 2,
                    items: [
                      {
                        dataField: 'name',
                        label: {
                          text: dict.getTextForKey('WEB_COMPANIES_NAME'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_COMPANIES_NAME_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'city',
                        label: {
                          text: dict.getTextForKey('WEB_COMPANIES_CITY'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_COMPANIES_CITY_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'address',
                        label: {
                          text: dict.getTextForKey('WEB_COMPANIES_ADDRESS'),
                        },
                        editorOptions: {
                          maxLength: '100',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_COMPANIES_ADDRESS_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'country',
                        label: {
                          text: dict.getTextForKey('WEB_COMPANIES_COUNTRY'),
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                          searchEnabled: true,
                          dataSource: nations,
                          valueExpr: 'code',
                          displayExpr: 'name',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_COMPANIES_COUNTRY_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'defaultLanguage',
                        label: {
                          text: dict.getTextForKey('WEB_COMPANIES_LANGUAGE'),
                        },
                        editorType: 'dxSelectBox',
                        editorOptions: {
                          items: dict.languages.map(lng => lng.code),
                          // value: 'language',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_COMPANIES_LANGUAGE_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'zipCode',
                        label: {
                          text: dict.getTextForKey('WEB_COMPANIES_ZIPCODE'),
                        },
                        editorOptions: {
                          maxLength: '20',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_COMPANIES_ZIPCODE_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'phone',
                        label: {
                          text: dict.getTextForKey('WEB_COMPANIES_PHONE'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_COMPANIES_PHONE_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'email',
                        label: {
                          text: dict.getTextForKey('WEB_COMPANIES_EMAIL'),
                        },
                        editorOptions: {
                          maxLength: '100',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_COMPANIES_EMAIL_REQUIRED'
                            ),
                          },
                          {
                            type: 'email',
                            message: 'Email is invalid',
                          },
                        ],
                      },
                      {
                        dataField: 'vatCode',
                        label: {
                          text: dict.getTextForKey('WEB_COMPANIES_VATCODE'),
                        },
                        editorOptions: {
                          maxLength: '50',
                        },
                        validationRules: [
                          {
                            type: 'required',
                            message: dict.getTextForKey(
                              'WEB_COMPANIES_VATCODE_REQUIRED'
                            ),
                          },
                        ],
                      },
                      {
                        dataField: 'isEnabled',
                        editorType: 'dxCheckBox',
                        editorOptions: {
                          text: dict.getTextForKey('WEB_COMPANIES_ENABLED'),
                          value: true,
                        },
                      },
                      {
                        dataField: 'note',
                        label: {
                          text: dict.getTextForKey('WEB_COMPANIES_NOTE'),
                        },
                        editorType: 'dxTextArea',
                        colSpan: 2,
                        editorOptions: {
                          height: 100,
                        },
                      },
                    ],
                  },
                ],
              },
            },
            columns: [
              {
                dataField: 'id',
                caption: dict.getTextForKey('WEB_COMPANIES_ID'),
                main: 'int',
                visible: false,
                allowFiltering: false,
              },
              {
                dataField: 'name',
                caption: dict.getTextForKey('WEB_COMPANIES_NAME'),
                main: 'string',
                alignment: 'left',
              },
              {
                dataField: 'city',
                caption: dict.getTextForKey('WEB_COMPANIES_CITY'),
                main: 'string',
                alignment: 'left',
              },
              {
                dataField: 'address',
                caption: dict.getTextForKey('WEB_COMPANIES_ADDRESS'),
                main: 'string',
                alignment: 'left',
              },
              {
                dataField: 'country',
                caption: dict.getTextForKey('WEB_COMPANIES_COUNTRY'),
                main: 'string',
                width: '20%',
                alignment: 'left',
              },
              {
                dataField: 'email',
                caption: dict.getTextForKey('WEB_COMPANIES_EMAIL'),
                main: 'string',
                alignment: 'left',
              },
              {
                dataField: 'isEnabled',
                caption: dict.getTextForKey('WEB_COMPANIES_ENABLED'),
                // width: 100,
                alignment: 'center',
                allowFiltering: false,
                calculateCellValue: function (e) {
                  return e.isEnabled ? '🟢' : '🔴';
                },
              },
              {
                dataField: 'zipCode',
                caption: dict.getTextForKey('WEB_COMPANIES_ZIPCODE'),
                visible: false,
              },
              {
                dataField: 'phone',
                caption: dict.getTextForKey('WEB_COMPANIES_PHONE'),
                visible: false,
              },
              {
                dataField: 'defaultLanguage',
                caption: dict.getTextForKey('WEB_COMPANIES_LANGUAGE'),
                visible: false,
                allowFiltering: false,
              },
              {
                dataField: 'note',
                caption: dict.getTextForKey('WEB_COMPANIES_NOTE'),
                visible: false,
              },
              {
                dataField: 'vatCode',
                caption: dict.getTextForKey('WEB_COMPANIES_VATCODE'),
                visible: false,
              },
              {
                type: 'buttons',
                width: 200,
                buttons: [
                  {
                    hint: 'Modifica',
                    name: 'edit',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    hint: 'Elimina',
                    icon: 'trash',
                    onClick(e) {
                      const result = DevExpress.ui.dialog.confirm(
                        `<i>${dict.getTextForKey(
                          'WEB_COMPANIES_DELETE_MESSAGE'
                        )}</i>`,
                        `${dict.getTextForKey('WEB_COMPANIES_DELETE_TITLE')}`
                      );
                      result.done(function (dialogResult) {
                        if (dialogResult) {
                          handlerDelete(e.row.data, function (data) {
                            reloadData(data, true);
                          });
                          // DevExpress.ui.dialog.alert(
                          //   '<i>ELIMINATO</i>',
                          //   'Attenzione'
                          // );
                          // e.component.refresh(true);
                          // location.reload();
                        }
                      });
                      e.event.preventDefault();
                    },
                  },
                ],
              },
            ],
            onEditorPreparing: function (e) {
              if (e.dataField === 'isEnabled') {
                if (!e.row?.data?.id) {
                  e.editorOptions.value = true;
                } else {
                  e.editorOptions.value = e.value === '🟢' ? true : false;
                }
              }
            },
          })
          .dxDataGrid('instance');

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        function reloadData(data, reload) {
          dataGrid.option('dataSource', data);
          dataGrid.refresh();

          if (reload) {
            location.reload();
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

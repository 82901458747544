// ##################################
// Helper Generic
// ##################################
export const showSuccessDialog = function (msg) {
  DevExpress.ui.notify(
    {
      message: msg,
      position: {
        my: 'center top',
        at: 'center top',
      },
    },
    'success',
    3000
  );
};

export const showErrorDialog = function (msg) {
  DevExpress.ui.notify(
    {
      message: msg,
      position: {
        my: 'center top',
        at: 'center top',
      },
    },
    'error',
    3000
  );
};

export const processResponse = function (res) {
  const { result, message } = res;
  if (result.toLowerCase() !== 'ok') {
    showErrorDialog(message);
    return false;
  }
  return true;
};

// ##################################
// Helper Views
// ##################################
export const addScrollToContent = function (content) {
  let scrollView = $("<div id='scrollView'></div>");
  scrollView.append(content);
  scrollView.dxScrollView({
    height: '100%',
    width: '100%',
  });
  return scrollView;
};

import { UNKNOWN_TEXT } from '../utilities/config.js';

class Dictionary {
  dictionary;
  version;
  languages;

  // Retunr true if dictionary needs to be updated
  checkDictVersion(ver) {
    const savedDict = deserialize();
    if (!savedDict || !savedDict.version) return true;
    return +savedDict.version < +ver;
  }

  setDictionary(data) {
    // const { name, surname, token, isLogged } = data;
    this.languages = data.languages;
    this.dictionary = data.dictionary;
    this.version = data.version;
    serialize(this);
  }

  getTextForKey = function (key) {
    if (!this.dictionary || this.dictionary.length === 0) return;

    if (!key) return UNKNOWN_TEXT;

    const text = this.dictionary.find(el => el.key === key)?.text;

    if (!text) return key;

    return text;
  };

  print() {
    // console.log(
    //   `dict: ${this.dictionary}, version: ${this.version}, languages: ${this.languages}`
    // );
  }
}

const serialize = function (dict) {
  localStorage.setItem('dictionary', JSON.stringify(dict));
};

const deserialize = function () {
  return JSON.parse(localStorage.getItem('dictionary'));
};

// Create shared instance
let dictionary = null;

export const sharedDict = function () {
  if (!dictionary) {
    dictionary = new Dictionary();
    const savedDictionary = deserialize();
    if (savedDictionary) dictionary.setDictionary(savedDictionary);
  }
  return dictionary;
};

export const resetDictionary = function () {
  localStorage.removeItem('dictionary');
  dictionary = null;
};

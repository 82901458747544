import View from './view.js';
import { sharedDict } from './entities/dictionary.js';

export default class MessagesView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Setup parameters and controller handlers
        const {
          user,
          messages: messages,
          handlerGetMessages: getMessages,
          handlerIsRead: isRead,
          handlerupdateAllMessage: updateAllMessage,
          handlerDelete: deleteMessage,
        } = handlers;

        // const test = data.every(el => el.isRead === true);
        // console.log(data);

        // Setup default date to now (used for filters)
        const date = new Date();
        let query = {
          from: date,
          to: date,
        };
        // console.log(data);
        const dataGrid = $('#grid-messages')
          .dxDataGrid({
            dataSource: messages,
            showBorders: true,
            columnAutoWidth: true,
            allowColumnReordering: true,
            rowAlternationEnabled: true,
            toolbar: {
              items: [
                {
                  location: 'before',
                  widget: 'dxButton',
                  disabled: messages.every(el => el.isRead === true),
                  options: {
                    type: 'default',
                    text: dict.getTextForKey('WEB_MESSAGES_MARK_ALL_READ'),
                    onClick(e) {
                      updateAllMessage(query, function () {
                        getMessages(query, function (data) {
                          // Check if there are unread messages
                          dataGrid.option(
                            'toolbar.items[0].disabled',
                            data.every(el => el.isRead === true)
                          );
                          // Reload data
                          reloadData(data);
                        });
                      });
                    },
                  },
                },
                {
                  location: 'after',
                  widget: 'dxDateBox',
                  options: {
                    label: dict.getTextForKey('WEB_MESSAGES_FROM'),
                    type: 'date',
                    value: date,
                    onValueChanged(e) {
                      query.from = e.value;
                    },
                  },
                },
                {
                  location: 'after',
                  widget: 'dxDateBox',
                  options: {
                    label: dict.getTextForKey('WEB_MESSAGES_TO'),
                    type: 'date',
                    value: date,
                    onValueChanged(e) {
                      query.to = e.value;
                    },
                  },
                },
                {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    icon: 'search',
                    onClick(e) {
                      getMessages(query, function (data) {
                        // Check if there are unread messages
                        dataGrid.option(
                          'toolbar.items[0].disabled',
                          data.every(el => el.isRead === true)
                        );
                        // Reload data
                        reloadData(data);
                      });
                    },
                  },
                },
              ],
            },
            headerFilter: {
              visible: true,
            },
            filterPanel: { visible: true },
            filterRow: { visible: true },
            paging: {
              pageSize: 10,
            },
            pager: {
              visible: true,
              allowedPageSizes: [10, 25, 'all'],
              showPageSizeSelector: true,
              showInfo: true,
              showNavigationButtons: true,
            },
            columns: [
              {
                dataField: 'isRead',
                dataType: 'string',
                // sortIndex: 1,
                sortOrder: 'desc',
                allowFiltering: false,
                caption: dict.getTextForKey('WEB_MESSAGES_READ'),
                calculateDisplayValue: function (e) {
                  return !e.isRead ? '🔵' : '';
                },
              },
              {
                dataField: 'creationDate',
                dataType: 'string',
                // sortIndex: 0,
                sortOrder: 'desc',
                caption: dict.getTextForKey('WEB_MESSAGES_CREATION_DATE'),
                calculateDisplayValue: function (e) {
                  return new Date(e.creationDate).toLocaleString(user.language);
                },
              },
              {
                dataField: 'text',
                dataType: 'string',
                caption: dict.getTextForKey('WEB_MESSAGES_TEXT'),
              },
              {
                type: 'buttons',
                buttons: [
                  {
                    hint: 'Info',
                    icon: 'info',
                    onClick(e) {
                      // console.log(e.row.data);
                      const result = DevExpress.ui.dialog.alert(
                        `<i>${e.row.data.text}</i>`,
                        `${dict.getTextForKey('WEB_MESSAGES_MESSAGE')}`
                      );
                      if (!e.row.data.isRead)
                        isRead(e.row.data, function () {
                          getMessages(query, function (data) {
                            // Check if there are unread messages
                            dataGrid.option(
                              'toolbar.items[0].disabled',
                              data.every(el => el.isRead === true)
                            );
                            // Reload data
                            reloadData(data);
                          });
                        });
                      // e.event.preventDefault();
                    },
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    hint: 'Elimina',
                    icon: 'trash',
                    onClick(e) {
                      const result = DevExpress.ui.dialog.confirm(
                        `<i>${dict.getTextForKey(
                          'WEB_MESSAGES_DELETE_MESSAGE'
                        )}</i>`,
                        `${dict.getTextForKey('WEB_MESSAGES_ATTENTION')}`
                      );
                      result.done(function (dialogResult) {
                        if (dialogResult) {
                          deleteMessage(e.row.data, function () {
                            getMessages(query, function (data) {
                              // Check if there are unread messages
                              dataGrid.option(
                                'toolbar.items[0].disabled',
                                data.every(el => el.isRead === true)
                              );
                              // Reload data
                              reloadData(data);
                            });
                          });
                        }
                        // else {
                        //   DevExpress.ui.dialog.alert(
                        //     '<i>NON ELIMINATO</i>',
                        //     'Attenzione'
                        //   );
                        //   e.component.refresh(true);
                        // }
                      });
                      e.event.preventDefault();
                    },
                  },
                ],
              },
            ],
          })
          .dxDataGrid('instance');

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        function reloadData(data) {
          dataGrid.option('dataSource', data);
          dataGrid.refresh();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

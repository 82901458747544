import { debugLog } from './utilities/logger.js';

class Router {
  routes = [];
  add(route) {
    this.routes.push(route);
  }
  getRoute(name) {
    const route = this.routes.find(
      route => route.name.toLowerCase() === name.toLowerCase()
    );
    // debugLog(route);
    return route;
  }
  // Not used
  /*
  setCurRoute(url) {
    localStorage.setItem('curUrl', JSON.stringify(url));
  }
  getCurRoute() {
    return JSON.parse(localStorage.getItem('curUrl'));
  }
  */
}

// Export router object
export let router = null;

// ##################################
// Helper function "runOnce"
// ##################################
(function () {
  router = new Router();
  // debugLog('New router created');

  // Init routes
  router.add({
    name: 'index',
    file: '/index.html',
    path: 'index.html',
  });
  router.add({
    name: 'home',
    file: '/views/home.html',
    path: '#home',
  });
  router.add({
    name: 'login',
    file: '/views/login.html',
    path: '#login',
  });
  router.add({
    name: 'resetPassword',
    file: '/views/resetPassword.html',
    path: '#resetPassword',
  });
  router.add({
    name: 'scheduler',
    file: '/views/scheduler.html',
    path: '#scheduler',
  });
  router.add({
    name: 'archive',
    file: '/views/archive.html',
    path: '#archive',
  });
  router.add({
    name: 'tenants',
    file: '/views/tenants.html',
    path: '#tenants',
  });
  router.add({
    name: 'users',
    file: '/views/users.html',
    path: '#users',
  });
  router.add({
    name: 'userDetails',
    file: '/views/userDetails.html',
    path: '#userDetails',
  });
  router.add({
    name: 'companies',
    file: '/views/companies.html',
    path: '#companies',
  });
  router.add({
    name: 'site',
    file: '/views/site.html',
    path: '#site',
  });
  router.add({
    name: 'slot',
    file: '/views/slot.html',
    path: '#slot',
  });
  router.add({
    name: 'vehiclestype',
    file: '/views/vehiclesType.html',
    path: '#vehiclestype',
  });
  router.add({
    name: 'producttype',
    file: '/views/productType.html',
    path: '#producttype',
  });
  router.add({
    name: 'slotline',
    file: '/views/slotLine.html',
    path: '#slotline',
  });
  router.add({
    name: 'slottype',
    file: '/views/slotType.html',
    path: '#slottype',
  });
  router.add({
    name: 'subjects',
    file: '/views/subjects.html',
    path: '#subjects',
  });
  router.add({
    name: 'messages',
    file: '/views/messages.html',
    path: '#messages',
  });
  router.add({
    name: 'logs',
    file: '/views/logs.html',
    path: '#logs',
  });
  router.add({
    name: 'kpiPunctuality',
    file: '/views/kpiPunctuality.html',
    path: '#kpiPunctuality',
  });
  router.add({
    name: 'kpiTransits',
    file: '/views/kpiTransits.html',
    path: '#kpiTransits',
  });
  router.add({
    name: 'kpiCarriers',
    file: '/views/kpiCarriers.html',
    path: '#kpiCarriers',
  });
  router.add({
    name: 'kpiSlotsOccupation',
    file: '/views/kpiSlotsOccupation.html',
    path: '#kpiSlotsOccupation',
  });
})();

// debugLog(router);
// debugLog(router.getRoute('Home').path);

// export const routeIndex = {
//   file: '/index.html',
//   path: 'index.html',
// };
// export const routeHome = {
//   file: './views/home.html',
//   path: '#home',
// };
// export const routeLogin = {
//   file: './views/login.html',
//   path: '#login',
// };
// export const routeMessages = {
//   file: './views/messages.html',
//   path: '#messages',
// };

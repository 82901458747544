export default class View {
  parentElement = document.querySelector('.dx-viewport');

  // Base constructor
  constructor() {
    //console.log('View is initialized');
  }

  clear() {
    // console.log('CLEAN INNER HTML');
    this.parentElement.innerHTML = '';
  }

  deInit() {
    // console.log('VIEW DEINIT CALLED');
  }

  print() {
    // console.log('VIEW PRINT CALLED');
  }

  // constructor(model) {
  //   this.model = model;
  // }
  // run() {}
}

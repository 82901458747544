import View from './view.js';
import * as helpers from './utilities/helpers.js';
import { sharedDict } from './entities/dictionary.js';
import { sharedUser } from './entities/user.js';

export default class UsersView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared objects
        const user = sharedUser();
        const dict = sharedDict();
        let {
          handlerCreate: createUser,
          handlerModify: modifyUser,
          handlerDelete: deleteUser,
          users,
          role,
          roles,
          cmp: companies,
          sbj: subjects,
        } = handlers;
        const editing = role === 'SUPADMIN' || role === 'ADMIN' ? true : false;
        const emptyPassword = 'A!123A!123A';
        let treeView;
        let popupMode;
        let popup;
        let form;
        let currentIndex;
        const companiesDataSourceTmp = users.map(usr => {
          return {
            text: usr.userCompany.map(el => el.name).join(', '),
            value: usr.userCompany.map(el => el.name).join(', '),
          };
        });
        const companiesDataSource = [];
        companiesDataSourceTmp.forEach(x => {
          if (!companiesDataSource.find(y => y.text === x.text))
            companiesDataSource.push(x);
        });

        roles = roles.map(({ code, nameKeyTranslation, visible }) => {
          return {
            code: code,
            name: dict.getTextForKey(nameKeyTranslation),
            visible: visible,
          };
        });
        const dataGrid = $('#grid-users')
          .dxDataGrid({
            dataSource: users,
            keyExpr: 'id',
            rowAlternationEnabled: true,
            columnAutoWidth: true,
            headerFilter: {
              visible: true,
            },
            filterPanel: { visible: true },
            filterRow: { visible: true },
            selection: {
              mode: 'single',
            },
            paging: {
              pageSize: 10,
            },
            pager: {
              visible: true,
              allowedPageSizes: [10, 25, 'all'],
              showPageSizeSelector: true,
              showInfo: true,
              showNavigationButtons: true,
            },
            editing: {
              allowUpdating: editing,
              allowAdding: editing,
              allowDeleting: editing,
              useIcons: true,
              mode: 'popup',
            },
            columns: [
              {
                dataField: 'id',
                allowFiltering: false,
              },
              {
                dataField: 'name',
                main: 'string',
                caption: dict.getTextForKey('WEB_USERS_FIRSTNAME'),
                alignment: 'left',
              },
              {
                dataField: 'surname',
                main: 'string',
                caption: dict.getTextForKey('WEB_USERS_LASTNAME'),
                alignment: 'left',
              },
              {
                dataField: 'email',
                main: 'string',
                caption: dict.getTextForKey('WEB_USERS_EMAIL'),
                alignment: 'left',
              },
              {
                dataField: 'account.role',
                main: 'string',
                caption: dict.getTextForKey('WEB_USERS_ROLE'),
                alignment: 'left',
                calculateCellValue: function (rowData) {
                  return dict.getTextForKey(
                    roles.find(el => el.code === rowData.account.role).name
                  );
                },
              },
              {
                // Needs to display headerFilter, if not specified headerFilter is hidden
                dataField: 'userCompany',
                caption: dict.getTextForKey('WEB_USERS_COMPANY'),
                alignment: 'left',
                // allowFiltering: false,
                headerFilter: {
                  dataSource: companiesDataSource,
                },
                calculateCellValue: function (rowData) {
                  return rowData.userCompany.map(el => el.name).join(', ');
                },
                // calculateDisplayValue: function (rowData) {
                //   return rowData.userCompany.map(el => el.name).join(', ');
                // },
              },
              {
                dataField: 'note',
                main: 'string',
                caption: dict.getTextForKey('WEB_USERS_NOTE'),
                alignment: 'left',
              },
              {
                dataField: 'language',
                caption: dict.getTextForKey('WEB_USERS_LANGUAGE'),
                allowFiltering: false,
              },
              {
                type: 'buttons',
                alignment: 'left',
                buttons: [
                  {
                    name: 'edit',
                    onClick: function (e) {
                      showPopup(
                        'Edit',
                        users.findIndex(el => el.id === e.row.data.id)
                      );
                    },
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    itemType: 'empty',
                  },
                  {
                    name: 'delete',
                    onClick: function (e) {
                      const result = DevExpress.ui.dialog.confirm(
                        `<i>${dict.getTextForKey(
                          'WEB_USERS_DELETE_MESSAGE'
                        )}</i>`,
                        `${dict.getTextForKey('WEB_USERS_DELETE_TITLE')}`
                      );
                      result.done(function (dialogResult) {
                        if (dialogResult) {
                          deleteUser(e.row.data, function () {
                            location.reload();
                          });
                        }
                      });
                    },
                  },
                ],
              },
            ],
            onToolbarPreparing: function (e) {
              let toolbarItems = e.toolbarOptions.items;
              // Customize addRow toolbar button
              toolbarItems.forEach(item => {
                if (item.name === 'addRowButton') {
                  item.options.onClick = function (e) {
                    // Add new user in memory
                    users.push({
                      note: '',
                      userCompany: [],
                    });
                    showPopup('Add', users.length - 1);
                  };
                  return;
                }
              });
            },
            onCellPrepared: function (e) {
              if (e.rowType == 'data') {
                if (e.data.id === user.id || e.data.account.role === 'SUPADMIN')
                  e.cellElement.find('.dx-link-delete').remove();
              }
            },
          })
          .dxDataGrid('instance');

        function showPopup(mode, index) {
          // Setup popup mode
          popupMode = mode;
          currentIndex = index;

          // Init popup
          initPopup();

          // Setup a template
          popup.option({
            contentTemplate: () => popupContentTemplate(),
            onContentReady(e) {
              let contentElement = e.component.content();
              contentElement[0].parentElement.classList.add('p20');
            },
          });
          popup.show();

          checkUserPermissions(
            users[currentIndex],
            users[currentIndex]?.account?.role
          );
          setCompanyUser();
        }

        function checkUserPermissions(user, role = undefined) {
          form.itemOption(
            'company',
            'visible',
            role === 'ADMIN' || role === 'INTERNAL'
          );

          /////////////////////////////////////////////////////////////
          // UNCOMMENT TO HAVE MULTIPLE INSERTS OF COMPANY AND SUBJECTS
          /////////////////////////////////////////////////////////////
          form.itemOption(
            'company-subject-group',
            'visible',
            role === 'CARRIER' || role === 'CUSTOMER' || role === 'SUPPLIER'
          );
          /////////////////////////////////////////////////////////////
          // UNCOMMENT TO HAVE MULTIPLE INSERTS OF COMPANY AND SUBJECTS
          /////////////////////////////////////////////////////////////

          form.itemOption(
            'company-subject-container',
            'visible',
            role === 'CARRIER' || role === 'CUSTOMER' || role === 'SUPPLIER'
          );

          //////////////////////////////////////////////////////////
          // DELETE TO HAVE MULTIPLE INSERTS OF COMPANY AND SUBJECTS
          //////////////////////////////////////////////////////////
          // if (
          //   role === 'CARRIER' ||
          //   role === 'CUSTOMER' ||
          //   role === 'SUPPLIER'
          // ) {
          //   // If there is no subject create one
          //   if (user.userCompany?.length === 0)
          //     form.itemOption(
          //       'company-subject-container',
          //       'items',
          //       createUserCompanyOptions()
          //     );
          // }
          //////////////////////////////////////////////////////////
          // DELETE TO HAVE MULTIPLE INSERTS OF COMPANY AND SUBJECTS
          //////////////////////////////////////////////////////////
        }

        function setCompanyUser() {
          let data = form.option('formData');
          const multiSelectEditor = form.getEditor(`idCompany`);
          if (multiSelectEditor)
            multiSelectEditor.option(
              'value',
              data.userCompany.map(x => x.idCompany)
            );
        }

        function popupContentTemplate() {
          const content = $('<div>');
          let validationRules = [];
          if (popupMode === 'Add') {
            validationRules = [
              {
                type: 'required',
                message: dict.getTextForKey('WEB_USERS_PASSWORD_REQUIRED'),
              },
            ];
          }
          form = content
            .dxForm({
              formData: users[currentIndex],
              labelLocation: 'top',
              showColonAfterLabel: true,
              onContentReady: function (e) {
                // console.log(users[currentIndex]);
                if (popupMode === 'Edit') {
                  const user = users[currentIndex];
                  user.account.password = emptyPassword;
                }
              },
              colCountByScreen: {
                lg: 2,
                md: 2,
                sm: 1,
                xs: 1,
              },
              items: [
                {
                  itemType: 'group',
                  name: 'account',
                  colCount: 2,
                  colSpan: 2,
                  items: [
                    {
                      dataField: 'name',
                      label: {
                        text: dict.getTextForKey('WEB_USERS_NAME'),
                      },
                      editorOptions: {
                        maxLength: '50',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_USERS_NAME_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'surname',
                      label: {
                        text: dict.getTextForKey('WEB_USERS_SURNAME'),
                      },
                      editorOptions: {
                        maxLength: '50',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_USERS_SURNAME_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'email',
                      label: {
                        text: dict.getTextForKey('WEB_USERS_EMAIL'),
                      },
                      editorOptions: {
                        maxLength: '100',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_USERS_EMAIL_REQUIRED'
                          ),
                        },
                        {
                          type: 'email',
                          message: dict.getTextForKey(
                            'WEB_USERS_EMAIL_INVALID'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'language',
                      label: {
                        text: dict.getTextForKey('WEB_USERS_LANGUAGE'),
                      },
                      editorType: 'dxSelectBox',
                      editorOptions: {
                        items: dict.languages.map(lng => lng.code),
                        // value: 'language',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_USERS_LANGUAGE_REQUIRED'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'account.username',
                      label: {
                        text: dict.getTextForKey('WEB_USERS_USERNAME'),
                      },
                      editorOptions: {
                        maxLength: '20',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_USERS_USERNAME_REQUIRED'
                          ),
                        },
                        {
                          type: 'pattern',
                          pattern: /[0-9a-zA-Z_]{6,}/,
                          message: dict.getTextForKey(
                            'WEB_USERS_USERNAME_VALIDATION'
                          ),
                        },
                      ],
                    },
                    {
                      dataField: 'account.password',
                      label: {
                        text: dict.getTextForKey('WEB_USERS_PASSWORD'),
                      },
                      editorOptions: {
                        mode: 'password',
                        maxLength: '20',
                        // value: '00000000',
                      },
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_USERS_PASSWORD_REQUIRED'
                          ),
                        },
                        {
                          type: 'pattern',
                          pattern:
                            /^(?=.*[A-Za-z_])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z_\d@$!%*#?&]{8,}$/,
                          message: dict.getTextForKey(
                            'WEB_USERS_PASSWORD_VALIDATION'
                          ),
                        },
                      ],
                      // validationRules: validationRules,
                    },
                    {
                      dataField: 'note',
                      label: {
                        text: dict.getTextForKey('WEB_USERS_NOTE'),
                      },
                      editorType: 'dxTextArea',
                      colSpan: 2,
                      editorOptions: {
                        height: 100,
                      },
                    },
                  ],
                },
                {
                  itemType: 'group',
                  name: 'role',
                  caption: dict.getTextForKey('WEB_USERS_ROLE'),
                  colSpan: 2,
                  items: [
                    {
                      dataField: 'account.role',
                      editorType: 'dxSelectBox',
                      //value: 'account.role',
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_USERS_ROLE_REQUIRED'
                          ),
                        },
                      ],
                      editorOptions: {
                        items: roles,
                        displayExpr: 'name',
                        valueExpr: 'code',
                        onValueChanged: function (e) {
                          users[currentIndex].userCompany = [];
                          // Setup a template
                          popup.option({
                            contentTemplate: () => popupContentTemplate(),
                          });
                          // Set users's role
                          users[currentIndex].account.role = e.value;
                          checkUserPermissions(users[currentIndex], e.value);
                          //setCompanyUser();
                        },
                      },
                    },
                  ],
                },
                {
                  itemType: 'group',
                  colCount: 1,
                  colSpan: 2,
                  horizontalAlignment: 'right',
                  verticalAlignment: 'center',
                  name: 'company-subject-group',
                  visible: false,
                  items: [
                    {
                      itemType: 'button',
                      buttonOptions: {
                        icon: 'add',
                        type: 'success',
                        stylingMode: 'contained',
                        text: dict.getTextForKey(
                          'WEB_USERS_ADD_COMPANY_SUBJECT'
                        ),
                        verticalAlignment: 'bottom',
                        onClick() {
                          form.itemOption(
                            'company-subject-container',
                            'items',
                            createUserCompanyOptions()
                          );

                          // Focus on userCompany last element (just created one)
                          form
                            .getEditor(
                              `userCompany[${
                                users[currentIndex]?.userCompany.length - 1
                              }].idCompany`
                            )
                            .focus();
                        },
                      },
                    },
                  ],
                },
                {
                  itemType: 'group',
                  colCount: 1,
                  colSpan: 2,
                  name: 'company-subject-container',
                  visible: false,
                  items: getUserCompanyOptions(),
                },
                {
                  itemType: 'group',
                  name: 'company',
                  visible: false,
                  caption: dict.getTextForKey('WEB_USERS_COMPANY'),
                  disabled: user.id === users[currentIndex].id,
                  items: [
                    {
                      dataField: 'idCompany',
                      editorType: 'dxDropDownBox',
                      validationRules: [
                        {
                          type: 'required',
                          message: dict.getTextForKey(
                            'WEB_USERS_COMPANY_REQUIRED'
                          ),
                        },
                      ],
                      editorOptions: {
                        dataSource: companies,
                        valueExpr: 'id',
                        displayExpr: 'name',
                        contentTemplate: function (e) {
                          const $treeView = $('<div>').dxTreeView({
                            searchEnabled: true,
                            dataSource: e.component.getDataSource(),
                            keyExpr: 'id',
                            displayExpr: 'name',
                            selectionMode: 'multiple',
                            selectByClick: true,
                            onContentReady: function (args) {
                              let data = form.option('formData');
                              if (!data.userCompany) {
                                if (data.account.role === 'ADMIN')
                                  args.component.selectAll();
                                else {
                                  args.component.unselectAll();
                                  args.component.selectItem(
                                    args.component.itemElements()[0]
                                  );
                                }
                                const selectedKeys =
                                  args.component.getSelectedNodeKeys();
                                e.component.option('value', selectedKeys);
                                data.userCompany = [];
                                selectedKeys.forEach(function (element) {
                                  data.userCompany.push({
                                    IdCompany: `${element}`,
                                    IdSubject: `${0}`,
                                    MainCompany: false,
                                  });
                                });
                              }
                            },
                            onItemSelectionChanged(args) {
                              const selectedKeys =
                                args.component.getSelectedNodeKeys();
                              e.component.option('value', selectedKeys);
                              let data = form.option('formData');
                              data.userCompany = [];
                              selectedKeys.forEach(function (element) {
                                data.userCompany.push({
                                  IdCompany: `${element}`,
                                  IdSubject: `${0}`,
                                  MainCompany: false,
                                });
                              });
                            },
                          });
                          treeView = $treeView.dxTreeView('instance');
                          return $treeView;
                        },
                      },
                    },
                  ],
                },
                // {
                //   itemType: 'group',
                //   name: 'Azienda-container',
                //   visible: false,
                //   caption: 'Azienda',
                //   colCount: 2,
                //   colSpan: 2,
                //   items: [
                //     {
                //       dataField: `userCompany.idCompany`,
                //       label: {
                //         text: 'Company',
                //       },
                //       editorType: 'dxSelectBox',
                //       validationRules: [
                //         {
                //           type: 'required',
                //           message: 'The Company is required',
                //         },
                //       ],
                //       editorOptions: {
                //         dataSource: companies,
                //         valueExpr: `id`,
                //         displayExpr: 'name',
                //         onValueChanged: function (e) {
                //           let data = form.option('formData');
                //           data.userCompany.push({ IdCompany: e.value });
                //         },
                //       },
                //     },
                //     {
                //       dataField: `IdSubject`,
                //       editorType: 'dxSelectBox',
                //       label: {
                //         text: 'Subject',
                //       },
                //       validationRules: [
                //         {
                //           type: 'required',
                //           message: 'The Subject is required',
                //         },
                //       ],
                //       editorOptions: {
                //         dataSource: subjects,
                //         valueExpr: 'id',
                //         displayExpr: 'name',
                //       },
                //     },
                //   ],
                // },
              ],
            })
            .dxForm('instance');

          $('.dx-popup-content').append(helpers.addScrollToContent(content));
        }

        function initPopup() {
          popup = $('#popup')
            .dxPopup({
              contentTemplate: popupContentTemplate,
              title: `${popupMode} ${dict.getTextForKey('WEB_USERS_USER')}`,
              visible: true,
              width: '80%',
              closeOnOutsideClick: true,
              toolbarItems: [
                {
                  widget: 'dxButton',
                  location: 'after',
                  toolbar: 'bottom',
                  options: {
                    stylingMode: 'outlined',
                    type: 'success',
                    text: dict.getTextForKey('WEB_USERS_CANCEL'),
                    toolbar: 'bottom',
                    onClick: function () {
                      popup.hide();
                      location.reload();
                    },
                  },
                },
                {
                  widget: 'dxButton',
                  location: 'after',
                  toolbar: 'bottom',
                  options: {
                    stylingMode: 'contained',
                    type: 'success',
                    text: dict.getTextForKey('WEB_USERS_CONFIRM'),
                    onClick: function (e) {
                      // Check if form is valid
                      const result = form.validate();
                      if (!result.isValid) return;

                      const user = users[currentIndex];
                      const role = user.account?.role;

                      // Remove userCompany.IdSubject if role is not permited
                      if (
                        role === 'SUPADMIN' ||
                        role === 'ADMIN' ||
                        role === 'INTERNAL'
                      ) {
                        user.userCompany.map(el => {
                          return (el.IdSubject = null);
                        });
                        //user.userCompany = [];
                      }

                      console.log('START UPLOAD DATA');
                      // console.log(users[currentIndex]);
                      // Remove emptyPassword before send to server
                      if (user.account.password === emptyPassword)
                        user.account.password = null;
                      popup.hide();
                      popupMode === 'Add'
                        ? createUser(users[currentIndex], function () {
                            location.reload();
                          })
                        : modifyUser(users[currentIndex], function () {
                            location.reload();
                          });
                    },
                  },
                },
              ],
            })
            .dxPopup('instance');
        }

        function filteredCompanies() {
          const ids = users[currentIndex].userCompany.map(el => el.idCompany);
          return companies.filter(el => !ids.includes(el.id));
        }

        function createUserCompanyOptions() {
          const options = [];

          users[currentIndex]?.userCompany.forEach((_, i) => {
            options.push(generateNewUserCompanyOption(i));
          });

          // Push into array the new element
          users[currentIndex]?.userCompany.push({
            idCompany: null,
            idSubject: null,
          });

          options.push(
            generateNewUserCompanyOption(
              users[currentIndex]?.userCompany.length - 1
            )
          );

          return options;
        }

        function getUserCompanyOptions() {
          const options = [];
          if (users[currentIndex]?.userCompany.length === 0) {
            // Push into array the new element
            users[currentIndex]?.userCompany.push({
              idCompany: null,
              idSubject: null,
              deletable: false,
            });
          }
          users[currentIndex]?.userCompany.forEach((_, i) => {
            options.push(generateNewUserCompanyOption(i));
          });
          return options;
        }

        function generateNewUserCompanyOption(index) {
          return {
            itemType: 'group',
            /////////////////////////////////////////////////////////////
            // UNCOMMENT TO HAVE MULTIPLE INSERTS OF COMPANY AND SUBJECTS
            /////////////////////////////////////////////////////////////
            caption: dict.getTextForKey('WEB_USERS_COMPANY_SUBJECT'),
            /////////////////////////////////////////////////////////////
            // UNCOMMENT TO HAVE MULTIPLE INSERTS OF COMPANY AND SUBJECTS
            /////////////////////////////////////////////////////////////
            //////////////////////////////////////////////////////////
            // DELETE TO HAVE MULTIPLE INSERTS OF COMPANY AND SUBJECTS
            //////////////////////////////////////////////////////////
            // caption: 'Setup subject',
            //////////////////////////////////////////////////////////
            // DELETE TO HAVE MULTIPLE INSERTS OF COMPANY AND SUBJECTS
            //////////////////////////////////////////////////////////
            colCount: 3,
            visible: true,
            items: [
              {
                dataField: `userCompany[${index}].idCompany`,
                label: {
                  text: dict.getTextForKey('WEB_USERS_COMPANY'),
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                  searchEnabled: true,
                  dataSource: companies,
                  valueExpr: `id`,
                  displayExpr: 'name',
                  onOpened: function (e) {
                    // Get current editor
                    const currentEditor = form.getEditor(
                      `userCompany[${index}].idCompany`
                    );
                    // Filter dataSource with "remaining" companies
                    currentEditor.option('dataSource', filteredCompanies());
                    // Reload dataSource
                    currentEditor.getDataSource().reload();
                  },
                  onValueChanged: function (e) {
                    // Set new value for company
                    users[currentIndex].userCompany[index].idCompany = e.value;

                    // Get second editor
                    const secondEditor = form.getEditor(
                      `userCompany[${index}].idSubject`
                    );

                    // Clear old value
                    secondEditor.option('value', null);

                    // Filter subjects for the current idCompany
                    secondEditor
                      .getDataSource()
                      .filter(['idCompany', '=', e.value]);
                    secondEditor.getDataSource().reload();
                  },
                },
                validationRules: [
                  {
                    type: 'required',
                    message: dict.getTextForKey('WEB_USERS_COMPANY_REQUIRED'),
                  },
                ],
              },
              {
                dataField: `userCompany[${index}].idSubject`,
                label: {
                  text: dict.getTextForKey('WEB_USERS_SUBJECT'),
                },
                editorType: 'dxSelectBox',
                editorOptions: {
                  searchEnabled: true,
                  dataSource: subjects.filter(
                    x =>
                      x.subjectTypesRole === users[currentIndex].account?.role
                  ),
                  valueExpr: `id`,
                  displayExpr: 'name',
                  onValueChanged: function (e) {
                    users[currentIndex].userCompany[index].idSubject = e.value;
                  },
                },
                validationRules: [
                  {
                    type: 'required',
                    message: dict.getTextForKey('WEB_USERS_SUBJECT_REQUIRED'),
                  },
                ],
              },

              /////////////////////////////////////////////////////////////
              // UNCOMMENT TO HAVE MULTIPLE INSERTS OF COMPANY AND SUBJECTS
              /////////////////////////////////////////////////////////////
              {
                itemType: 'button',
                verticalAlignment: 'center',
                horizontalAlignment: 'left',
                visible: index !== 0,
                buttonOptions: {
                  icon: 'trash',
                  // text: 'Delete',
                  onClick() {
                    users[currentIndex]?.userCompany.splice(index, 1);
                    // console.log(userCompany);
                    form.itemOption(
                      'company-subject-container',
                      'items',
                      getUserCompanyOptions()
                    );
                  },
                },
              },
              /////////////////////////////////////////////////////////////
              // UNCOMMENT TO HAVE MULTIPLE INSERTS OF COMPANY AND SUBJECTS
              /////////////////////////////////////////////////////////////
            ],
          };

          // if (
          //   users[currentIndex]?.userCompany[index].idCompany === -1 ||
          //   users[currentIndex]?.userCompany[index].idSubject === -1
          // ) {
          //   return {
          //     itemType: 'group',
          //     caption: 'new-group',
          //     colCount: 3,
          //     visible: true,
          //     items: [
          //       {
          //         dataField: `userCompany[${index}].idCompany`,
          //         label: {
          //           text: 'Company',
          //         },
          //         editorType: 'dxSelectBox',
          //         editorOptions: {
          //           dataSource: companies,
          //           valueExpr: `id`,
          //           displayExpr: 'name',
          //           onValueChanged: function (e) {
          //             // Set new value for company
          //             users[currentIndex].userCompany[index].idCompany =
          //               e.value;

          //             // Get second editor
          //             const secondEditor = form.getEditor(
          //               `userCompany[${index}].idSubject`
          //             );

          //             // Clear old value
          //             secondEditor.option('value', null);

          //             // Filter subjects for the current idCompany
          //             secondEditor
          //               .getDataSource()
          //               .filter(['idCompany', '=', e.value]);
          //             secondEditor.getDataSource().reload();
          //           },
          //         },
          //         validationRules: [
          //           {
          //             type: 'required',
          //             message: 'The Company is required',
          //           },
          //         ],
          //       },
          //       {
          //         dataField: `userCompany[${index}].idSubject`,
          //         label: {
          //           text: 'Subject',
          //         },
          //         editorType: 'dxSelectBox',
          //         editorOptions: {
          //           dataSource: subjects,
          //           valueExpr: `id`,
          //           displayExpr: 'name',
          //           onValueChanged: function (e) {
          //             users[currentIndex].userCompany[index].idSubject =
          //               e.value;
          //           },
          //         },
          //         validationRules: [
          //           {
          //             type: 'required',
          //             message: 'The Subject is required',
          //           },
          //         ],
          //       },
          //       {
          //         itemType: 'button',
          //         verticalAlignment: 'center',
          //         horizontalAlignment: 'left',
          //         buttonOptions: {
          //           icon: 'trash',
          //           // text: 'Delete',
          //           onClick() {
          //             users[currentIndex]?.userCompany.splice(index, 1);
          //             // console.log(userCompany);
          //             form.itemOption(
          //               'company-subject-container',
          //               'items',
          //               getUserCompanyOptions()
          //             );
          //           },
          //         },
          //       },
          //     ],
          //   };
          // } else {
          //   return {
          //     itemType: 'group',
          //     caption: 'new-group',
          //     colCount: 3,
          //     visible: true,
          //     items: [
          //       {
          //         dataField: `userCompany[${index}].idCompany`,
          //         disabled: true,
          //         label: {
          //           text: 'Company',
          //         },
          //       },
          //       {
          //         dataField: `userCompany[${index}].idSubject`,
          //         disabled: true,
          //         label: {
          //           text: 'Subject',
          //         },
          //       },
          //       {
          //         itemType: 'button',
          //         verticalAlignment: 'center',
          //         horizontalAlignment: 'left',
          //         buttonOptions: {
          //           icon: 'trash',
          //           // text: 'Delete',
          //           onClick() {
          //             users[currentIndex]?.userCompany.splice(index, 1);
          //             // console.log(userCompany);
          //             form.itemOption(
          //               'company-subject-container',
          //               'items',
          //               getUserCompanyOptions()
          //             );
          //           },
          //         },
          //       },
          //     ],
          //   };
          // }
        }
      });
    } catch (err) {
      console.error(err);
    }
  };
}

import View from './view.js';
import { sharedDict } from './entities/dictionary.js';
import * as helpers from './utilities/helpers.js';
import { LOGTYPE } from './utilities/config.js';

export default class LogsView extends View {
  render = async function (handlers) {
    try {
      $(() => {
        // Import shared dictionary
        const dict = sharedDict();
        // Map LOGTYPE object into logType array
        const logType = LOGTYPE.map(({ code, name }) => {
          return {
            code: code,
            name: dict.getTextForKey(name),
          };
        });
        // Setup parameters and controller handlers
        const { user, logs: data, handlerGetLogs: getLogs } = handlers;
        // Setup default date to now
        const date = new Date();
        // Setup filters
        let query = {
          type: '-1',
          from: date,
          to: date,
        };
        let rowData = null;
        const dataGrid = $('#grid-logs')
          .dxDataGrid({
            dataSource: data,
            showBorders: true,
            columnAutoWidth: true,
            allowColumnReordering: true,
            rowAlternationEnabled: true,
            onContentReady: function (e) {
              // console.log(dataGrid);
            },
            toolbar: {
              items: [
                {
                  location: 'after',
                  widget: 'dxDateBox',
                  options: {
                    label: dict.getTextForKey('WEB_LOG_FROM'),
                    type: 'date',
                    value: date,
                    onValueChanged(e) {
                      query.from = e.value;
                    },
                  },
                },
                {
                  location: 'after',
                  widget: 'dxDateBox',
                  options: {
                    label: dict.getTextForKey('WEB_LOG_TO'),
                    type: 'date',
                    value: date,
                    onValueChanged(e) {
                      query.to = e.value;
                    },
                  },
                },
                {
                  location: 'after',
                  widget: 'dxSelectBox',
                  options: {
                    width: 'auto',
                    label: dict.getTextForKey('WEB_LOG_TYPE'),
                    items: logType,
                    value: logType[0].code,
                    valueExpr: 'code',
                    displayExpr: 'name',
                    onValueChanged(e) {
                      query.type = e.value;
                    },
                  },
                },
                {
                  location: 'after',
                  widget: 'dxButton',
                  options: {
                    icon: 'search',
                    onClick(e) {
                      getLogs(query, function (data) {
                        // console.log(data);
                        // Assign new data
                        dataGrid.option('dataSource', data);
                        // Reload data
                        reloadData();
                      });
                    },
                  },
                },
              ],
            },
            paging: {
              pageSize: 10,
            },
            headerFilter: {
              visible: true,
            },
            filterPanel: { visible: true },
            filterRow: { visible: true },
            pager: {
              visible: true,
              allowedPageSizes: [10, 25, 'all'],
              showPageSizeSelector: true,
              showInfo: true,
              showNavigationButtons: true,
            },
            columns: [
              {
                dataField: 'datetime',
                dataType: 'string',
                // sortIndex: 0,
                sortOrder: 'desc',
                caption: dict.getTextForKey('WEB_LOG_DATETIME'),
                calculateDisplayValue: function (e) {
                  return new Date(e.dateTime).toLocaleString(user.language);
                },
              },
              {
                dataField: 'type',
                dataType: 'string',
                caption: dict.getTextForKey('WEB_LOG_TYPE'),
                cellTemplate: function (e, info) {
                  switch (info.data.type) {
                    case '2':
                      e.append(
                        '<i class="fa-solid fa-circle-xmark" style="color:#F44336;"></i>'
                      );
                      break;
                    case '1':
                      e.append(
                        '<i class="fas fa-exclamation-triangle" style="color:#FFC107;"></i>'
                      );
                      break;
                    default:
                      e.append(
                        '<i class="fas fa-eye" style="color:#2196F3;"></i>'
                      );
                      break;
                  }
                },
                allowFiltering: false,
              },
              {
                dataField: 'code',
                dataType: 'string',
                caption: dict.getTextForKey('WEB_LOG_CODE'),
              },
              {
                dataField: 'section',
                dataType: 'string',
                caption: dict.getTextForKey('WEB_LOG_SECTION'),
              },
              {
                dataField: 'message',
                dataType: 'string',
                caption: dict.getTextForKey('WEB_LOG_MESSAGE'),
              },
              {
                dataField: 'user',
                dataType: 'string',
                caption: dict.getTextForKey('WEB_LOG_USER'),
              },
              {
                dataField: 'stack_Trace',
                dataType: 'string',
                visible: false,
              },
              {
                type: 'buttons',
                buttons: [
                  {
                    hint: 'Info',
                    icon: 'info',
                    onClick(e) {
                      rowData = e.row.data;
                      popup.option({
                        contentTemplate: () => popupContentTemplate(),
                        onContentReady(e) {
                          let contentElement = e.component.content();
                          contentElement[0].parentElement.classList.add('p20');
                        },
                      });
                      popup.show();
                    },
                  },
                ],
              },
            ],
          })
          .dxDataGrid('instance');

        const popupContentTemplate = function () {
          let scrollView = $("<div id='scrollView'></div>");
          const content = $('<div>').append(
            $(
              `<p>${dict.getTextForKey('WEB_LOG_DATETIME')}: <span>${
                rowData.dateTime
              }</span></p>`
            ),
            $(
              `<p>${dict.getTextForKey('WEB_LOG_COMPANY')}: <span>${
                rowData.company
              }</span></p>`
            ),
            $(
              `<p>${dict.getTextForKey('WEB_LOG_USER')}: <span>${
                rowData.user
              }</span></p>`
            ),
            $(
              `<p>${dict.getTextForKey('WEB_LOG_TYPE')}: <span>${
                logType.find(el => el.code === rowData.type).name
              }</span></p>`
            ),
            $(
              `<p>${dict.getTextForKey('WEB_LOG_CODE')}: <span>${
                rowData.code
              }</span></p>`
            ),
            $(
              `<p>${dict.getTextForKey('WEB_LOG_SECTION')}: <span>${
                rowData.section
              }</span></p>`
            ),
            $(
              `<p>${dict.getTextForKey('WEB_LOG_MESSAGE')}: <span>${
                rowData.message
              }</span></p>`
            ),
            $(
              `<p>${dict.getTextForKey('WEB_LOG_STACKTRACE')}: <span>${
                rowData.stack_Trace === undefined ? '' : rowData.stack_Trace
              }</span></p>`
            )
          );
          // Helper method to create scrollView inside content
          scrollView.append(content);
          scrollView.dxScrollView({
            height: '100%',
            width: '100%',
          });
          return scrollView;
        };

        const popup = $('#popup-logs')
          .dxPopup({
            contentTemplate: popupContentTemplate,
            width: '80%',
            // height: '80%',
            container: '.dx-viewport',
            showTitle: true,
            title: dict.getTextForKey('WEB_LOG_INFOLOG'),
            visible: false,
            dragEnabled: false,
            closeOnOutsideClick: true,
            showCloseButton: false,
            position: {
              at: 'center',
              my: 'center',
            },
            toolbarItems: [
              {
                widget: 'dxButton',
                toolbar: 'bottom',
                location: 'after',
                options: {
                  stylingMode: 'outlined',
                  type: 'success',
                  text: dict.getTextForKey('WEB_LOG_CLOSE'),
                  onClick() {
                    popup.hide();
                  },
                },
              },
            ],
          })
          .dxPopup('instance');

        ///////////////////////////////////////
        // Helper functions
        ///////////////////////////////////////

        const getDataSource = function () {
          return $('#grid-logs').dxDataGrid('getDataSource');
        };

        const getData = function () {
          return getDataSource().items();
        };

        const reloadData = function () {
          getDataSource().load();
        };
      });
    } catch (err) {
      console.error(err);
    }
  };
}

import { ENV } from './utilities/config.js';
// import { debugLog } from './utilities/logger.js';
import { sharedDict } from './entities/dictionary.js';
import { getTenants } from './models.js';

export default class Navigation {
  #navigation;
  #toolbar;

  getNavigation() {
    return this.#navigation;
  }

  getToolbar() {
    return this.#toolbar;
  }

  setNavigation(role) {
    const dict = sharedDict();
    this.#navigation = [];
    let i = 0;
    // Scheduler & Archive
    if (
      role === 'SUPADMIN' ||
      role === 'ADMIN' ||
      role === 'INTERNAL' ||
      role === 'SUPPLIER' ||
      role === 'CUSTOMER' ||
      role === 'CARRIER'
    ) {
      let j = 0;
      this.#navigation.push({
        id: i,
        icon: 'fa-solid fa-calendar-days fa-xl',
        path: 'scheduler',
        selectedTab: 0, // Default value
        items: [
          {
            id: j++,
            // text: 'Scheduler',
            text: dict.getTextForKey('WEB_TAB_SCHEDULER'),
            path: 'scheduler',
            onClick(e) {
              const curHash = window.location.hash;
              // NOTE: Checks current hash to prevent double click on scheduler tab
              if (curHash === '#scheduler') {
                e.event.stopPropagation();
              }
            },
          },
          {
            id: j++,
            // text: 'Archive',
            text: dict.getTextForKey('WEB_TAB_ARCHIVE'),
            path: 'archive',
            onClick(e) {},
          },
        ],
      });
    }

    // Users & Tenants
    if (role === 'SUPADMIN') {
      i++;
      let j = 0;
      this.#navigation.push({
        id: i,
        icon: 'fa-solid fa-users fa-xl',
        path: 'tenants',
        selectedTab: 0, // Default value
        items: [
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_TENANTS'),
            path: 'tenants',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_USERS'),
            path: 'users',
          },
          // {
          //   id: j++,
          //   text: 'DettaglioUtente',
          //   path: 'usersDetails',
          //   visible: false,
          // },
          {
            id: j++,
            text: '',
            path: 'TenantsDetails',
            visible: false,
          },
        ],
      });
    }

    // Users & Tenants
    if (role === 'ADMIN') {
      i++;
      let j = 0;
      this.#navigation.push({
        id: i,
        icon: 'fa-solid fa-users fa-xl',
        path: 'users',
        selectedTab: 0, // Default value
        items: [
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_USERS'),
            path: 'users',
          },
        ],
      });
    }

    // Config
    if (role === 'SUPADMIN' || role === 'ADMIN') {
      i++;
      let j = 0;
      this.#navigation.push({
        id: i,
        icon: 'fa-solid fa-gear fa-xl',
        path: 'companies',
        selectedTab: 0, // Default value
        items: [
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_COMPANIES'),
            path: 'companies',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_SITES'),
            path: 'site',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_SLOT_TYPE'),
            path: 'slottype',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_VEHICLE_TYPE'),
            path: 'vehiclestype',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_PRODUCT_TYPE'),
            path: 'producttype',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_LINE_TYPE'),
            path: 'slotline',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_SLOTS'),
            path: 'slot',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_SUBJECTS'),
            path: 'subjects',
          },
        ],
      });
    }
    // Config
    if (role === 'INTERNAL') {
      i++;
      let j = 0;
      this.#navigation.push({
        id: i,
        icon: 'fa-solid fa-gear fa-xl',
        path: 'site',
        selectedTab: 0, // Default value
        items: [
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_SITES'),
            path: 'site',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_SLOT_TYPE'),
            path: 'slottype',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_VEHICLE_TYPE'),
            path: 'vehiclestype',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_PRODUCT_TYPE'),
            path: 'producttype',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_LINE_TYPE'),
            path: 'slotline',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_SLOTS'),
            path: 'slot',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_SUBJECTS'),
            path: 'subjects',
          },
        ],
      });
    }

    // KPI
    if (role === 'SUPADMIN' || role === 'ADMIN' || role === 'INTERNAL') {
      i++;
      let j = 0;
      this.#navigation.push({
        id: i,
        icon: 'fa-solid fa-chart-pie fa-xl',
        path: 'kpiPunctuality',
        selectedTab: 0, // Default value
        items: [
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_PUNCTUALITY'),
            path: 'kpiPunctuality',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_TRANSITS'),
            path: 'kpiTransits',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_CARRIERS'),
            path: 'kpiCarriers',
          },
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_SLOTS_OCCUPATION'),
            path: 'kpiSlotsOccupation',
          },
        ],
      });
    }

    // LOGS
    if (role === 'SUPADMIN' || role === 'ADMIN') {
      i++;
      let j = 0;
      this.#navigation.push({
        id: i,
        icon: 'fa-solid fa-magnifying-glass fa-xl',
        path: 'logs',
        selectedTab: 0, // Default value
        items: [
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_LOGS'),
            path: 'logs',
          },
        ],
      });
    }

    // // NEWS
    // if (
    //   role === 'SUPADMIN' ||
    //   role === 'ADMIN' ||
    //   role === 'INTERNAL' ||
    //   role === 'SUPPLIER' ||
    //   role === 'CUSTOMER' ||
    //   role === 'CARRIER'
    // ) {
    //   i++;
    //   let j = 0;
    //   this.#navigation.push({
    //     id: i,
    //     icon: 'message',
    //     path: 'news',
    //     selectedTab: 0, // Default value
    //     items: [
    //       {
    //         id: j++,
    //         text: dict.getTextForKey('WEB_TAB_NEWS'),
    //         path: 'news',
    //       },
    //     ],
    //   });
    // }

    // MESSAGES
    if (
      role === 'ADMIN' ||
      role === 'INTERNAL' ||
      role === 'SUPPLIER' ||
      role === 'CUSTOMER' ||
      role === 'CARRIER'
    ) {
      i++;
      let j = 0;
      this.#navigation.push({
        id: i,
        icon: 'fa-solid fa-message fa-xl',
        path: 'messages',
        selectedTab: 0, // Default value
        items: [
          {
            id: j++,
            text: dict.getTextForKey('WEB_TAB_MESSAGES'),
            path: 'messages',
          },
        ],
        // template() {
        //   return $(`
        //   <div class='icon-badge-container'>
        //     <i class='fa-solid fa-messages fa-xl'></i>
        //     <div id='badge' class='icon-badge hidden'></div>
        //   </div>
        // `);
        // },
      });
    }
  }

  updateMessagesBadge(val) {
    const badge = document.querySelector('.icon-badge');
    // console.log(`BADGE = ${badge}`);
    if (!badge) return;
    val === 0
      ? badge.classList.add('hidden')
      : badge.classList.remove('hidden');
    badge.textContent = val;
  }

  setToolbar(handlers) {
    const dict = sharedDict();
    const {
      handlerLogout: logout,
      user,
      tn: tenants,
      cmp: companies,
      version,
      handlerTenant: setTenant,
      handlerCompany: setCompany,
      handlerReload: showHome,
      handlerUserDetails: showUserDetails,
    } = handlers;
    const role = user.account.role;
    let tenantId;

    this.#toolbar = [];
    this.#toolbar.push({
      widget: 'dxButton',
      location: 'before',
      options: {
        icon: 'fa-solid fa-bars',
        onClick() {
          $('#drawer').dxDrawer('instance').toggle();
        },
      },
    });
    this.#toolbar.push({
      location: 'center',
      locateInMenu: 'never',
      template() {
        return $(
          `<div class='toolbar-label'><b>Silwa SLOT</b> v: ${version} ${
            ENV.PRODUCTION ? '' : '[DEVELOPMENT]'
          }  </div>`
        );
      },
    });
    this.#toolbar.push({
      widget: 'dxButton',
      location: 'after',
      template() {
        return $(
          `<div class='toolbar-label'>${dict.getTextForKey(
            'WEB_TOOLBAR_HELLO'
          )}, ${user.name}</div> `
        );
      },
    });
    this.#toolbar.push({
      location: 'after',
      width: '20px',
    });
    if (role === 'SUPADMIN') {
      this.#toolbar.push({
        location: 'after',
        widget: 'dxSelectBox',
        locateInMenu: 'auto',
        id: 'tenant-selectbox',
        options: {
          label: dict.getTextForKey('WEB_TOOLBAR_TENANT'),
          width: 'auto',
          items: tenants,
          valueExpr: 'id',
          displayExpr: 'name',
          //value: user.idTenant,
          value: getTenantId(),
          onValueChanged(args) {
            // NOTE: Remove later (not used)
            // const seletBoxInstance =
            //   $('#company-selectbox').dxSelectBox('instance');

            // var selectBoxDataSource = $('#selectBoxContainer').dxSelectBox(
            //   'getDataSource'
            // );
            // selectBoxDataSource.reload();
            tenantId = args.value;
            setTenant(tenantId);
            // Show home and redirect to page selected by user's role
            // Example: if SUPADMIN => open tenants, ADMIN => open users
            showHome(user, true);
          },
        },
      });
    }
    //if (role === 'SUPADMIN' || role === 'ADMIN' || role === 'INTERNAL') {
    this.#toolbar.push({
      location: 'after',
      widget: 'dxSelectBox',
      locateInMenu: 'auto',
      id: 'company-selectbox',
      options: {
        label: dict.getTextForKey('WEB_TOOLBAR_COMPANY'),
        width: 'auto',
        items: companies,
        valueExpr: 'id',
        displayExpr: 'name',
        value: companies.find(cmp => cmp.id === user.idCompany)
          ? user.idCompany
          : companies[0]?.id,
        onInitialized(e) {
          const IdCompany = companies.find(cmp => cmp.id === user.idCompany)
            ? user.idCompany
            : companies[0]?.id;
          setCompany(IdCompany);
        },
        onValueChanged(args) {
          const IdCompany = args.value;
          setCompany(IdCompany);
          location.reload();
        },
      },
    });
    //}
    this.#toolbar.push({
      locateInMenu: 'always',
      widget: 'dxButton',
      path: 'userDetails',
      options: {
        text: dict.getTextForKey('WEB_TOOLBAR_USER'),
        icon: 'fa-solid fa-user',
        onClick() {
          // Important! button actions are called here
          showUserDetails();
        },
      },
    });
    this.#toolbar.push({
      locateInMenu: 'always',
      widget: 'dxButton',
      options: {
        text: dict.getTextForKey('WEB_TOOLBAR_LOGOUT'),
        icon: 'fa-solid fa-right-from-bracket',
        onClick() {
          const result = DevExpress.ui.dialog.confirm(
            `<i>${dict.getTextForKey('WEB_TOOLBAR_LOGOUT_CONFIRM_TITLE')}</i>`,
            `${dict.getTextForKey('WEB_TOOLBAR_LOGOUT_CONFIRM_MESSAGE')}`
          );
          result.done(function (dialogResult) {
            if (dialogResult) {
              // Important! button actions are called here
              logout();
            }
          });
        },
      },
    });

    function getTenantId() {
      let tenant = tenants.find(el => el.id === user.idTenant);
      if (!tenant) tenant = tenants.find(el => el.isMain === true);

      return tenant?.id;
    }
  }

  changeNavigationLanguage(code) {
    this.#navigation;
  }

  findItem(path) {
    // const tmp = this.copyArray(this.#navigation);

    // Return if navigaion not exists
    if (!this.#navigation) return;

    return this.#navigation.find(nav => {
      if (nav.path.toLowerCase() === path.toLowerCase())
        return nav.path.toLowerCase() === path.toLowerCase();
      if (
        nav.path.toLowerCase() !== path.toLowerCase() &&
        nav.items &&
        nav.items.length > 0
      ) {
        return nav.items.find(item => {
          if (item.path.toLowerCase() === path.toLowerCase()) {
            nav.path = item.path;
            nav.selectedTab = item.id;
            // debugLog(nav.selectedTab);
            // debugLog(item.id);
            return nav;
          }
        });
      }
    });

    // debugLog(tmp);
    // debugLog(this.#navigation);

    // ##################################
    // DON'T REMOVE
    // ##################################
    // return this.#navigation?.find(nav => {
    //   if (
    //     nav.path !== path.toLowerCase() &&
    //     nav.items &&
    //     nav.items.length > 0
    //   ) {
    //     return nav.items.find(item => {
    //       if (item.path === path.toLowerCase()) {
    //         debugLog(nav);
    //         debugLog(item);
    //         return item;
    //       }
    //     });
    //   } else if (nav.path === path.toLowerCase()) {
    //     debugLog(nav);
    //     return nav.path === path.toLowerCase();
    //   }
    // });
    // const nav = this.#navigation;
    // for (let i = 0; i < nav.length; i++) {
    //   if (nav[i].path !== path.toLowerCase() && nav[i].items) {
    //     const items = nav[i].items;
    //     for (let j = 0; j < items.length; j++) {
    //       if (items[j].path === path.toLowerCase()) {
    //         debugLog(nav[i]);
    //         nav[i].path = items[j].path;
    //         debugLog(this.#navigation);
    //         return items[i];
    //       }
    //     }
    //   } else if (nav[i].path === path.toLowerCase()) {
    //     return nav[i];
    //   }
    // }
    // return null;
  }

  setItem(path) {
    const item = this.findItem(path);

    // If item exists then save find & save nav item
    if (item) localStorage.setItem('curNav', JSON.stringify(item));
    // If item not found the save first element
    else localStorage.setItem('curNav', JSON.stringify(this.#navigation[0]));
  }

  getItem() {
    return JSON.parse(localStorage.getItem('curNav'));
  }

  removeItem() {
    localStorage.removeItem('curNav');
  }

  // NOT USED
  copyArray(arr) {
    let newNav = [];
    arr.map(el => {
      let newItems = [];
      el.items &&
        el.items.map(item => {
          newItems.push({
            id: item.id,
            name: item.name,
            path: item.path,
          });
        });
      newNav.push({
        id: el.id,
        icon: el.icon,
        path: 'path',
        items: el.items,
      });
    });
    return newNav;
  }
}
